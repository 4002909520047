import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { FormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
