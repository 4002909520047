import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { linkOptions } from '../../../add-single/constants/link-option';
import { decrement, increment } from 'src/app/shared/helper/helper';
import { youtubeValidator } from 'src/app/shared/validators/validatiors';

@Component({
  selector: 'app-configure-design',
  templateUrl: './configure-design.component.html',
  styleUrls: ['./configure-design.component.scss'],
})
export class ConfigureDesignComponent implements OnInit {
  @Input() configureDesignForm!: FormGroup;
  linkOptions = linkOptions;
  themeList!: { key: string; value: string }[];
  tiersList!: { key: string; value: string }[];
  numOfTasksList = [1, 2, 3, 4, 5];
  @Output() csvFile = new EventEmitter<string>();

  constructor(private generalService: GeneralService) {}

  ngOnInit(): void {
    this.configureDesignForm
      .get('link_option')
      ?.valueChanges.subscribe((val: any) => {
        this.checkBackgroundValidation(val);
        this.configureDesignForm.get('background')?.reset();
      });

    this.generalService.ad_tier$.subscribe(() => {
      this.tiersList = this.generalService.getTierAsArray();
    });

    this.generalService.themes$.subscribe(() => {
      this.themeList = this.generalService.getThemeAsArray();
    });
  }

  checkBackgroundValidation(val: string) {
    if (val === linkOptions[0].key) {
      this.configureDesignForm
        .get('background')
        ?.removeValidators(this.applyValidators());
      this.configureDesignForm.get('background')?.updateValueAndValidity();
    } else {
      this.configureDesignForm
        .get('background')
        ?.setValidators(this.applyValidators());
      this.configureDesignForm.get('background')?.updateValueAndValidity();
    }
  }

  applyValidators() {
    const backgroundControl = this.configureDesignForm.get('background');
    const linkOptionControl = this.configureDesignForm.get('link_option');
    return youtubeValidator({
      control: backgroundControl,
      additionalControls: linkOptionControl,
    });
  }

  saveCsv(event: any) {
    this.csvFile.emit(event);
  }

  increment(): void {
    const updatedValue = increment(
      this.configureDesignForm.get('number_of_tasks')?.value,
      5
    );
    this.configureDesignForm.get('number_of_tasks')?.setValue(updatedValue);
  }

  decrement(): void {
    const updatedValue = decrement(
      this.configureDesignForm.get('number_of_tasks')?.value,
      1
    );

    this.configureDesignForm.get('number_of_tasks')?.setValue(updatedValue);
  }
}
