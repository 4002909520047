import { RangeOptions } from './range-option.model';
import * as moment from 'moment';

export const rangeOptions: RangeOptions = {
  Today: [moment().startOf('day'), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Year': [moment().startOf('year'), moment()],
  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
  'All Time': [moment().subtract(10, 'years'), moment()],
};
