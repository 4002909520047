import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectFailedReferral: Snackbar = {
  duration: 2000,
  message: 'referral_program.snackbar_failed_get_date',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectFailedGenerate: Snackbar = {
  duration: 2000,
  message: 'referral_program.snackbar_failed_generate',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
