import { TableColumn } from '@swimlane/ngx-datatable';
import { ColumnsEnum } from '../enums/columns.enum';

const columnConfig: TableColumn = {
  draggable: false,
  resizeable: false,
};

export const columns: TableColumn[] = [
  {
    prop: ColumnsEnum.NAME,
    name: 'full_script_table.columns.name',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.ID,
    name: 'full_script_table.columns.id',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.CREATED_DATE,
    name: 'full_script_table.columns.created_date',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.VERTICAL,
    name: 'full_script_table.columns.vertical',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.ACTIONS,
    name: 'full_script_table.columns.actions',
    flexGrow: 0.75,
    sortable: false,
    ...columnConfig,
  },
];
