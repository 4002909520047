<div class="profile-container">
  <app-side-navbar
    (titleClicked)="changeTab($event)"
    class="side-nav"
    [activeTitle]="activeSection"
  ></app-side-navbar>
  <div class="content">
    <ng-container [ngSwitch]="activeSection">
      <app-personal-settings
        [userDetails]="userDetails"
        *ngSwitchCase="sideNavSections.PERSONAL_SETTINGS"
      ></app-personal-settings>
      <app-payment-method
        *ngSwitchCase="sideNavSections.PAYMENT_METHOD"
        [paymentMethodList]="paymentMethodList"
        [paymentDetails]="paymentDetails"
      ></app-payment-method>
      <app-password *ngSwitchCase="sideNavSections.PASSWORD"> </app-password>
      <app-creator-details
        *ngSwitchCase="sideNavSections.CREATOR_DETAILS"
        [userDetails]="userDetails"
        [creatorDetails]="creatorDetails"
      ></app-creator-details>
      <app-api-key *ngSwitchCase="sideNavSections.API_KEY"> </app-api-key>
    </ng-container>
  </div>
</div>
