<app-login-modal>
  <div class="titles-container color-dark-blue">
    <span class="typography-heading3-thin title">
      {{ 'email_send.title' | translate }}
    </span>
    <span class="typography-body4-thin subtitle">
      {{ 'email_send.subtitle' | translate }}
    </span>
  </div>
  <div class="buttons-container color-dark-blue">
    <app-button
      class="log-in-button full-width-button"
      (buttonClick)="sendAgain()"
      [fullWidth]="true"
    >
      {{ 'email_send.button' | translate }}
    </app-button>
  </div>
</app-login-modal>
