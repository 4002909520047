<div class="set-up-container" [formGroup]="setUpForm">
  <div class="download">
    <div class="step">
      <div class="left-step">
        <span class="typography-headline2">{{
          'multiple.step_1.download_csv_title' | translate
        }}</span>
      </div>
      <app-button (buttonClick)="downloadFile()" class="download-button">
        <app-icon name="download"></app-icon>
        {{ 'multiple.step_1.download_button' | translate }}</app-button
      >
    </div>
    <div class="typography-text explaination">
      <app-icon name="info"></app-icon>
      <span class="typography-headline2"
        >{{ 'multiple.step_1.download_csv_subtitle' | translate }}
        <a
          class="typography-body2-bold color-primary"
          target="_blank"
          [href]="link"
          >{{ 'multiple.step_1.click_here' | translate }}</a
        >
      </span>
    </div>
  </div>
  <div class="column">
    <div class="left-step">
      <div class="title typography-footnote2-bold">
        {{ 'multiple.step_1.source_name_title' | translate }}
      </div>
    </div>
    <div class="column-content">
      <div class="subtitle typography-footnote2-thin">
        {{ 'multiple.step_1.source_name_subtitle' | translate }}
      </div>
      <app-input
        class="name"
        formControlName="short_name"
        [maxLength]="30"
        [textCounter]="true"
        [placeholder]="'single.step_1.source_name_placeholder' | translate"
        [invalid]="
          (setUpForm.get('short_name')?.hasError('minlength') &&
            !setUpForm.get('short_name')?.pristine) ||
          (setUpForm.get('short_name')?.hasError('required') &&
            !setUpForm.get('short_name')?.pristine)
        "
      ></app-input>
    </div>
  </div>
  <div class="column padding">
    <div class="left-step">
      <div class="title typography-footnote2-bold">
        {{ 'multiple.step_1.category_title' | translate }}
      </div>
    </div>
    <div class="column-content last">
      <div class="subtitle typography-footnote2-thin">
        {{ 'multiple.step_1.category_subtitle' | translate }}
      </div>
      <div class="line">
        <ng-select
          class="dropdown typography-body3-thin"
          [multiple]="false"
          [items]="categories"
          [searchable]="false"
          bindLabel="value"
          bindValue="key"
          formControlName="category"
          [ngClass]="{
            'has-value': setUpForm.get('category') !== null
          }"
          [class.error]="setUpForm.get('category')?.hasError('required')"
          [placeholder]="
            'multiple.step_1.category_ng_select_placeholder' | translate
          "
        >
        </ng-select>
        <app-input
          *ngIf="setUpForm.get('category')?.value === 'Create Folder'"
          class="additional-input"
          formControlName="other_category"
          [placeholder]="'single.step_1.category_input_placeholder' | translate"
          [invalid]="
            setUpForm.get('other_category')?.hasError('required') &&
            !setUpForm.get('other_category')?.pristine
          "
        ></app-input>
      </div>
    </div>
  </div>
  <app-button
    [disabled]="setUpForm.invalid"
    (buttonClick)="next()"
    class="btn padding"
    >{{ 'multiple.step_1.next_btn' | translate }}</app-button
  >
</div>
