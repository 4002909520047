import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  public supportSlideToggle = new Subject<void>();
  public supportSlideToggle$ = this.supportSlideToggle.asObservable();

  public supportSlideOpen = new BehaviorSubject<boolean>(false);
  public supportSlideOpen$ = this.supportSlideOpen.asObservable();

  toggleSidenav = () => {
    this.supportSlideToggle.next();
  };
}
