import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as GeneralActions from './general-state.action';
import * as GeneralSelectors from './general-state.selector';
import { GeneralState } from './general-state.state';
@Injectable()
export class GeneralFacade {
  lootLabsData$ = this.generalStore.pipe(
    select(GeneralSelectors.getLootLabsData)
  );
  enums$ = this.generalStore.pipe(select(GeneralSelectors.getEnums));

  constructor(private generalStore: Store<GeneralState>) {}

  loadLootLabsData() {
    this.generalStore.dispatch(GeneralActions.loadLootLabsData());
  }
}
