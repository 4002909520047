<div *ngIf="form" [formGroup]="form" class="social-container">
  <div class="formControl typography-body3-thin color-light-gray name">
    <span>{{ 'profile.creator_details.creator_name' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="creator_name"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray name">
    <span>{{ 'profile.creator_details.main_game' | translate }}</span>
    <ng-select
      class="input-field"
      formControlName="main_game"
      [multiple]="false"
      [items]="mainGameList"
      bindLabel="value"
      bindValue="key"
      [placeholder]="
              'profile.creator_details.main_game' | translate
            "
    ></ng-select>
  </div>
  <div class="formControl typography-body3-thin color-light-gray name">
    <span>{{ 'profile.creator_details.social_channel' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="social_channel"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray name">
    <span>{{ 'profile.creator_details.main_traffic' | translate }}</span>
    <ng-select
      class="input-field"
      formControlName="main_source_of_traffic"
      [multiple]="false"
      [items]="mainTrafficList"
      bindLabel="value"
      bindValue="key"
      [placeholder]="
              'profile.creator_details.main_traffic' | translate
            "
    ></ng-select>
  </div>
  <div class="typography-heading3-thin">
    {{ 'profile.creator_details.title' | translate }}
  </div>
  <div class="typography-body3-thin">
    {{ 'profile.creator_details.instruction' | translate }}
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.tiktok' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="tiktok"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.facebook' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="facebook"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.instagram' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="instagram"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.youtube' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="youtube"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.discord' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="discord"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.twitter' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="twitter"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.twitch' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="twitch"
    ></app-input>
  </div>
  <div class="formControl typography-body3-thin color-light-gray">
    <span>{{ 'profile.creator_details.personal_site' | translate }}</span>
    <app-input
      [type]="'text'"
      class="input-field"
      formControlName="personal_site"
    ></app-input>
  </div>
</div>
