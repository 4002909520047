import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';
import * as GeneralActions from './general-state.action';
import { LootLabsData } from '../enums/enum.model';
import { GeneralService } from './general-state.service';

@Injectable()
export class GeneralEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly lootLabsService: GeneralService
  ) {}

  loadLootLabsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GeneralActions.loadLootLabsData),
      concatMap(() => {
        return this.lootLabsService.loadLootLabsData().pipe(
          map(
            (presentation: any) => {
              return GeneralActions.loadLootLabsDataSuccess({
                lootLabsData: <LootLabsData>{
                  enums: presentation[0],
                },
              });
            },
            catchError((err) => {
              return of(GeneralActions.loadLootLabsDataFailure({ error: err }));
            })
          )
        );
      })
    );
  });
}
