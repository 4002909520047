import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyProfitModule } from '../my-profit/my-profit.module';
import { PaymentTableComponent } from './components/payment-table/payment-table.component';

@NgModule({
  declarations: [PaymentComponent, PaymentTableComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    MyProfitModule,
    FormsModule,
  ],
  exports: [PaymentComponent],
})
export class PaymentModule {}
