<div
  [ngClass]="{
    card: true,
    'with-title': title,
    'without-title': title === undefined
  }"
  [class.error]="error"
  [class.emphasize]="emphasize"
  [class.fund]="fund"
>
  <div *ngIf="title" class="typography-heading3-bold title-container">
    <span class="title"> {{ title }}</span>
  </div>

  <ng-content></ng-content>
</div>
