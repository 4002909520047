import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSingleComponent } from './add-single.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddSocialComponent } from './components/add-social/add-social.component';
import { SetUpScreenComponent } from './components/set-up-screen/set-up-screen.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateLinkComponent } from './components/create-link/create-link.component';
import { PreviewComponent } from './components/preview/preview.component';
import { EditMultipleComponent } from './components/edit-multiple/edit-multiple.component';
import { ContentComponent } from './components/preview/components/content/content.component';

@NgModule({
  declarations: [
    AddSingleComponent,
    AddSocialComponent,
    CreateLinkComponent,
    PreviewComponent,
    SetUpScreenComponent,
    EditMultipleComponent,
    ContentComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTabsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
  ],
})
export class AddSingleModule {}
