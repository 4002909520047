<div class="creator-container">
  <div class="title typography-heading3-bold">
    {{ 'profile.creator_details.creator_title' | translate }}
  </div>
  <app-user-details
    [creatorDetails]="creatorDetails"
    [form]="creatorDetailsForm"
  ></app-user-details>

  <app-social-channels
    [form]="creatorDetailsForm"
    [creatorDetails]="creatorDetails"
  ></app-social-channels>
  <app-button
    class="button"
    (buttonClick)="submit()"
    [disabled]="
      isDisable ||
      creatorDetailsForm.get('creator_picture')?.value ===
        this.placeHolderPicture
    "
    >{{ 'profile.payment_method.submit' | translate }}</app-button
  >
</div>
