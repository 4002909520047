import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddFullScriptService } from '../../service/add-full-script.service';
import { Item } from 'src/app/shared/types/item';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-set-up-link',
  templateUrl: './set-up-link.component.html',
  styleUrls: ['./set-up-link.component.scss'],
})
export class SetUpLinkComponent {
  @Output() continue = new EventEmitter<any>();
  @Input() setupLinkForm!: FormGroup;
  @Input() isEditMode!: boolean;
  constructor(
    public generalService: GeneralService,
    public addFullScriptService: AddFullScriptService
  ) {}

  next() {
    this.continue.emit();
  }
}
