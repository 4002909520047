<app-card
  ><div class="form-container">
    <div class="layout">
      <div class="full-script-container">
        <div class="header-container">
          <div class="header">
            <span class="title typography-heading3-bold">
              {{ 'full_page.title' | translate }}
            </span>
            <img
              class="close"
              (click)="close()"
              src="../../../../../assets/svg/white_x.svg"
            />
          </div>
          <ng-container *ngFor="let bar of progressBars">
            <mat-progress-bar
              *ngIf="!isCopyMode && !fullPageScriptData.editMode"
              mode="determinate"
              [value]="bar"
            ></mat-progress-bar>
          </ng-container>
        </div>
        <div class="content">
          <div class="form">
            <mat-accordion *ngIf="!isCopyMode" [formGroup]="fullScriptForm">
              <mat-expansion-panel
                (opened)="panelOpened(0)"
                (closed)="panelClosed(0)"
                [expanded]="steps[0].isOpen"
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    'edit-mode': setupLinkForm.valid && !steps[0].isOpen,
                    closed: setupLinkForm.valid && !steps[0].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[0].isValid,
                      false: !this.steps[0].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[0].isValid && steps[0].isOpen) ||
                          (!this.steps[0].isValid && !steps[0].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">{{
                        steps[0].index
                      }}</span>
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span class="typography-heading4-bold">{{
                      'full_page.step_1.title' | translate
                    }}</span>
                    <div
                      class="values"
                      *ngIf="setupLinkForm.valid && !steps[0].isOpen"
                    >
                      <div class="left-edit">
                        <app-icon name="edit"></app-icon>
                        <span
                          class="url typography-body3-bold txt-w-dots"
                          [tooltip]="setupLinkForm.get('name')?.value"
                          >{{ 'full_page.step_1.name' | translate }} :
                          {{ setupLinkForm.get('name')?.value }}</span
                        >
                      </div>
                      <span
                        class="url typography-body3-bold txt-w-dots"
                        [tooltip]="setupLinkForm.get('url')?.value"
                        >{{ 'full_page.step_1.url' | translate }} :
                        {{ setupLinkForm.get('url')?.value }}</span
                      >
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <app-set-up-link
                  (continue)="firstStepCompleted()"
                  [setupLinkForm]="setupLinkForm"
                  [isEditMode]="isEditMode"
                ></app-set-up-link> </mat-expansion-panel
            ></mat-accordion>
            <mat-accordion *ngIf="!isCopyMode" [formGroup]="typeListForm">
              <mat-expansion-panel
                [disabled]="
                  !isEditMode && (!isStep1Complited || !setupLinkForm.valid)
                "
                [expanded]="
                  steps[1].isOpen && !steps[0].isOpen && !steps[2].isOpen
                "
                (opened)="panelOpened(1)"
                (closed)="panelClosed(1)"
              >
                <mat-expansion-panel-header>
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: steps[1].isValid && steps[0].isValid,
                      false: !steps[1].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[1].isValid && steps[1].isOpen) ||
                          (!this.steps[1].isValid && !steps[1].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">{{
                        steps[1].index
                      }}</span>
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <span class="typography-heading4-bold title">{{
                    'full_page.step_2.title' | translate
                  }}</span>
                </mat-expansion-panel-header>
                <app-wl-bl-step
                  [urls]="fullPageScriptData.urls"
                  [setupLinkForm]="setupLinkForm"
                  [wlBlForm]="typeListForm"
                  (continueStep2)="secondStepCompleted()"
                  [isEditMode]="isEditMode"
                ></app-wl-bl-step>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel
                [disabled]="
                  !fullPageScriptData.editMode &&
                  (!isStep2Complited ||
                    typeListForm.invalid ||
                    setupLinkForm.invalid)
                "
                [expanded]="
                  (steps[2].isOpen && !steps[0].isOpen && !steps[1].isOpen) ||
                  isCopyMode
                "
                (opened)="panelOpened(2)"
                (closed)="panelClosed(2)"
              >
                <mat-expansion-panel-header>
                  <div class="circle false">
                    <ng-container>
                      <span class="typography-footnote1">{{
                        isCopyMode ? steps[0].index : steps[2].index
                      }}</span>
                    </ng-container>
                  </div>
                  <span class="typography-heading4-bold title">{{
                    'full_page.step_3.title' | translate
                  }}</span>
                </mat-expansion-panel-header>
                <app-copy-step [form]="fullScriptForm"></app-copy-step>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="submit-button">
    <app-button
      [disabled]="!fullScriptForm.valid || !steps[1].isValid"
      (buttonClick)="submitStep()"
    >
      {{ 'full_page.back' | translate }}
    </app-button>
  </div>
</app-card>
