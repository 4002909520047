import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDividerComponent } from './divider.component';

@NgModule({
  declarations: [AppDividerComponent],
  imports: [CommonModule],
  exports: [AppDividerComponent],
})
export class AppDividerModule {}
