import { Component, OnInit } from '@angular/core';
import { Password } from './type/password';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordMatcher } from 'src/app/shared/validators/validatiors';
import { LoginService } from '../../store/login-state/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  creatorId!: string | null;
  passwordArray!: Password[];
  public passwordForm!: FormGroup;
  isEqualPassword = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.createPasswordArray();
    this.route.queryParamMap.subscribe((params) => {
      const publisher_id = params.get('publisher_id');
      if (publisher_id) {
        this.creatorId = publisher_id;
      }
    });
    this.initForm();
    this.passwordForm.get('confirmPassword')?.valueChanges.subscribe(() => {
      this.isEqual();
    });
  }

  initForm() {
    this.passwordForm = this.fb.group(
      {
        password: [null, [Validators.required]],
        confirmPassword: [null, [Validators.required]],
      },
      { validator: passwordMatcher }
    );
  }

  createPasswordArray() {
    this.passwordArray = [
      {
        key: 'password',
        password: false,
        passwordIcon: 'hide_eye',
      },
      {
        key: 'passwordConfirmation',
        passwordConfirmation: false,
        passwordIcon: 'hide_eye',
      },
    ];
  }

  togglePasswordVisibility(type: string): void {
    if (this.passwordArray[0].key === type) {
      this.passwordArray[0].password = !this.passwordArray[0].password;
      if (this.passwordArray[0].password) {
        this.passwordArray[0].passwordIcon = 'visibility';
      } else {
        this.passwordArray[0].passwordIcon = 'hide_eye';
      }
    } else {
      this.passwordArray[1].password = !this.passwordArray[1].password;
      if (this.passwordArray[1].password) {
        this.passwordArray[1].passwordIcon = 'visibility';
      } else {
        this.passwordArray[1].passwordIcon = 'hide_eye';
      }
    }
  }

  submit() {
    if (this.passwordForm.valid) {
      const password = this.passwordForm.value.password;

      const response = this.loginService
        .resetPassword(password, this.creatorId)
        .subscribe(
          (res) => {
            this.router.navigate(['login']);
          },
          (error: HttpErrorResponse) => {
            //TO DO: add error function in general service
          }
        );
      this.passwordForm.reset();
    }
  }

  isEqual() {
    const passwordControl = this.passwordForm.get('password');
    const confirmPasswordControl = this.passwordForm.get('confirmPassword');

    if (passwordControl?.value === confirmPasswordControl?.value) {
      this.isEqualPassword = true;
    } else {
      this.isEqualPassword = false;
    }
  }
}
