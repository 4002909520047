import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { Observable } from 'rxjs';
import {
  BaseControlValueAccessor,
  getValueAccessor,
} from 'src/app/shared/components/forms/reactive-form/base-control-value-accessor';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss'],
  providers: [getValueAccessor(UploadCsvComponent)],
})
export class UploadCsvComponent extends BaseControlValueAccessor<any> {
  override writeValue(obj: any): void {}
  event!: Event;
  selectedFiles?: FileList;
  currentFile?: File;
  isUpload = false;
  csv!: string;
  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;
  @Input() preview!: string;
  @Input() formControlName!: string;
  @Input() invalid: boolean | undefined = false;
  @Output() csvChanged: EventEmitter<FormData> = new EventEmitter();
  @Output() imageChanged: EventEmitter<any> = new EventEmitter();
  imageInfos?: Observable<any>;
  fileName!: string;

  constructor() {
    super();
  }
  upload(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.isUpload = true;
          this.imageChanged.emit();
          var formData = new FormData();
          formData.append('file', file);
          this.csvChanged.emit(formData);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  deleteImage() {
    this.preview = this.csv;
    this.isUpload = false;
    this.onChange(this.preview);
    this.fileUpload.nativeElement.value = null;
  }
}
