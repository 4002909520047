<div [formGroup]="wlBlForm" class="container">
  <div class="stepper-n-title">
    <div class="typography-body3-thin first-step typography-color-dark-blue">
      {{ 'full_page.step_2.choose_subtitle' | translate }}
    </div>
    <app-radio-group
      formControlName="typeList"
      [groupName]="'typeList'"
      class="radio-buttons-group radio-buttons-group-step1"
    >
      <app-radio-button
        [value]="listEnumType.BLACK_LIST"
        [groupName]="'typeList'"
      >
        <ng-template
          let-checked="checked"
          let-disabled="disabled"
          class="radio"
        >
          <app-radio-circle
            [checked]="checked"
            [disabled]="disabled"
            [label]="'full_page.step_2.black_list' | translate"
          >
          </app-radio-circle>
        </ng-template>
      </app-radio-button>
      <app-radio-button
        [value]="listEnumType.WHITE_LIST"
        [groupName]="'typeList'"
      >
        <ng-template
          let-checked="checked"
          let-disabled="disabled"
          class="radio"
        >
          <app-radio-circle
            [checked]="checked"
            [disabled]="disabled"
            [label]="'full_page.step_2.white_list' | translate"
          >
          </app-radio-circle>
        </ng-template>
      </app-radio-button>
    </app-radio-group>
  </div>
  <div class="stepper-n-title">
    <div class="typography-body3-thin first-step typography-color-dark-blue">
      {{ 'full_page.step_2.enter_url_subtitle' | translate }}
    </div>
    <app-button size="sm" color="primary" (buttonClick)="addUrl()">
      <app-icon size="sm" start name="add"></app-icon>
    </app-button>
  </div>
  <div
    [class.height]="Urls.controls.length > 3"
    class="form-container"
    formArrayName="url"
  >
    <div
      *ngFor="let urlControl of Urls.controls; let i = index"
      class="url-control"
    >
      <app-input
        type="text"
        class="input-container"
        formControlName="{{ i }}"
        placeholder="Enter URL"
        [invalid]="urlControl.hasError('pattern') && urlControl.dirty"
      ></app-input>

      <app-button color="primary" (buttonClick)="deleteUrl(i)">
        <app-icon start name="delete"></app-icon>
      </app-button>
    </div>
  </div>
  <app-button
    class="btn"
    (buttonClick)="next()"
    [disabled]="
      wlBlForm.invalid || (isSubmit && !isChanged) || (isEditMode && !isChanged)
    "
  >
    {{
      isEditMode
        ? ('full_page.step_2.save' | translate)
        : ('full_page.step_2.btn' | translate)
    }}
  </app-button>
</div>
