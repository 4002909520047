import {Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import {GeneralService} from "src/app/shared/states/general-state.service";
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-social-channels',
  templateUrl: './social-channels.component.html',
  styleUrls: ['./social-channels.component.scss'],
})
export class SocialChannelsComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() creatorDetails!: CreatorModel;
  mainGameList: any = [];
  mainTrafficList: any = [];

  constructor(
    public generalService: GeneralService,

  ) {}


  ngOnInit(): void {
    interval(3000)
      .pipe(
        switchMap(() => this.generalService.main_game$),
      )
      .subscribe(val => {
        if (val !== null) {
          this.mainGameList = [];
          Object.keys(val).forEach(key => {
            this.mainGameList.push({ key, value: val[key] });
          });
        }
      });
    interval(3000)
      .pipe(
        switchMap(() => this.generalService.source_traffic$),
      )
      .subscribe(val => {
        if (val !== null) {
          this.mainTrafficList = [];
          Object.keys(val).forEach(key => {
            this.mainTrafficList.push({ key, value: val[key] });
          });
        }
      });
  }

}
