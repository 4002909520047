<app-card>
  <div class="form-container">
    <div class="layout">
      <div class="single-link-container">
        <div class="header-container">
          <div class="header">
            <span class="title typography-heading3-bold">
              {{
                isDesktop && isEditMode
                  ? ('single.edit_title' | translate)
                  : ('single.create_title' | translate)
              }}
            </span>
            <img
              *ngIf="isDesktop"
              class="close"
              (click)="close()"
              src="../../../../../assets/svg/white_x.svg"
            />
          </div>
          <ng-container *ngFor="let bar of progressBars">
            <mat-progress-bar
              *ngIf="(isDesktop && !ref.data.editMode) || !isDesktop"
              mode="determinate"
              [value]="bar"
            ></mat-progress-bar>
          </ng-container>
        </div>
        <div class="content">
          <div class="form">
            <mat-accordion [formGroup]="singleLinkForm">
              <mat-expansion-panel
                (opened)="panelOpened(0)"
                (closed)="panelClosed(0)"
                [expanded]="steps[0].isOpen"
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    'edit-mode':
                      singleLinkForm.get('first')?.valid && !steps[0].isOpen,
                    closed:
                      singleLinkForm.get('first')?.valid && !steps[0].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[0].isValid,
                      false: !this.steps[0].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[0].isValid && steps[0].isOpen) ||
                          (!this.steps[0].isValid && !steps[0].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">{{
                        steps[0].index
                      }}</span>
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span
                      [ngClass]="{
                        'typography-body2-bold': !isDesktop,
                        'typography-heading4-bold': isDesktop
                      }"
                      >{{
                        (isEditMode
                          ? 'single.step_1.edit_title'
                          : 'single.step_1.title'
                        ) | translate
                      }}</span
                    >
                    <div
                      class="values"
                      *ngIf="
                        singleLinkForm.get('first')?.valid && !steps[0].isOpen
                      "
                    >
                      <div class="left-edit">
                        <app-icon name="edit"></app-icon>
                        <span
                          class="url typography-body3-bold txt-w-dots"
                          [tooltip]="
                            singleLinkForm.get('first')?.get('url')?.value
                          "
                          >{{ 'single.step_1.url_title' | translate }}:
                          {{
                            singleLinkForm.get('first')?.get('url')?.value
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <app-create-link
                  [creatingLinkControls]="firstFormGroup"
                  (closeFirstPanel)="firstStepCompleted()"
                  [isEditMode]="isEditMode"
                ></app-create-link>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion [formGroup]="singleLinkForm">
              <mat-expansion-panel
                (opened)="panelOpened(1)"
                (closed)="panelClosed(1)"
                [expanded]="
                  steps[1].isOpen && !steps[0].isOpen && !steps[2].isOpen
                "
                [disabled]="
                  singleLinkForm.get('first')?.invalid && !this.steps[0].isValid
                "
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    'edit-mode':
                      singleLinkForm.get('second')?.valid &&
                      !steps[1].isOpen &&
                      isDesktop,
                    closed:
                      singleLinkForm.get('second')?.valid && !steps[1].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[1].isValid,
                      false: !this.steps[1].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[1].isValid && steps[0].isOpen) ||
                          (!this.steps[1].isValid && !steps[0].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">
                        {{ steps[1].index }}</span
                      >
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span
                      [ngClass]="{
                        'typography-body2-bold': !isDesktop,
                        'typography-heading4-bold': isDesktop
                      }"
                      >{{ 'single.set_up.step_title' | translate }}</span
                    >
                    <div
                      class="values"
                      *ngIf="
                        singleLinkForm.get('second')?.valid &&
                        !steps[1].isOpen &&
                        isDesktop
                      "
                    >
                      <div class="left-edit">
                        <app-icon name="edit"></app-icon>
                        <span
                          class="url typography-body3-bold txt-w-dots"
                          [tooltip]="
                            singleLinkForm.get('second')?.get('title')?.value
                          "
                          >{{ 'single.set_up.title_page' | translate }}:
                          {{
                            singleLinkForm.get('second')?.get('title')?.value
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <app-set-up-screen
                  (continue)="secondStepCompleted()"
                  [setUpForm]="secondFormGroup"
                ></app-set-up-screen>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion class="last-accordion" [formGroup]="singleLinkForm">
              <mat-expansion-panel
                (opened)="panelOpened(2)"
                (closed)="panelClosed(2)"
                [expanded]="
                  steps[2].isOpen && !steps[1].isOpen && !steps[0].isOpen
                "
                [disabled]="
                  singleLinkForm.get('second')?.invalid &&
                  !this.steps[1].isValid
                "
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    closed:
                      singleLinkForm.get('third')?.valid && !steps[2].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[2].isValid,
                      false: !this.steps[2].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[2].isValid && steps[1].isOpen) ||
                          (!this.steps[2].isValid && !steps[1].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-text"> {{ steps[2].index }}</span>
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span
                      [ngClass]="{
                        'typography-body2-bold': !isDesktop,
                        'typography-heading4-bold': isDesktop
                      }"
                      >{{
                        (isDesktop
                          ? 'single.add_social.step_title'
                          : 'single.add_social.step_title_mobile'
                        ) | translate
                      }}</span
                    >
                  </div>
                </mat-expansion-panel-header>
                <app-add-social [socialForm]="thirdFormGroup"></app-add-social>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div *ngIf="isDesktop" class="preview">
            <app-preview
              [title]="secondFormGroup.get('title')?.value"
              [theme]="this.secondFormGroup.get('theme_id')?.value"
              [social_links]="thirdFormGroup"
              [number_of_tasks]="secondFormGroup.get('number_of_tasks')?.value"
              [backgroundForm]="secondFormGroup.get('background')?.value"
            ></app-preview>
          </div>
        </div>
      </div>
      <div class="submit-button">
        <app-button
          color="primary"
          [disabled]="
            firstFormGroup.get('other_category')?.invalid ||
            firstFormGroup.get('category')?.invalid ||
            firstFormGroup.get('short_name')?.invalid ||
            firstFormGroup.get('url')?.invalid ||
            secondFormGroup.invalid ||
            secondFormGroup.get('number_of_tasks')?.value == 0 ||
            thirdFormGroup.invalid
          "
          (buttonClick)="submit()"
        >
          {{
            isDesktop && ref.data.editMode
              ? ('single.edit_title' | translate)
              : ('single.create_title' | translate)
          }}</app-button
        >
      </div>
    </div>
  </div>
</app-card>
