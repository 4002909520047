class Icons {
  readonly alert = './assets/svg/alert.svg';
  readonly account = './assets/svg/account.svg';
  readonly active_account = './assets/svg/active_account.svg';
  readonly active_computer_mouse = './assets/svg/active_computer_mouse.svg';
  readonly active_key = './assets/svg/active_key.svg';
  readonly active_lock = './assets/svg/active_lock.svg';
  readonly active_payment = './assets/svg/active_payment.svg';
  readonly add = './assets/svg/add.svg';
  readonly analytics = './assets/svg/analytics.svg';
  readonly back = './assets/svg/back_icon.svg';
  readonly calendar = './assets/svg/calendar.svg';
  readonly close = './assets/svg/x.svg';
  readonly content_copy = './assets/svg/content_copy.svg';
  readonly copy_code = './assets/svg/copy_code.svg';
  readonly computer_mouse = './assets/svg/computer_mouse.svg';
  readonly chevron_down = './assets/svg/chevron_down.svg';
  readonly delete = './assets/svg/delete.svg';
  readonly discord = './assets/svg/discord.svg';
  readonly download = './assets/svg/download.svg';
  readonly edit = './assets/svg/edit.svg';
  readonly error = './assets/svg/error.svg';
  readonly filter = './assets/svg/filter.svg';
  readonly hide_eye = './assets/svg/hide_eye.svg';
  readonly info = './assets/svg/info.svg';
  readonly key = './assets/svg/key.svg';
  readonly lamp = './assets/svg/lamp.svg';
  readonly link = './assets/svg/link.svg';
  readonly lock = './assets/svg/lock.svg';
  readonly logout = './assets/svg/logout.svg';
  readonly mail = './assets/svg/mail.svg';
  readonly mark_all = './assets/svg/mark_all.svg';
  readonly move_arrow = './assets/svg/move_arrow.svg';
  readonly navigation_left = './assets/svg/navigation_left.svg';
  readonly navigation_right = './assets/svg/navigation_right.svg';
  readonly notifications = './assets/svg/notification.svg';
  readonly payment = './assets/svg/payment.svg';
  readonly preview_question_mark = './assets/svg/preview_question_mark.svg';
  readonly question_mark = './assets/svg/question_mark.svg';
  readonly remove = './assets/svg/remove.svg';
  readonly route = './assets/svg/route.svg';
  readonly search = './assets/svg/search.svg';
  readonly share = './assets/svg/share.svg';
  readonly statistics = './assets/svg/statistics.svg';
  readonly small_v = './assets/svg/small_v.svg';
  readonly support = './assets/svg/support.svg';
  readonly skype = './assets/svg/skype.svg';
  readonly telegram = './assets/svg/telegram.svg';
  readonly upload = './assets/svg/upload.svg';
  readonly visibility = './assets/svg/visibility.svg';
  readonly white_v = './assets/svg/white_v.svg';
  readonly white_x = './assets/svg/white_x.svg';
  readonly white_discord = './assets/svg/white_discord.svg';
  readonly white_facebook = './assets/svg/white_facebook.svg';
  readonly white_instagram = './assets/svg/white_instagram.svg';
  readonly white_tiktok = './assets/svg/white_tiktok.svg';
  readonly white_twitch = './assets/svg/white_twitch.svg';
  readonly white_twitter = './assets/svg/white_twitter.svg';
  readonly white_youtube = './assets/svg/white_youtube.svg';
  readonly website = './assets/svg/website.svg';
  readonly youtube = './assets/svg/youtube.svg';
}
export const icons = new Icons();

export type AppIcon = keyof Icons;
