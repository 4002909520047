import { Component, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { MyOverlayRef } from '../../services/overlay/overlay-ref';

import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

interface LinkData {
  short_link: string;
}
@Component({
  selector: 'app-download-csv',
  templateUrl: './download-csv.component.html',
  styleUrls: ['./download-csv.component.scss'],
})
export class DownloadCsvComponent implements OnInit {
  file: LinkData[] = [];
  updateDataFn!: Function;

  constructor(
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    @Optional() @SkipSelf() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Optional()
    @SkipSelf()
    private bottomSheetRef: MatBottomSheetRef<DownloadCsvComponent>
  ) {}
  ngOnInit(): void {
    if (this.ref && this.ref.data.file) {
      this.file = this.ref.data.file;
      this.updateDataFn = this.ref.data.updateDataFn;
    }
  }

  downloadCSV(): void {
    const csvRows = ['Links'];
    this.file.forEach((obj) => {
      csvRows.push(obj.short_link);
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'links.csv';
    downloadLink.click();
    URL.revokeObjectURL(url);
  }

  close() {
    if (this.ref) {
      this.ref.close();
    } else if (this.data) {
      this.bottomSheetRef.dismiss();
    }

    if (this.updateDataFn) {
      this.updateDataFn();
    }
  }
}
