import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidePanelLoaderComponent } from './side-panel-loader.component';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  declarations: [SidePanelLoaderComponent],
  imports: [CommonModule, LottieModule],
  exports: [SidePanelLoaderComponent],
})
export class SidePanelLoaderModule {}
