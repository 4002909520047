import { TableColumn } from '@swimlane/ngx-datatable';
import { ColumnsEnum } from '../enums/column.enum';

const columnConfig: TableColumn = {
  draggable: false,
  resizeable: false,
};
export const columns: TableColumn[] = [
  {
    prop: ColumnsEnum.ID,
    name: 'referral_program.columns.id',
    sortable: false,
    flexGrow: 5,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.NAME,
    name: 'referral_program.columns.name',
    sortable: false,
    flexGrow: 5,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.CREATED_DATE,
    flexGrow: 5,
    name: 'referral_program.columns.create_date',
    sortable: false,
    ...columnConfig,
  },

  {
    prop: ColumnsEnum.ACTIONS,
    name: 'referral_program.columns.actions',
    flexGrow: 1,
    sortable: false,
    ...columnConfig,
  },
];
