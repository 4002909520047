import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { MyOverlayRef } from '../../services/overlay/overlay-ref';
import { OverlayService } from '../../services/overlay/overlay.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent {
  constructor(
    private router: Router,
    private ref: MyOverlayRef,
    private loginService: LoginService,
    private overlayService: OverlayService
  ) {}

  navigateToLogin() {
    this.ref.close();
    this.overlayService.enableClose();
    this.router.navigate(['/login']).then(() => {
      this.loginService.logoutGuard();
    });
  }
}
