import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProfileService } from './service/profile.service';
import { replaceSpacesWithUnderscores } from 'src/app/shared/helper/helper';
import { SideNavSectionsEnum } from './enums/side_nav_section';
import { Item } from 'src/app/shared/types/item';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import { combineLatest } from 'rxjs';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { UserModel } from '../../common/login/store/models/user.model';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { CreatorModel } from '../../common/login/store/models/creator.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  activeSection!: string;
  sideNavSections = SideNavSectionsEnum;
  public paymentMethodList!: Item[];
  public paymentDetails!: any | null;
  public userDetails = new UserModel();
  public creatorDetails!: CreatorModel;

  constructor(
    public profileService: ProfileService,
    private generalFacade: GeneralFacade,
    private generalService: GeneralService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.profileService.tab$.subscribe((tab) => {
      this.activeSection = tab as SideNavSectionsEnum;
    });
    combineLatest([
      this.generalFacade.enums$,
      this.generalService.creator$,
      this.generalService
        .getUserDetails()
        .pipe(tap((res: any) => (this.userDetails = res.message))),
      this.profileService
        .getPaymentFromService()
        .pipe(
          tap(
            (paymentDetails: any) =>
              (this.paymentDetails = paymentDetails.message[0])
          )
        ),
    ]).subscribe(([enums, creator]) => {
      if (creator) {
        this.creatorDetails = creator;
      }
      if (this.userDetails.bitcoin_allowed) {
        this.paymentMethodList = [
          {
            ...enums?.message.payment_methods.basic,
            ...enums?.message.payment_methods.bitcoin,
          },
        ];
      } else {
        this.paymentMethodList = [{ ...enums?.message.payment_methods.basic }];
      }
      this.paymentMethodList = Object.entries(this.paymentMethodList[0]).map(
        ([key, value]) => ({ key, value })
      );
      this.loaderService.hide();
    });
  }

  changeTab(section: string) {
    this.activeSection = replaceSpacesWithUnderscores(section);
  }
}
