<div class="statistics-container">
  <app-my-profit></app-my-profit>
  <app-card>
    <div class="container">
      <div *ngIf="isDesktop" class="header">
        <span class="title typography-heading4-thin">
          {{ 'statistics.title' | translate }}
        </span>

        <app-date-picker
          (dateSelected)="getDateSelected($event)"
          [selectedRange]="selectedRange"
          class="date-picker"
        ></app-date-picker>
      </div>
      <div
        [class.filter-desktop]="isDesktop"
        [class.filter-mobile]="!isDesktop"
      >
        <app-filters
          [selected]="selected"
          [isDesktop]="isDesktop"
          [class.first]="!isDesktop"
        ></app-filters>
        <div class="actions" [class.second]="!isDesktop">
          <app-group-by></app-group-by>
          <app-export-to-csv
            *ngIf="isDesktop"
            [rows]="rowsToExport"
            [columns]="columnsToExport"
            [disable]="rowsToExport?.length === 0"
            [title]="'statistics.title' | translate"
          ></app-export-to-csv>
        </div>
      </div>
      <app-statistics-table></app-statistics-table>
    </div>
  </app-card>
</div>
