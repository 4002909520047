<app-login-modal
  [formGroup]="loginGroup"
  (formValueChanged)="updateLoginRequestBody($event)"
>
  <div  *ngIf="checkSwitchStatus()">
  <div class="titles-container">
    <span class="typography-heading3-bold title">
      {{ 'login.title' | translate }}
    </span>
    <span class="typography-heading3-bold subtitle">
      {{ 'login.subtitle' | translate }}
    </span>
  </div>

  <div class="input-fields-container" [formGroup]="loginGroup">
    <div class="input">
      <div class="password-label-container">
        <span class="typography-body4-thin input-label">
          {{ 'login.email_label' | translate }}
        </span>
      </div>
      <app-input
        [type]="'email'"
        class="input-field"
        formControlName="email"
        [errorMessage]="errorMessage('email')"
        [invalid]="isFieldValid('email')"
        (inputFocusOut)="loginGroup.controls['email'].markAsTouched()"
        [placeholder]="'login.error_messages.email' | translate"
      ></app-input>
    </div>
    <div class="input">
      <div class="password-label-container">
        <span class="typography-body4-thin input-label">{{
          'login.password_label' | translate
        }}</span>
        <span class="typography-body4-thin">
          <a href="/forgot-password">{{
            'login.forgot_password' | translate
          }}</a>
        </span>
      </div>
      <div class="password-container">
        <app-input
          [type]="isPasswordVisible ? 'text' : 'password'"
          class="input-field"
          formControlName="password"
          [errorMessage]="errorMessage('password')"
          [invalid]="isFieldValid('password')"
          (inputFocusOut)="loginGroup.controls['password'].markAsTouched()"
          [placeholder]="'login.error_messages.password' | translate"
        ></app-input>
        <div class="emoji-btn" (click)="togglePasswordVisibility()">
          <app-icon class="icon" [name]="passwordIcon"></app-icon>
        </div>
      </div>
    </div>
  </div>
  <app-captcha
    class="captcha"
    (userToken)="setCaptchaToken($event)"
    [invalid]="isFieldValid('captcha_token')"
  >
  </app-captcha>
  <div *ngIf="loginError" class="wrong-records-container">
    <div class="alert-img">
      <app-icon name="alert"></app-icon>
    </div>
    <span class="typography-footnote1 disclaimer-text">
      {{ loginError }}
    </span>
  </div>
  <div class="buttons-container">
    <app-button
      class="log-in-button full-width-button"
      (buttonClick)="submit()"
      [fullWidth]="true"
    >
      {{ 'login.main_button' | translate }}
    </app-button>
    <div class="typography-caption disclaimer-text">
      {{ 'login.terms-n-privacy.part1' | translate }}
      <a class="color-primary" target="_blank" [href]="termsOfUseLink">{{
        'login.terms-title' | translate
      }}</a>
      {{ 'login.terms-n-privacy.part2' | translate }}
      <a class="color-primary" target="_blank" [href]="privacyPolicyLink">{{
        'login.privacy-title' | translate
      }}</a>
    </div>

  </div>
  </div>
  <div  *ngIf="!checkSwitchStatus()">
    <div class="titles-container">
        <span class="typography-heading3-bold title">
      {{ "login.title" | translate }}
    </span>
      <span class="typography-heading3-bold subtitle">
      {{ "login.subtitle_down" | translate }}
    </span>
      <span class="typography-heading3-bold subtitle">
      {{ "login.subtitle_down2" | translate }}
    </span>
    </div>
  </div>
</app-login-modal
>
