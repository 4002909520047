import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { LoginService } from '../../../common/login/store/login-state/login.service';
import { Filter } from '../types/filters';
import { snackbarObjectFailedGetReport } from '../snackbar/snackbar';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from '../constants/group-by';
import { first, tap } from 'rxjs/operators';
import { TableColumn } from 'src/app/shared/types/table-column';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import { DataTableModel } from '../model/data-table.model';
import { RowsModel } from '../model/rows.model';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { columns } from '../constants/columns';
import { snackbarFailedObject } from '../../dashboard/snackbar/snackbar';
import { GroupByEnum } from '../enums/groupby-enum';
import { paginationDropDown } from 'src/app/shared/helper/helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  data$: BehaviorSubject<DataTableModel | null> =
    new BehaviorSubject<DataTableModel | null>(null);
  dataFromBackend$: BehaviorSubject<DataTableModel | null> =
    new BehaviorSubject<DataTableModel | null>(null);
  columns$: BehaviorSubject<TableColumn[]> = new BehaviorSubject<TableColumn[]>(
    columns
  );
  rows$: BehaviorSubject<RowsModel[]> = new BehaviorSubject<RowsModel[]>([]);

  private maps = new BehaviorSubject<any>([]);
  public maps$ = this.maps.asObservable();

  private adFormatData = new BehaviorSubject<{ [key: string]: string }>({});
  public adFormatData$ = this.adFormatData.asObservable();
  currentPage = 1;
  limitPerPage = 10;
  public limitOptions = [10];
  private readonly apiUrl: string;

  public filters = new BehaviorSubject<Filter>({
    group_by: groupBy[0].id,
    countries_list: ['-1'],
  });
  public filters$ = this.filters.asObservable();

  private numOfPages = new BehaviorSubject<number>(1);
  public numOfPages$ = this.numOfPages.asObservable();

  private hasCountryGroup = new BehaviorSubject<boolean>(false);
  public hasCountryGroup$ = this.hasCountryGroup.asObservable();

  private hasTagidGroup = new BehaviorSubject<boolean>(false);
  public hasTagidGroup$ = this.hasTagidGroup.asObservable();

  private hasTagidReferralGroup = new BehaviorSubject<boolean>(false);
  public hasTagidReferralGroup$ = this.hasTagidReferralGroup.asObservable();

  private hasLinkGroup = new BehaviorSubject<boolean>(false);
  public hasLinkGroup$ = this.hasLinkGroup.asObservable();

  private chosenLimit = new BehaviorSubject<number>(this.limitOptions[0]);
  public chosenLimit$ = this.chosenLimit.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private generalFacade: GeneralFacade,
    private generalService: GeneralService,
    private translateService: TranslateService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }
  setMapsValue(value: any) {
    this.maps.next(value);
  }

  getMaps() {
    return this.maps.value;
  }

  updateFilters(filters: Partial<Filter>) {
    this.filters.next({ ...this.filters.value, ...filters });
  }

  getFilters() {
    return this.filters.value;
  }

  getReport(hasCountry: boolean, numOfCountries: number | null) {
    const body = this.filters.value;

    if (
      (body.countries_list && body.countries_list.length === numOfCountries) ||
      (hasCountry && body.countries_list?.length === 0)
    ) {
      body.countries_list = ['-1'];
    }

    this.currentPage = 1;
    if (!hasCountry) {
      delete body.countries_list;
    }

    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.reports;

    this.http.post<Array<any>>(url, body, { headers }).subscribe(
      (res: any) => {
        if (res.message.columns.length > 0 && res.message.count != 0) {
          if (
            res.message.results.length > 0 &&
            res.message.results[0].hasOwnProperty('tag_id')
          ) {
            this.hasTagidGroup.next(true);
            this.hasLinkGroup.next(false);
            if (res.message.results[0].operation_id === 57) {
              this.hasTagidReferralGroup.next(true);
            } else {
              this.hasTagidReferralGroup.next(false);
            }
          } else if (res.message.results[0].hasOwnProperty('link')) {
            this.hasTagidGroup.next(false);
            this.hasLinkGroup.next(true);
            this.hasTagidReferralGroup.next(false);
          } else {
            this.hasTagidGroup.next(false);
            this.hasLinkGroup.next(false);
            this.hasTagidReferralGroup.next(false);
          }

          this.dataFromBackend$.next(res.message);
          this.hasCountryGroup.next(hasCountry);

          this.groupByData(this.getFilters().group_by || '');
        } else {
          this.hasCountryGroup.next(hasCountry);
          this.dataFromBackend$.next(res.message);
          this.numOfPages.next(1);
          this.limitOptions = paginationDropDown(
            this.numOfPages.value,
            this.limitPerPage
          );
          this.data$.next(null);
        }

        this.loaderService.hide();

        return res;
      },
      (error: HttpErrorResponse) => {
        this.loaderService.hide();
        return this.generalService.catchError(
          snackbarObjectFailedGetReport,
          error
        );
      }
    );
  }

  formatColumnValue(column_name: string, value: any): any {
    const columnName = column_name.toLowerCase().replace(/\s+/g, '_');
    const columnsToFormat = ['total_revenue', 'cpm', 'sessions_cpm'];
    if (columnsToFormat.includes(columnName)) {
      const numVal = parseFloat(value);
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
      return formatter.format(numVal);
    } else if (columnName === 'operation_id') {
      return this.adFormatData.value[value];
    } else if (columnName === 'report_date') {
      const [year, month, day] = value.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else if (columnName === 'country_code') {
      return this.getCountry(value);
    } else if (columnName == 'link') {
      if (this.maps.value['link_id_to_name_map'][value] == undefined) {
        return value;
      } else {
        return this.maps.value['link_id_to_name_map'][value];
      }
    } else if (columnName === 'tag_id') {
      if (this.hasTagidReferralGroup.value) {
        if (this.maps.value['ref_id_to_ref_name_map'][value] == undefined) {
          return value;
        } else {
          return this.maps.value['ref_id_to_ref_name_map'][value];
        }
      } else {
        if (this.maps.value['tag_id_to_tag_name_map'][value] == undefined) {
          return value;
        } else {
          return this.maps.value['tag_id_to_tag_name_map'][value];
        }
      }
    } else {
      const val = parseFloat(value);
      const formattedValue = val.toLocaleString('en-US');
      return formattedValue;
    }
  }

  getCountry(countryCode: string) {
    if (countryCode) {
      let countriesRaw: any;
      this.generalFacade.enums$
        .pipe(first((val) => !!val))
        .subscribe((state) => {
          countriesRaw = state?.message.countries_map;
        });
      if (countriesRaw[countryCode]) {
        return countriesRaw[countryCode].country_name;
      } else {
        return '';
      }
    }
  }

  getProfit() {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.revenue;
    return this.http.get<string>(url, { headers }).pipe(
      tap(
        (res: any) => {
          return res.message[0];
        },
        (error: HttpErrorResponse) => {
          return this.generalService.catchError(snackbarFailedObject, error);
        }
      )
    );
  }

  getAdFormatEnum() {
    this.generalFacade.enums$.subscribe((r) => {
      this.adFormatData.next(r?.message.ad_formats);
    });
  }

  getAdFormatValue() {
    return this.adFormatData.value;
  }

  setChosenLimit(limit: number) {
    this.chosenLimit.next(limit);
    this.numOfPages.next(Math.ceil(this.rows$.value.length / limit));
  }

  groupByData(filterBy: string): any {
    this.currentPage = 1;
    const data = this.dataFromBackend$.value;

    if (data?.results && data?.results.length > 0 && data?.columns) {
      const filteredResults = data.results.map((result: any) => {
        const filteredResult: any = {};
        for (const key in result) {
          if (key === filterBy) {
            filteredResult[key] = result[key];
          }
        }

        for (const key in result) {
          if (data.columns.includes(key) && key !== filterBy) {
            filteredResult[key] = result[key];
          }
        }
        return filteredResult;
      });

      const groupedAndSummedResults = this.groupAndSumResults(
        filteredResults,
        filterBy
      );

      const finalData = {
        columns: Object.keys(filteredResults[0]),
        count: data.count,
        results: groupedAndSummedResults,
      };

      this.numOfPages.next(
        Math.ceil(groupedAndSummedResults.length / this.limitPerPage)
      );

      this.limitOptions = paginationDropDown(
        this.numOfPages.value,
        this.limitPerPage
      );
      this.data$.next(finalData);

      this.updateColumnsFromServer();
      this.filterRows();
    } else {
      return null;
    }
  }

  groupAndSumResults(filteredResults: any[], filterBy: string): any[] {
    let groupedResults: any = {};
    filteredResults.forEach((result) => {
      const key = result[filterBy];

      if (!groupedResults.hasOwnProperty(key)) {
        groupedResults[key] = { ...result };
      } else {
        for (const prop in result) {
          if (prop !== filterBy && result[prop]) {
            if (!groupedResults[key][prop]) {
              groupedResults[key][prop] = 0;
            }

            const numericValue = parseFloat(result[prop]);

            groupedResults[key][prop] =
              Number(groupedResults[key][prop]) + numericValue;
          }
        }
      }
    });
    let cpmPropExists = false;
    let cpmSessionPropExists = false;
    const columns = this.dataFromBackend$.value?.columns;
    columns?.forEach((column) => {
      if (column === 'cpm') {
        cpmPropExists = true;
      }
      if (column === 'sessions_cpm') {
        cpmSessionPropExists = true;
      }
    });
    if (cpmPropExists || cpmSessionPropExists) {
      for (const key in groupedResults) {
        const result = groupedResults[key];
        if (
          result.total_impressions != undefined &&
          result.total_revenue != undefined &&
          result.total_impressions != 0
        ) {
          result.cpm = (1000 * result.total_revenue) / result.total_impressions;
        }
        if (
          result.total_completed_tasks != undefined &&
          result.total_revenue != undefined &&
          result.total_completed_tasks != 0
        ) {
          result.cpm =
            (1000 * result.total_revenue) / result.total_completed_tasks;
        } else if (
          result.total_impressions != undefined &&
          result.total_impressions == 0 &&
          result.total_completed_tasks != undefined &&
          result.total_completed_tasks == 0
        ) {
          result.cpm = 0;
        }
        if (
          result.total_sessions != undefined &&
          result.total_sessions != 0 &&
          result.total_revenue != undefined
        ) {
          result.sessions_cpm =
            (1000 * result.total_revenue) / result.total_sessions;
        } else if (result.total_sessions == 0) {
          result.sessions_cpm = 0;
        }
      }
    }

    let groupedArray = Object.keys(groupedResults).map(
      (key) => groupedResults[key]
    );

    if (filterBy === GroupByEnum.REPORT_DATE) {
      groupedArray = groupedArray.sort((a, b) => {
        const dateA = new Date(a.report_date);
        const dateB = new Date(b.report_date);
        return dateB.getTime() - dateA.getTime();
      });
    }

    return groupedArray;
  }

  updateColumnsFromServer(
    initTemplates: TableColumn[] = [],
    genericTemplate: TableColumn = {}
  ): void {
    const matchedColumns: TableColumn[] = [];
    const data = this.data$.getValue();
    data?.columns.forEach((columnName) => {
      const formattedColumnName = columnName.toLowerCase().replace(/_/g, ' ');
      const matchingColumn = columns.find((column) => {
        if (column.prop) {
          const isMatch =
            column.prop.toString().toLowerCase() === formattedColumnName;

          return isMatch;
        } else {
          return;
        }
      });

      if (matchingColumn) {
        matchedColumns.push(matchingColumn);
      }
    });

    const updatedArray = matchedColumns.map((obj: any) => {
      const name = this.translateService.instant(obj.name);
      const updatedObj = initTemplates.find(
        (updated: any) => updated.prop === obj.prop
      );
      if (updatedObj) {
        return { ...obj, ...updatedObj, name: name };
      }
      return { ...obj, ...genericTemplate, name: name };
    });
    this.columns$.next(updatedArray);
  }

  filterRows(): void {
    const data = this.data$.getValue();
    const filteredRows = data?.results.map((row) => {
      const filteredRow: RowsModel = {};
      Object.keys(row).forEach((key) => {
        const formattedKey = key.toLowerCase().replace(/_/g, ' ');
        const matchingColumn = columns.find((column) => {
          if (column.prop) {
            const isMatch =
              column.prop.toString().toLowerCase() === formattedKey;
            return isMatch;
          } else {
            return;
          }
        });

        const propertyName = matchingColumn ? matchingColumn.name : key;
        filteredRow[propertyName as keyof RowsModel] = this.formatColumnValue(
          key,
          row[key]
        );
      });
      return filteredRow;
    });
    if (filteredRows) {
      this.rows$.next(filteredRows);
    }
  }
}
