import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RowsModel } from '../../model/rows.model';
import { DashboardService } from '../../service/dashboard.service';
import { combineLatest } from 'rxjs';
import { ColumnsEnum } from '../../enums/columns.enum';
import { SelectionType } from '@swimlane/ngx-datatable';
import { AddSingleService } from '../../../creating-flows/add-single/service/add-single.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { EditMultipleComponent } from '../../../creating-flows/add-single/components/edit-multiple/edit-multiple.component';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { FiltersService } from '../../../statistics/services/filters.service';
import { filtersListsKey } from '../../../statistics/types/filters';
import { Router } from '@angular/router';
import { CopyCodeComponent } from 'src/app/shared/components/copy-code/copy-code.component';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { snackbarCopyCode } from 'src/app/shared/components/copy-code/snackbar/snackbar';
import { Clipboard } from '@angular/cdk/clipboard';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import {columns} from "../../../statistics/constants/columns";
import { oldPanelModalComponent } from "../../../../../shared/components/old-panel-modal/old-panel-modal.component";

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent implements OnInit, OnDestroy {
  limit = 0;
  rows!: any;
  columns!: any;
  numOfPages!: number;
  SelectionType = SelectionType;
  isRowsExist = false;
  isDesktop = true;
  mobileColumns!: any;
  categoriesList!: { key: string; value: string }[];
  selectedCategories!: { key: string; value: string }[];
  isBtnDisabled = true;

  @ViewChild('genericCellTemplate') genericCellTemplate!: TemplateRef<any>;
  @ViewChild('name') name!: TemplateRef<any>;
  @ViewChild('name_header') name_header!: TemplateRef<any>;
  @ViewChild('actions') actions!: TemplateRef<any>;
  @ViewChild('destination_url') destination_url!: TemplateRef<any>;
  @ViewChild('title') title!: TemplateRef<any>;
  @ViewChild('category') category!: TemplateRef<any>;
  @ViewChild('short_link') short_link!: TemplateRef<any>;

  constructor(
    public dashboardService: DashboardService,
    private addSingleService: AddSingleService,
    private overlay: OverlayService,
    private cdr: ChangeDetectorRef,
    private filtersService: FiltersService,
    private router: Router,
    private loaderService: LoaderService,
    private clipboard: Clipboard,
    private snackbarService: SnackbarService,
    private generalService: GeneralService
  ) {}

  ngOnDestroy(): void {
    this.dashboardService.updateSelectedCampaigns([]);
  }

  private showModalOncePerDay() {
    const lastShown = localStorage.getItem('lastShown');
    const now = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    if (!lastShown || (now - parseInt(lastShown) > twentyFourHours)) {
      this.overlay.open(oldPanelModalComponent, {});
      localStorage.setItem('lastShown', now.toString());
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('oldUser')) {
      this.showModalOncePerDay();
    }
    this.dashboardService.getCategory().subscribe((val) => {
      this.categoriesList = val.message.filter(
        (category: string) => category !== ''
      );

      this.selectedCategories = this.categoriesList;

      this.selectAllForDropdownItems(this.categoriesList);
    });
    this.dashboardService.rows$.subscribe((rows: RowsModel[]) => {
      this.isRowsExist = rows.length > 0;
      this.updateColumns();
    });
  }

  onSelectChange() {
    this.isBtnDisabled = false;
  }
  submit() {
    this.loaderService.show();
    this.isBtnDisabled = true;
    const selectedCategoryValues = this.selectedCategories.map(
      (category: any) => category.value
    );
    this.dashboardService
      .getContentLockerTable(selectedCategoryValues)
      .subscribe();
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any) => {
      items.forEach((element: any, index: number) => {
        items[index] = {
          value: element,
          selectedAllGroup: 'selectedAllGroup',
        };
      });
    };

    allSelect(items);
  }

  async ngAfterViewInit() {
    combineLatest([
      this.dashboardService.chosenLimit$,
      this.dashboardService.numOfPages$,
      this.dashboardService.columns$,
      this.dashboardService.rows$,
      this.generalService.isDesktop$,
    ]).subscribe(([chosenLimit, numOfPages, columns, rows, isDesktop]) => {
      this.rows = rows;
      this.columns = columns;
      this.limit = chosenLimit;
      this.numOfPages = numOfPages;
      this.isDesktop = isDesktop;
      this.filterColumns();

      this.cdr.detectChanges();
    });
  }

  changePage(event: any) {
    this.dashboardService.currentPage = event.page;
    return true;
  }

  updateColumns() {
    this.dashboardService.updateColumnsData(
      [
        {
          prop: ColumnsEnum.NAME,
          headerTemplate: this.name_header,
          cellTemplate: this.name,
          checkboxable: true,
          headerCheckboxable: true,
        },
        {
          prop: ColumnsEnum.CATEGORY,
          cellTemplate: this.category,
        },
        {
          prop: ColumnsEnum.ACTIONS,
          cellTemplate: this.actions,
        },
        {
          prop: ColumnsEnum.DESTINATION_URL,
          cellTemplate: this.destination_url,
        },

        {
          prop: ColumnsEnum.TITLE,
          cellTemplate: this.title,
        },
        {
          prop: ColumnsEnum.SHORT_LINK,
          cellTemplate: this.short_link,
        },
      ],
      {}
    );
  }

  filterColumns(): void {
    this.mobileColumns = this.columns.filter(
      (column: any) => column.prop !== ColumnsEnum.ACTIONS
    );
  }

  editSingleContentLocker(row: RowsModel) {
    if (row.short_link) {
      this.addSingleService.short_link = row.short_link;
    }
    const contentData = {
      short_name: row.short_name || '',
      url: row.url || '',
      title: row.title || '',
      editMode: true,
      id: row.id,
      tag_id: row.tag_id,
      short_link: row.short_link,
      theme_id: row.theme_id,
      tier_id: row.tier_id || 0,
      sub_id: row.sub_id || '',
      background: row.background,
      number_of_tasks: row.number_of_tasks,
      social_links: row.social_links,
      social_channel: row.social_channel,
    };
    this.addSingleService.openSingleContentLockerComponent(contentData);
  }

  deleteSingleContentLocker(row: RowsModel) {
    const ref = this.overlay.open(DeletePopupComponent, {});
    ref.afterClosed$.subscribe((res) => {
      if (res.data === true) {
        if (row && row.id) {
          const id = [row.id];
          this.dashboardService.deleteContentLocker(id, row.id);
        }
      }
    });
  }

  navigateToStatistics(row: RowsModel) {
    this.filtersService.navigateFromPage = true;
    this.filtersService.navigateFrom(
      [
        {
          key: row.id || '',
          value: row.name || '',
        },
      ],
      filtersListsKey.links
    );
    this.router.navigate(['/statistics']);
  }

  selectCampaigns({ selected }: any) {
    this.dashboardService.updateSelectedCampaigns(selected);
  }

  closeBulkSelection() {
    this.dashboardService.updateSelectedCampaigns([]);
  }

  deleteMultipleContentLocker() {
    const ref = this.overlay.open(DeletePopupComponent, {});
    ref.afterClosed$.subscribe((res) => {
      if (res.data === true) {
        if (this.dashboardService.selectedCampaigns.getValue().length >= 5) {
          this.loaderService.show();
        }
        this.dashboardService.deleteMultipleContentLocker();
      } else {
        this.dashboardService.updateSelectedCampaigns([]);
      }
    });
  }

  searchElement(filterTerm: string) {
    this.dashboardService.filterTable(filterTerm);
  }

  editMultipleContentLocker() {
    const ref = this.overlay.open(EditMultipleComponent, {});
    ref.afterClosed$.subscribe(() => {
      this.dashboardService.updateSelectedCampaigns([]);
    });
  }

  copyCodePopup(row: RowsModel) {
    this.clipboard.copy(row.short_link || '');
    this.snackbarService.showSnackbar(snackbarCopyCode);
  }
}
