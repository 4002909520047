<app-login-modal [formGroup]="passwordForm">
  <div class="titles-container">
    <span class="typography-heading3-thin title">
      {{ 'reset_password.title' | translate }}
    </span>
    <span class="typography-body4-thin subtitle">
      {{ 'reset_password.subtitle' | translate }}
    </span>
  </div>

  <div class="input-fields-container" [formGroup]="passwordForm">
    <div *ngFor="let passwordItem of passwordArray; let i = index">
      <div class="container">
        <label class="typography-footnote1 labels">{{
          i === 0
            ? ('reset_password.password' | translate)
            : ('reset_password.password_confirm' | translate)
        }}</label>
        <div class="password-container">
          <app-input
            [type]="passwordItem.password ? 'text' : 'password'"
            class="input-field"
            [errorMessage]="
              !passwordForm.controls[i === 0 ? 'password' : 'confirmPassword']
                .value &&
              passwordForm.controls[i === 0 ? 'password' : 'confirmPassword']
                .touched
                ? 'Enter your password'
                : null
            "
            [invalid]="
              passwordForm.controls[i === 0 ? 'password' : 'confirmPassword']
                .errors &&
              passwordForm.controls[i === 0 ? 'password' : 'confirmPassword']
                .touched
            "
            (inputFocusOut)="
              passwordForm.controls[
                i === 0 ? 'password' : 'confirmPassword'
              ].markAsTouched()
            "
            [placeholder]="'reset_password.placeholder' | translate"
            [formControlName]="i === 0 ? 'password' : 'confirmPassword'"
          ></app-input>
          <div
            class="emoji-btn"
            (click)="
              togglePasswordVisibility(
                i === 0 ? 'password' : 'passwordConfirmation'
              )
            "
          >
            <app-icon
              class="icon"
              [name]="passwordItem.passwordIcon"
            ></app-icon>
          </div>
        </div>
      </div>
    </div>
    <p class="error typography-footnote1" *ngIf="!isEqualPassword">
      {{ 'reset_password.passwords-error' | translate }}
    </p>
  </div>

  <div class="buttons-container">
    <app-button
      class="log-in-button full-width-button"
      (buttonClick)="submit()"
      [fullWidth]="true"
    >
      {{ 'reset_password.button' | translate }}
    </app-button>
  </div>
</app-login-modal>
