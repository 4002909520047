import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  public options: AnimationOptions = {
    path: '/assets/lottie/loader.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    width: '200px',
    height: '400px',
    zIndex: '999999',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}
