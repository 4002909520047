<div class="table-container" *ngIf="data && isDesktop">
  <ngx-datatable
    #table
    class="table typography-body4-thin"
    [rows]="statisticsService.rows$ | async"
    [columns]="(statisticsService.columns$ | async) || []"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [limit]="limit"
    [summaryRow]="true"
    [summaryPosition]="'bottom'"
    [summaryHeight]="55"
  >
    <ng-container *ngFor="let column of columns; let i = index">
      <ngx-datatable-column
        [summaryTemplate]="i == 0 ? currencySummaryCell : columnSummaryCell"
        [sortable]="true"
        [name]="column.name || ''"
        [prop]="column.name || ''"
        [tooltip]="column.name || ''"
        [comparator]="column.isCurrency ? currencyComparator : undefined"
      >
        <ng-template ngx-datatable-header-template let-column="column">
          <div
            *ngIf="getTooltip(column.name) !== ''"
            tooltip
            [tooltip]="getTooltip(column.name) | translate"
          >
            {{ column.name | translate }}
          </div>
          <div *ngIf="!getTooltip(column.name)">
            {{ column.name | translate }}
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div class="footer-container">
          <div class="pager-container">
            <app-button
              [ngClass]="{ 'icon-disabled-style': curPage <= 1 }"
              color="naked"
              (buttonClick)="pager.prevPage()"
            >
              <app-icon start name="navigation_left"></app-icon>
            </app-button>
            <datatable-pager
              #pager
              [page]="statisticsService.currentPage"
              [count]="rowCount"
              [size]="limit"
              (change)="changePage($event) && table.onFooterPage($event)"
            ></datatable-pager>
            <app-button
              [ngClass]="{
                'icon-disabled-style': curPage === numOfPages
              }"
              color="naked"
              (buttonClick)="pager.nextPage()"
            >
              <app-icon start name="navigation_right"></app-icon>
            </app-button>
          </div>
          <div class="right-pagination">
            <div class="typography-paragraph">
              {{ 'statistics.show' | translate }}
            </div>
            <ng-select
              class="pagination"
              [ngModel]="limit"
              (ngModelChange)="statisticsService.setChosenLimit($event)"
              [items]="statisticsService.limitOptions"
              appendTo="body"
              [clearable]="false"
              [searchable]="false"
            ></ng-select>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<div *ngIf="data && !isDesktop">
  <div class="cards" *ngFor="let row of rows">
    <div class="card">
      <div *ngFor="let column of columns">
        <ng-container *ngIf="column.prop !== undefined">
          <div class="content">
            <div class="typography-caption description">
              {{ column.name }}
            </div>
            <div class="typography-body4-thin text">
              {{ row[column.name!] }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!data">
  <app-empty-state
    [title]="'statistics.empty_state.title'"
    [subtitle]="'statistics.empty_state.subtitle'"
    [isMobile]="!isDesktop"
  ></app-empty-state>
</div>

<ng-template #currencySummaryCell>
  <div class="color-white">
    <div>{{ 'statistics.total_rows' | translate }} {{ numOfRows }}</div>
  </div>
</ng-template>

<ng-template #columnSummaryCell let-column="column">
  <div class="color-white">
    {{ calculateColumnSum(column.prop) }}
  </div>
</ng-template>
