import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import {
  NotificationModel,
  NotificationRedirect,
} from '../models/notifications.model';
import { NotificationType } from '../models/notifications.model';
import { RedirectRouteEnum } from '../enum/notification.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly apiUrl: string;

  public notificationsData = new BehaviorSubject<Array<NotificationModel>>(
    new Array<NotificationModel>()
  );
  public notificationsData$ = this.notificationsData.asObservable();

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getNotifications() {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.notifications;

    return this.http.get<Array<any>>(url, { headers }).subscribe((r: any) => {
      this.notificationsData.next(r.message);
    });
  }

  getNavigation(type: keyof typeof RedirectRouteEnum, data?: string) {
    const navigation: NotificationRedirect = {
      route: RedirectRouteEnum[type],
      data: data,
    };
    return navigation;
  }

  createNotification(notification: Partial<NotificationModel>) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.notifications;

    return this.http.post(url, notification, { headers });
  }

  createNotificationObject(details: {
    notificationType: string;
    id?: number;
    publisher_id?: number | null;
    body?: string;
  }) {
    const notification: Partial<NotificationModel> = {
      original_id: details.id,
      title: '',
      body: '',
      subject: '',
      redirect: '',
      publisher_id: details.publisher_id,
    };

    switch (details.notificationType) {
      case NotificationType.ContentLocker:
        notification.title = 'New Content Locker Link Created';
        notification.subject = NotificationType.ContentLocker;
        notification.body = ' LootLabs link generated';
        notification.redirect = 'Open Locker';

        break;
      case NotificationType.ContentJs:
        notification.title = 'LootLabs JavaScript created';
        notification.subject = NotificationType.ContentJs;
        notification.body = 'New Content JS has been created';
        notification.redirect = 'Load JS';

        break;

      case NotificationType.PersonalDetails:
        notification.title = 'Personal Details Were Updated';
        notification.subject = NotificationType.PersonalDetails;
        notification.body = 'LootLabs Personal details updated';
        notification.redirect = 'Review Information';

        break;
      case NotificationType.AffiliateLink:
        notification.title = 'Affiliate Link Was Created';
        notification.subject = NotificationType.AffiliateLink;
        notification.body = 'LootLabs Affiliate link generated';
        notification.redirect = 'View Link';

        break;

      case NotificationType.BulkLink:
        notification.title = 'Bulk Link Was Uploaded';
        notification.subject = NotificationType.BulkLink;
        notification.body = 'A bulk link upload has been completed';
        notification.redirect = 'View Uploaded Links';
    }

    return notification;
  }

  updateNotificationsSeen() {
    const headers = this.loginService.TokenFromStorage;
    const url =
      this.apiUrl +
      this.appConfig.actions.notifications +
      this.appConfig.params.notificationsSeen;

    return this.http.put(url, {}, { headers });
  }

  updateNotificationsClicked() {
    const headers = this.loginService.TokenFromStorage;
    const url =
      this.apiUrl +
      this.appConfig.actions.notifications +
      this.appConfig.params.notificationClicked;

    return this.http.put(url, {}, { headers });
  }

  updateNotificationSeen(notificationID?: number | null) {
    const headers = this.loginService.TokenFromStorage;
    const url =
      this.apiUrl +
      this.appConfig.actions.notifications +
      this.appConfig.params.notificationId +
      notificationID;

    return this.http.put(url, {}, { headers });
  }

  onViewAllNotifications = () => {
    this.updateNotificationsSeen().subscribe(() => {
      this.getNotifications();
    });
  };

  onClickAllNotification = () => {
    this.updateNotificationsClicked().subscribe(() => {
      this.getNotifications();
    });
  };
}
