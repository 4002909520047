import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { rangeOptions } from './date-preset';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  alwaysShowCalendars!: boolean;

  @Input() ranges = rangeOptions;
  @Input() selectedRange = {
    startDate: this.ranges['This Year'][0],
    endDate: this.ranges['This Year'][1],
  };

  @Input() formattedValue!: string;
  @Output() dateSelected: EventEmitter<any> = new EventEmitter<any>();

  localeConfig = {
    format: 'MMM D, YYYY',
    display: 'MMM D, YYYY',
    separator: ' - ',
  };

  ngOnInit(): void {
    this.alwaysShowCalendars = true;
  }
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  formatSelectedRange(event: any) {
    this.selectedRange = event;

    this.dateSelected.emit(event);
  }
}
