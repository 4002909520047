import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../service/profile.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss'],
})
export class ApiKeyComponent implements OnInit {
  isGenerate = false;
  constructor(
    private profileService: ProfileService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.isGenerate =
      this.generalService.api_user_token !== '' &&
      this.generalService.api_user_token !== null;
  }

  generate() {
    this.isGenerate = true;
    this.profileService.generateCode().subscribe();
  }

  getCode() {
    if (!this.isGenerate || !this.generalService.api_user_token) {
      return '';
    } else {
      return `${this.generalService.api_user_token}`;
    }
  }
}
