import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  uploadImage(file: File): Observable<any> {
    const headers = this.loginService.TokenFromStorage;

    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post(`${this.apiUrl}/resources`, formData, { headers })
      .pipe(
        map((res: any) => {
          if (res != null) {
            return res.message;
          }
        })
      );
  }

  getFiles(): Observable<any> {
    const headers = this.loginService.TokenFromStorage;
    return this.http.get(`${this.apiUrl}/files`, { headers });
  }
}
