<div
  class="container background popupWith"

>
  <div
    class="header"
  >
    <span class="typography-title">{{
      "old_panel.title" | translate
      }}</span>
    <app-icon class="close" name="close" (click)="close()"></app-icon>
  </div>
  <div class="copy-frame-container">
    <span class="subtitle">{{
      "old_panel.sub_title" | translate
      }}</span>
    <span class="subtitle">    <a target="_blank" href="https://help.lootlabs.gg/en/">{{
        "old_panel.sub_title3" | translate
      }}</a> {{
      "old_panel.sub_title2" | translate
      }}</span>
  </div>
<!--  <app-button-->
<!--    style="margin: auto"-->
<!--    class="button-flex"-->
<!--    size="md"-->
<!--    (buttonClick)="redirectToOld()"-->
<!--    color="secondary"-->
<!--  >-->
<!--    {{"old_panel.button" | translate}}-->
<!--  </app-button>-->
</div>
