import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() userDetails!: UserModel;
  disableCities!: boolean;
  public countryChoose = '';
  public thereIsState!: boolean;
  constructor(public accountService: AccountService) {}

  ngOnChanges() {
    this.addressForm.get('country')?.valueChanges.subscribe((val) => {
      this.onCountryChanged(val);
    });

    this.accountService.countries$.subscribe(() => {
      this.initialStateCityInput();
    });

    this.addressForm.controls['state'].valueChanges.subscribe((value) => {
      this.OnStateChange(value);
    });
  }

  initialStateCityInput() {
    const countryValue =
      this.addressForm.get('country')?.value || this.userDetails.country;
    this.thereIsState = this.accountService.thereIsState(countryValue);
  }

  onCountryChanged(country: string) {
    this.countryChoose = country;

    this.initialStateCityInput();
    if (this.thereIsState === false) {
      this.disableCities = false;
    } else if (this.thereIsState === true) {
      this.disableCities = true;
    }

    if (!country) {
      this.disableCities = true;
      this.accountService.loadStates('');
      this.accountService.loadCities('', '');
      this.thereIsState = false;
      return;
    }

    this.addressForm.get('state')?.setValue(null);
    this.addressForm.get('city')?.setValue(null);

    if (this.accountService.thereIsState(country)) {
      this.accountService.loadStates(country);
      this.accountService.loadCities('', '');
      this.thereIsState = true;
      this.disableCities = true;
    } else {
      this.disableCities = false;
      this.accountService.loadCities(country, '');

      this.thereIsState = false;
    }
  }

  OnStateChange(value: string) {
    this.addressForm.get('city')?.setValue(null);
    if (!value) {
      this.disableCities = true;
      this.thereIsState = true;
      this.accountService.loadCities('', '');
      this.addressForm.get('city')?.setValue(null);
      return;
    }
    this.disableCities = false;
    this.accountService.loadCities(
      this.countryChoose || this.userDetails.country,
      value
    );
  }

  get addressForm() {
    return this.form.get('address') as FormGroup;
  }
}
