import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/pages/creator/dashboard/service/dashboard.service';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { AddSingleService } from '../../service/add-single.service';

@Component({
  selector: 'app-edit-multiple',
  templateUrl: './edit-multiple.component.html',
  styleUrls: ['./edit-multiple.component.scss'],
})
export class EditMultipleComponent implements OnInit {
  singleLinkForm!: FormGroup;

  constructor(
    public dashboardService: DashboardService,
    private ref: MyOverlayRef,
    private addSingleService: AddSingleService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.singleLinkForm = new FormGroup({
      theme_id: new FormControl(null, [Validators.required]),
      tier_id: new FormControl(null, [Validators.required]),
      number_of_tasks: new FormControl(1, [Validators.required]),
    });
  }

  close() {
    this.ref.close();
  }

  submit() {
    this.addSingleService
      .editMultipleContentLocker(this.singleLinkForm.value)
      .subscribe((res: any) => {
        this.dashboardService.updateData().subscribe();
        this.close();
      });
  }
}
