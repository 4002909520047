<div class="classic-container">
  <div class="creator-details">
    <img class="creator-picture" [src]="creator_picture" alt="creator_pic" />
    <div
      [tooltip]="creator_name"
      class="creator-name typography-heading4-bold {{ theme }}"
      [ngClass]="{
        'color-black': theme === ThemeEnum.SIMS
      }"
    >
      {{ creator_name }}
    </div>
    <div *ngIf="atLeastOneSocialLink" class="social-links {{ theme }}">
      <ng-container *ngFor="let link of socialLinks">
        <app-icon
          *ngIf="social_links.get(link.controlName)?.value"
          [name]="link.iconName"
        ></app-icon>
      </ng-container>
    </div>
  </div>
  <div class="values {{ theme }}">
    <div
      class="typography-body3-bold title line-height"
      [ngClass]="{
        'color-white':
          theme === ThemeEnum.SPACE ||
          theme === ThemeEnum.SIMS ||
          theme === ThemeEnum.GTA,
        'color-black':
          theme === ThemeEnum.CLASSIC || theme === ThemeEnum.MINECRAFT
      }"
      tooltip="{{ title || 'single.step_1.preview.title' | translate }}"
    >
      {{ title || 'single.step_1.preview.title' | translate }}
    </div>
    <div class="background-img-link">
      <img
        class="img-background"
        *ngIf="
          (background && !background.includes('youtube')) ||
          (background && !background.includes('youtu'))
        "
        [src]="background"
        alt="background"
      />
      <img
        class="img-youtube"
        *ngIf="
          (background && background.includes('youtube')) ||
          (background && background.includes('youtu'))
        "
        [src]="youtube_logo_src"
      />
    </div>

    <div
      class="complete_instructions typography-caption line-height"
      [ngClass]="{
        'color-white': theme === ThemeEnum.SPACE || theme === ThemeEnum.SIMS,
        'color-black':
          theme === ThemeEnum.CLASSIC ||
          theme === ThemeEnum.MINECRAFT ||
          theme === ThemeEnum.GTA
      }"
    >
      {{ 'single.step_1.preview.subtitle' | translate }}
    </div>
    <div
      class="typography-headline2 color-black"
      [ngClass]="{ tasks: item < 3, none: item >= 3 }"
      *ngFor="let item of number_of_tasks_arr"
    >
      <div
        *ngIf="item < 3"
        class="task {{ theme }}"
        [ngClass]="{
          task: item < 3,
          none: item >= 3
        }"
        [ngClass]="{
          'color-white':
            theme === ThemeEnum.SPACE ||
            theme === ThemeEnum.SIMS ||
            theme === ThemeEnum.GTA ||
            theme === ThemeEnum.MINECRAFT,
          'color-black': theme === ThemeEnum.CLASSIC
        }"
      >
        <img class="img" [src]="share_src" />
        <span>{{
          'single.step_1.preview.task'
            | translate
              : {
                  variable: item
                }
        }}</span>
        <img class="img" [src]="question_mark_src" />
      </div>
    </div>
    <span
      [ngClass]="{
        'color-white':
          theme === ThemeEnum.SPACE ||
          theme === ThemeEnum.SIMS ||
          theme === ThemeEnum.GTA,
        'color-black':
          theme === ThemeEnum.CLASSIC || theme === ThemeEnum.MINECRAFT
      }"
      class="typography-caption"
      *ngIf="number_of_tasks > 2"
      >{{
        'single.step_1.preview.tasks'
          | translate
            : {
                variable: number_of_tasks - 2
              }
      }}</span
    >
    <div
      class="typography-body4-bold"
      [ngClass]="{
        'color-white':
          theme === ThemeEnum.SPACE ||
          theme === ThemeEnum.SIMS ||
          theme === ThemeEnum.GTA,
        'color-black':
          theme === ThemeEnum.CLASSIC || theme === ThemeEnum.MINECRAFT
      }"
    >
      <span>{{
        'single.step_1.preview.progress'
          | translate
            : {
                variable: number_of_tasks_arr.length
              }
      }}</span>
    </div>
    <div class="progress-container">
      <div class="progress typography-body4-thin {{ theme }}">
        {{ number_of_tasks_arr.length }}
      </div>
    </div>
    <div class="grey-background-container">
      <div
        class="grey-background color-black typography-body4-bold {{ theme }}"
      >
        {{ 'single.step_1.preview.unlock' | translate }}
      </div>
    </div>
  </div>
</div>
