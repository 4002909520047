<div class="general-info" [formGroup]="generalInfoForm">
  <div class="contact-info">
    <div class="title typography-heading4-thin">
      {{
        'profile.personal_settings_page.general_info.contact_info' | translate
      }}
    </div>
    <div class="form">
      <div class="line">
        <label class="typography-headline2">
          {{
            'profile.personal_settings_page.general_info.form_fields.field_name'
              | translate
          }}
        </label>
        <app-input
          typographyClass="typography-font1"
          formControlName="full_name"
          [invalid]="
            (generalInfoForm.get('full_name')?.hasError('required') ||
              generalInfoForm.get('full_name')?.hasError('minlength')) &&
            generalInfoForm.get('full_name')?.dirty
          "
          [placeholder]="
            'profile.personal_settings_page.general_info.form_fields.place_holder_name'
              | translate
          "
        ></app-input>
      </div>
      <div class="line">
        <label class="typography-headline2">{{
          'profile.personal_settings_page.general_info.form_fields.field_email'
            | translate
        }}</label>
        <app-input
          typographyClass="typography-font1"
          formControlName="username"
          [invalid]="
            generalInfoForm.get('username')?.hasError('required') &&
            !generalInfoForm.get('username')?.pristine
          "
        ></app-input>
      </div>
      <div class="line">
        <label class="typography-headline2">
          {{
            'profile.personal_settings_page.general_info.form_fields.field_contact_platform'
              | translate
          }}</label
        >
        <div class="contact">
          <ng-select
            class="dropdown typography-font1"
            [multiple]="false"
            [items]="accountService.contactPlatform$ | async"
            bindLabel="value"
            bindValue="key"
            formControlName="signup_contact_type"
            [clearable]="false"
            [ngClass]="{
              'has-value': generalInfoForm.get('contact_source1') !== null
            }"
            [class.error]="
              generalInfoForm.get('signup_contact_type')?.hasError('required')
            "
            [clearable]="true"
          >
          </ng-select>
          <app-input
            typographyClass="typography-font1"
            formControlName="signup_contact_id"
            [placeholder]="
              'profile.personal_settings_page.general_info.form_fields.place_holder_contact_platform'
                | translate
            "
            [class.error]="
              generalInfoForm.get('signup_contact_type')?.hasError('minlength')
            "
            [invalid]="
              generalInfoForm.get('signup_contact_id')?.hasError('minlength') &&
              !generalInfoForm.get('signup_contact_id')?.pristine
            "
          ></app-input>
        </div>
      </div>
    </div>
  </div>
  <div class="company-info">
    <div class="header">
      <div class="title typography-heading4-thin">
        {{
          'profile.personal_settings_page.general_info.company_info' | translate
        }}
      </div>
      <div class="subtitle typography-headline2">
        {{
          'profile.personal_settings_page.general_info.company_info_subtitle'
            | translate
        }}
      </div>
    </div>
    <div class="form">
      <div class="line">
        <label class="typography-headline2">{{
          'profile.personal_settings_page.general_info.form_fields.field_company'
            | translate
        }}</label>
        <app-input
          typographyClass="typography-font1"
          formControlName="company_name"
          [placeholder]="
            'profile.personal_settings_page.general_info.form_fields.place_holder_company'
              | translate
          "
          [invalid]="
            generalInfoForm.get('company_name')?.hasError('required') &&
            !generalInfoForm.get('company_name')?.pristine
          "
        ></app-input>
      </div>
      <div class="line">
        <label class="typography-headline2">{{
          'profile.personal_settings_page.general_info.form_fields.field_vatid'
            | translate
        }}</label>
        <app-input
          typographyClass="typography-font1"
          formControlName="safety_number"
          [placeholder]="
            'profile.personal_settings_page.general_info.form_fields.place_holder_vatid'
              | translate
          "
          [invalid]="
            generalInfoForm.get('safety_number')?.hasError('minlength') &&
            !generalInfoForm.get('safety_number')?.pristine
          "
          [class.error]="
            generalInfoForm.get('safety_number')?.hasError('minlength')
          "
        ></app-input>
      </div>
    </div>
  </div>
</div>
