/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, Type } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export function getValueAccessor(component: Type<any>): any {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true,
  };
}

export abstract class BaseControlValueAccessor<T>
  implements ControlValueAccessor
{
  /**
   * Call when value has changed programatically
   */
  protected onChange<T>(newVal: T) {}
  protected onTouched(_?: any) {}

  /**
   * Model -> View changes
   */
  abstract writeValue(obj: any): void;

  setDisabledState(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
