import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { APP_CONFIG } from 'src/app';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  signUpLink!: string;
  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() formValueChanged = new EventEmitter<{ key: string }>();
  public isDesktop!: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private router: Router,
    private generalService: GeneralService
  ) {
    this.signUpLink = this.appConfig.links.signUpLink;
  }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe({
      next: (val) => {
        this.formValueChanged.emit(val);
      },
    });
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
  }

  navigateToLogin() {
    this.router.navigate(['login']).then();
  }

  signUp() {
    window.open(this.signUpLink);
  }
}
