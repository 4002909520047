import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToggleOption } from 'src/app/shared/components/toggle-button/type/toggle-option';
import { groupBy } from '../../constants/group-by';
import { groupByButtonComponent } from 'src/app/shared/components/toggle-button/group-by-button.component';
import { StatisticsService } from '../../services/statistics.service';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { combineLatest } from 'rxjs';
import { GroupByEnum } from '../../enums/groupby-enum';

@Component({
  selector: 'app-group-by',
  templateUrl: './group-by.component.html',
  styleUrls: ['./group-by.component.scss'],
})
export class GroupByComponent implements OnInit {
  @ViewChild(groupByButtonComponent)
  groupByButton!: groupByButtonComponent;
  @ViewChild('contentTemplate', { static: true })
  contentTemplate!: TemplateRef<any>;
  typeButtons: ToggleOption[] = groupBy.filter(option => option.id == GroupByEnum.OPERATION || option.id == GroupByEnum.REPORT_DATE);
  isDesktop!: boolean;
  firstTimeRun = true;
  picker = groupBy[0].label;
  open = false;
  private bottomSheetRef!: MatBottomSheetRef<BottomSheetComponent>;

  constructor(
    private generalService: GeneralService,
    private statisticsService: StatisticsService,
    private _bottomSheet: MatBottomSheet,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.generalService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;

      if (!this.firstTimeRun && isDesktop) {
        this.typeButtons.forEach((item: ToggleOption) => {
          if (item.label === this.picker) {
            setTimeout(() => {
              this.groupByButton?.setCurrentOption(item.id);
            });
          }
        });
      }
    });
    combineLatest([
      this.statisticsService.hasLinkGroup$,
      this.statisticsService.hasTagidGroup$,
      this.statisticsService.hasCountryGroup$,
    ]).subscribe(([link, tagid, country]) => {
      let groupBtns = groupBy.filter(option => option.id == GroupByEnum.OPERATION || option.id == GroupByEnum.REPORT_DATE);
      if (country) {
        groupBtns.push(groupBy[2]);
        if (this.picker === groupBy[2].label) {
          this.updateGroupByToDate();
        }
      }

      if (tagid) {
        groupBtns.push(groupBy[3]);
        if (this.picker === groupBy[3].label) {
          this.updateGroupByToDate();
        }
      }

      if (link) {
        groupBtns.push(groupBy[4]);
        if (this.picker === groupBy[4].label) {
          this.updateGroupByToDate();
        }
      }

      this.typeButtons = groupBtns;
    });

    this.firstTimeRun = false;
    setTimeout(() => {
      this.groupByButton?.setCurrentOption(this.typeButtons[0].id);
    });
  }

  ngOnDestroy() {
    this.updateGroupByToDate();
  }

  groupByChanges(event: any) {
    if (event !== this.statisticsService.getFilters().group_by) {
      this.typeButtons.forEach((item: ToggleOption) => {
        if (event === item.id) {
          this.picker = item.label;
        }
      });
      this.statisticsService.updateFilters({ group_by: event || '' });

      this.statisticsService.groupByData(event);
    }
  }

  updateGroupByToDate() {
    setTimeout(() => {
      this.groupByButton?.setCurrentOption(this.typeButtons[0].id);
    });

    this.picker = groupBy[0].label;
    this.groupByChanges(this.typeButtons[0].id);
  }

  openBottomSheet(): void {
    this.open = true;
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
      data: {
        title: this.translateService.instant('statistics.group_by.title'),
        contentTemplate: this.contentTemplate,
        closeIcon: true,
      },
    });

    this.bottomSheetRef.afterDismissed().subscribe(() => {
      this.open = false;
    });
  }

  closeBottomSheet(event?: any) {
    this.bottomSheetRef.dismiss();
  }

  onClickStatus(group: ToggleOption) {
    this._bottomSheet.dismiss();
    if (this.picker !== group.label) {
      this.picker = group.label;
      this.groupByChanges(group.id);
    }
  }
}
