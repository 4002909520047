import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatingCardsComponent } from './creating-cards.component';
import { CreatingCardModule } from './components/creating-card/creating-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddSingleModule } from '../add-single/add-single.module';
import { AddMultipleModule } from '../add-multiple/add-multiple.module';
import { AddFullScriptModule } from '../add-full-script/add-full-script.module';

@NgModule({
  declarations: [CreatingCardsComponent],
  imports: [
    CommonModule,
    CreatingCardModule,
    TranslateModule,
    SharedModule,
    AddSingleModule,
    AddMultipleModule,
    AddFullScriptModule,
  ],
  exports: [CreatingCardsComponent],
})
export class CreatingCardsModule {}
