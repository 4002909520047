<div class="empty-state-container">
  <img src="./assets/svg/empty_state.svg" />
  <div
    class="title typography-heading3-bold"
    [ngClass]="{ 'title-mobile': isMobile }"
  >
    {{ title | translate }}
  </div>
  <div
    class="subtitle typography-body3-thin"
    [ngClass]="{ 'subtitle-mobile': isMobile }"
  >
    {{ subtitle | translate }}
  </div>
</div>
