<div class="no-details typography-body2-bold">
  <div class="header">
    <app-button (buttonClick)="close()" color="naked">
      <app-icon class="close" name="close"></app-icon>
    </app-button>
  </div>
  <div class="title">
    <div class="text">
      {{ 'no_details_popup.title' | translate }}
    </div>
  </div>
</div>
