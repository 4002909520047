import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { InputModule } from '../input/input.module';
import { UploadImageComponent } from './upload-image.component';

@NgModule({
  declarations: [UploadImageComponent],
  imports: [
    CommonModule,
    IconModule,
    InputModule,
    ButtonModule,
    TranslateModule,
  ],
  exports: [UploadImageComponent],
})
export class UploadImageModule {}
