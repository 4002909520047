import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOOTLABS_DATA, GeneralState } from './general-state.state';

export const getGeneralState =
  createFeatureSelector<GeneralState>(LOOTLABS_DATA);

export const getEnums = createSelector(
  getGeneralState,
  (state: GeneralState) => state.lootLabsData?.enums
);

export const getLootLabsData = createSelector(
  getGeneralState,
  (state: GeneralState) => state.lootLabsData
);
