import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  BaseControlValueAccessor,
  getValueAccessor,
} from '../forms/reactive-form/base-control-value-accessor';
import { CheckboxTemplateRefModel } from '../../types/checkbox-template-ref.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [getValueAccessor(AppCheckboxComponent)],
})
export class AppCheckboxComponent extends BaseControlValueAccessor<boolean> {
  /**
   * Is the checkbox checked
   *
   * @type {boolean}
   */
  @Input() checked = false;

  /**
   * Make the checkbox disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * The label of the checkbox
   *
   * @type {string}
   */
  @Input() label = '';

  /**
   * Is checkbox custom style
   *
   * @type {boolean}
   */
  @Input() custom = false;

  /**
   * Is checkbox custom style
   *
   * @type {boolean}
   */
  @Input() invalid = false;

  /**
   * Emitter for checkbox change
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() checkboxChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ContentChild(TemplateRef)
  templateRef!: TemplateRef<CheckboxTemplateRefModel>;

  /**
   * Emit checkbox change event
   */
  onCheckboxChange(): void {
    this.checked = !this.checked;
    this.checkboxChanged.emit(this.checked);
    this.onChange(this.checked);
  }

  writeValue(value: boolean) {
    this.checked = value;
  }

  override setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
