import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyCodeComponent } from './copy-code.component';
import { IconModule } from '../icon/icon.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [CopyCodeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonModule,
    IconModule,
    InputModule,
  ],
  exports: [CopyCodeComponent],
})
export class CopyCodeModule {}
