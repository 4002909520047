import { Component, EventEmitter, Input, Output } from '@angular/core';
import { capitalizeFirstLetter, removeUnderLine } from '../../helper/helper';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Output() checkboxChanged = new EventEmitter<{
    key: string;
    value: boolean;
  }>();

  /**
   *
   * @type {string}
   */
  @Input() title!: string;

  /**
   *
   * @type {boolean}
   */
  @Input() error = false;

  /**
   *
   * @type {boolean}
   */
  @Input() emphasize = false;

  /**
   *
   * @type {boolean}
   */
  @Input() fund = false;

  fixLabel(label: string) {
    return capitalizeFirstLetter(removeUnderLine(label));
  }
}
