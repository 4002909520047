import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/common/login/login.component';
import { ForgotPasswordComponent } from './pages/common/login/components/forgot-password/forgot-password.component';
import { EmailSendComponent } from './pages/common/login/components/email-send/email-send.component';
import { ResetPasswordComponent } from './pages/common/login/components/reset-password/reset-password.component';
import { PagesComponent } from './pages/pages.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'email-send',
    component: EmailSendComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    component: PagesComponent,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
