<div class="login-container">
  <div class="login-header-desktop">
    <div class="logo">
      <app-logo class="clickable" (click)="navigateToLogin()"></app-logo>
      <div *ngIf="!isDesktop" class="title typography-heading3-thin">
        {{ 'header.title' | translate }}
      </div>
    </div>
    <app-button class="signup-button" (buttonClick)="signUp()">
      {{ 'login.signup' | translate }}
    </app-button>
  </div>
  <div class="login-inner-container">
    <div class="login-fields-container">
      <ng-content></ng-content>
    </div>
    <div class="desktop-image">
      <img class="login" src="./assets/svg/login-desktop.svg" />
    </div>
  </div>
</div>
