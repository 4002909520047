<mat-sidenav-container (backdropClick)="closeSupport()">
  <mat-sidenav
    #sidenav
    (keydown.escape)="closeSupport()"
    [position]="'end'"
    disableClose
  >
    <div class="sidenav-header color-white">
      <span class="typography-heading3-bold">
        {{ 'support.title' | translate }}
      </span>
      <div class="sidenav-icons">
        <app-button color="naked" (buttonClick)="closeSupport()">
          <app-icon name="back"></app-icon>
        </app-button>
      </div>
    </div>
    <div>
      <ng-content></ng-content>
    </div> </mat-sidenav
></mat-sidenav-container>
