import { Inject, Injectable } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { BehaviorSubject } from 'rxjs';
import { columns } from '../constants/columns';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { Filter } from '../../statistics/types/filters';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOtherLow,
  convertDateToEpoch,
  formatDateTime,
  paginationDropDown,
  removeUnderLine,
} from 'src/app/shared/helper/helper';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/types/item';
import { snackbarObjectFailedPayment } from '../snackbar/snackbar';
import { paymentRowType } from '../constants/row';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly apiUrl!: string;
  public filters = new BehaviorSubject<Filter>({});
  public filters$ = this.filters.asObservable();
  public paymentMethodList!: Item[];
  limitPerPage = 50;
  public limitOptions = [50];
  currentPage = 1;
  rowsLen = 0;
  private tempRows: paymentRowType[] = [];

  private rows = new BehaviorSubject<paymentRowType[]>([
    {
      request_date: '',
      payment_type: '',
      amount: '',
      status: '',
      payment_term: '',
      payment_period: '',
    },
  ]);
  public rows$ = this.rows.asObservable();

  private numOfPages = new BehaviorSubject<number>(1);
  public numOfPages$ = this.numOfPages.asObservable();

  private chosenLimit = new BehaviorSubject<number>(this.limitOptions[0]);
  public chosenLimit$ = this.chosenLimit.asObservable();

  private columns = new BehaviorSubject<TableColumn[]>(columns);
  public columns$ = this.columns.asObservable();

  constructor(
    private loaderService: LoaderService,
    private loginService: LoginService,
    private generalService: GeneralService,
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private translateService: TranslateService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  updateFilters(filters: Partial<Filter>) {
    this.filters.next({ ...this.filters.value, ...filters });
  }

  updateColumnsData(
    initTemplates: TableColumn[],
    genericTemplate: TableColumn
  ) {
    const updatedArray = this.columns.value.map((obj: any) => {
      const name = this.translateService.instant(obj.name || '');
      const updatedObj = initTemplates.find(
        (updated: any) => updated.prop === obj.prop
      );
      if (updatedObj) {
        return { ...obj, ...updatedObj, name: name };
      }
      return { ...obj, ...genericTemplate, name: name };
    });

    this.columns.next(updatedArray);
  }

  setChosenLimit(limit: number) {
    this.chosenLimit.next(limit);
    this.numOfPages.next(Math.ceil(this.rowsLen / limit));
  }

  customStatus(status: string) {
    return capitalizeFirstLetterOtherLow(removeUnderLine(status));
  }

  getDataTable() {
    if (!this.generalService.paymentEnum) {
      this.generalService.loadPaymentEnum();
    }
    this.loaderService.show();

    const headers = this.loginService.TokenFromStorage;

    const url =
      this.apiUrl +
      this.appConfig.actions.payment +
      `?from_date=${convertDateToEpoch(
        this.filters.value.from_date || ''
      )}&to_date=${convertDateToEpoch(this.filters.value.to_date || '')}`;
    return this.http.get(url, { headers }).pipe(
      map(
        (res: any) => {
          if (res !== null) {
            this.numOfPages.next(
              Math.ceil(res.message?.length / this.limitPerPage)
            );
            this.rowsLen = res.message.length;
            this.limitOptions = paginationDropDown(
              this.numOfPages.value,
              this.limitPerPage
            );
            const data =
              res.message?.map((item: any) => ({
                request_date: formatDateTime(item.request_date, false),
                payment_type: item.payment_type,
                amount: '$' + item.amount,
                status: this.customStatus(item.status),
                payment_term: capitalizeFirstLetter(item.payment_term),
                payment_period: `${formatDateTime(
                  item.payment_from_date,
                  false
                )} - ${formatDateTime(item.payment_to_date, false)}`,
              })) || [];
            this.rows.next(data);

            this.tempRows = [...this.rows.value];
            setTimeout(() => {
              this.loaderService.hide();
            }, 1300);
          }
        },
        (error: HttpErrorResponse) => {
          this.loaderService.hide();
          return this.generalService.catchError(
            snackbarObjectFailedPayment,
            error
          );
        }
      )
    );
  }
}
