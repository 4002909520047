import { createAction, props } from '@ngrx/store';
import { LoginRequestModel } from '../../models/login-request.model';
import { UserModel } from '../models/user.model';

export enum LoginActionType {
  login = '[Login] Login',
  loginSuccess = '[User/API] Load UserData Success',
  loginFailed = '[User/API] Load UserData Falied',
  LoadUser = '[User] Load User',
  updateUser = '[Update] Update',
  updateUserSuccess = '[User/API] Update UserData Success',
  updateUserFailed = '[User/API] Update UserData Falied',
}

export const Login = createAction(
  LoginActionType.login,
  props<{ credentials: LoginRequestModel }>()
);

export const LoginSuccess = createAction(
  LoginActionType.loginSuccess,
  props<{ user: UserModel }>()
);

export const LoginFailure = createAction(
  LoginActionType.loginFailed,
  props<{ error: string }>()
);

export const updateUser = createAction(
  LoginActionType.updateUser,
  props<{ user: UserModel }>()
);

export const UpdateUserSuccess = createAction(
  LoginActionType.updateUserSuccess,
  props<{ user: UserModel }>()
);

export const UpdateUserFailure = createAction(
  LoginActionType.updateUserFailed,
  props<{ error: string }>()
);
