<app-card class="table-container">
  <div class="title-container typography-heading4-bold">
    <app-icon name="route"></app-icon>
    <span class="title">{{ 'dashboard.title' | translate }}</span>
  </div>
  <div class="table-actions">
    <app-input
      class="input"
      prefix="search"
      [placeholder]="'dashboard.search_placeholder' | translate"
      (inputChanged)="searchElement($event)"
    ></app-input>
    <ng-select
      class="filter-dropdown typography-body3-thin"
      [multiple]="true"
      [items]="categoriesList"
      [searchable]="false"
      bindLabel="value"
      [(ngModel)]="selectedCategories"
      [placeholder]="'dashboard.categories_placeholder' | translate"
      groupBy="selectedAllGroup"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [closeOnSelect]="false"
      (ngModelChange)="onSelectChange()"
    >
      <ng-template
        ng-optgroup-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
          class="all"
        />

        {{ 'statistics.filters.all' | translate }}
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input type="checkbox" [ngModel]="item$.selected" />
        {{ item.value }}
      </ng-template>
    </ng-select>
    <app-button [disabled]="isBtnDisabled" (buttonClick)="submit()">
      {{ 'dashboard.get_btn' | translate }}</app-button
    >
  </div>
  <div
    class="bulk-selection"
    *ngIf="(dashboardService.selectedCampaigns$ | async)?.length && isDesktop"
  >
    <span class="typography-heading1 font-size">{{
      (dashboardService.selectedCampaigns$ | async)?.length
    }}</span>
    <app-divider class="divider" direction="vertical"></app-divider>
    <span class="typography-footnote1">{{
      'dashboard.item_selected' | translate
    }}</span>
    <div (click)="editMultipleContentLocker()" class="icon">
      <app-icon size="md" name="edit"></app-icon>
    </div>
    <div (click)="deleteMultipleContentLocker()" class="icon">
      <app-icon size="md" name="remove"></app-icon>
    </div>
    <app-divider class="divider" direction="vertical"></app-divider>
    <div (click)="closeBulkSelection()" class="icon">
      <app-icon name="close"></app-icon>
    </div>
  </div>
  <ngx-datatable
    #table
    *ngIf="isRowsExist && isDesktop"
    class="table typography-body4-thin"
    [rows]="(dashboardService.rows$ | async) || []"
    [columns]="(dashboardService.columns$ | async) || []"
    [headerHeight]="50"
    [footerHeight]="100"
    rowHeight="auto"
    columnMode="flex"
    [swapColumns]="false"
    [selectAllRowsOnPage]="false"
    [selectionType]="SelectionType.checkbox"
    (select)="selectCampaigns($event)"
    [offset]="dashboardService.currentPage - 1"
    [summaryRow]="false"
    [limit]="limit"
    [selected]="(dashboardService.selectedCampaigns$ | async) || []"
  >
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div class="footer-container">
          <div class="pager-container">
            <app-button
              [ngClass]="{ 'icon-disabled-style': curPage <= 1 }"
              color="naked"
              (buttonClick)="pager.prevPage()"
            >
              <app-icon start name="navigation_left"></app-icon>
            </app-button>
            <datatable-pager
              #pager
              [page]="dashboardService.currentPage"
              [size]="pageSize"
              [count]="rowCount"
              (change)="changePage($event) && table.onFooterPage($event)"
            ></datatable-pager>
            <app-button
              [ngClass]="{
                'icon-disabled-style': curPage === numOfPages
              }"
              color="naked"
              (buttonClick)="pager.nextPage()"
            >
              <app-icon start name="navigation_right"></app-icon>
            </app-button>
          </div>
          <ng-select
            class="pagination"
            [ngModel]="limit"
            (ngModelChange)="dashboardService.setChosenLimit($event)"
            [items]="dashboardService.limitOptions"
            appendTo="body"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</app-card>

<ng-template
  #name_header
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <app-checkbox
    *ngIf="isDesktop"
    [checked]="allRowsSelected"
    (checkboxChanged)="selectFn(!allRowsSelected)"
    [label]="'dashboard.columns.name' | translate"
  ></app-checkbox>
  <div *ngIf="!isDesktop">
    {{ 'dashboard.columns.name' | translate }}
  </div>
</ng-template>

<ng-template
  #name
  let-row="row"
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <div class="cell">
    <app-checkbox
      *ngIf="isDesktop"
      [checked]="isSelected"
      (checkboxChanged)="onCheckboxChangeFn($event)"
    ></app-checkbox>
    <div [tooltip]="row.name" class="detail">
      {{ row.name }}
    </div>
  </div>
</ng-template>

<ng-template
  #title
  let-row="row"
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <div class="cell">
    <div [tooltip]="row.title" class="title">
      {{ row.title }}
    </div>
  </div>
</ng-template>

<ng-template
  #short_link
  let-row="row"
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <div class="cell">
    <div [tooltip]="row.short_link" class="short">
      {{ row.short_link }}
    </div>
  </div>
</ng-template>

<ng-template
  #category
  let-row="row"
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <div class="cell">
    <div [tooltip]="row.category" class="category">
      {{ row.category }}
    </div>
  </div>
</ng-template>

<ng-template #destination_url let-row="row" let-value="value">
  <div class="cell">
    <div [tooltip]="row.url" class="url">
      {{ row.url }}
    </div>
  </div>
</ng-template>

<ng-template
  *ngIf="isDesktop"
  #actions
  let-value="value"
  let-row="row"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <div class="cell">
    <div
      (click)="editSingleContentLocker(row)"
      class="action-button"
      tooltip="{{'dashboard.tooltip.edit'| translate}}"
      [class.disabled]="row.actions === false"
      [attr.disabled]="row.actions === false ? true : null"
    >
      <app-icon size="lg" name="edit"></app-icon>
    </div>
    <div
      (click)="deleteSingleContentLocker(row)"
      class="action-button"
      tooltip="{{'dashboard.tooltip.delete'| translate}}"
      [class.disabled]="row.actions === false"
      [attr.disabled]="row.actions === false ? true : null"
    >
      <app-icon size="lg" name="remove"></app-icon>
    </div>
    <div
      (click)="navigateToStatistics(row)"
      class="action-button"
      tooltip="{{'dashboard.tooltip.analytics'| translate}}"
      [class.disabled]="row.actions === false"
      [attr.disabled]="row.actions === false ? true : null"
    >
      <app-icon size="lg" name="analytics"></app-icon>
    </div>
    <div
      (click)="copyCodePopup(row)"
      class="action-button"
      tooltip="{{'dashboard.tooltip.copy'| translate}}"
      [class.disabled]="row.actions === false"
      [attr.disabled]="row.actions === false ? true : null"
    >
      <img size="lg" src="/assets/svg/copy_code.svg" />
    </div>
  </div>



</ng-template>

<div *ngIf="isRowsExist && !isDesktop">
  <div class="cards" *ngFor="let row of rows">
    <div class="card">
      <div *ngFor="let column of mobileColumns">
        <ng-container *ngIf="column.prop !== undefined">
          <div class="content">
            <div class="typography-caption description">
              {{ column.name }}
            </div>
            <div class="typography-body4-thin text">
              {{ row[column.prop] }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isRowsExist">
  <app-empty-state></app-empty-state>
</div>
