import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarFailedObject: Snackbar = {
  duration: 2000,
  message: 'my_profit.my_profit_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarDataTableFailed: Snackbar = {
  duration: 2000,
  message: 'dashboard.data_table_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
