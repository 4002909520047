import { LootLabsData } from '../enums/enum.model';

export const LOOTLABS_DATA = 'LootLabsData';

export interface GeneralState {
  lootLabsData: LootLabsData | null;
}

export const initialGeneralState: GeneralState = {
  lootLabsData: null,
};
