export enum RedirectTypeEnum {
  Content_locker = 'Content locker',
  Personal_details = 'Personal details',
  Payment = 'Payment',
  Affiliate = 'Affiliate',
  bulk_link = 'Bulk link',
}

export enum RedirectRouteEnum {
  'new_content_locker_link' = '/dashboard',
  'new_content_js' = '/full-script-api',
  'personal_details_updated' = '/profile',
  'affiliate_link_created' = '/profile',
  'payment_details' = '/profile',
  'bulk_link_uploaded' = '/dashboard',
}
