import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG } from 'src/app';
import { SideNavSectionsEnum } from '../enums/side_nav_section';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { Item } from 'src/app/shared/types/item';
import { PaymentDetailsModel } from '../models/payment_details';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import {
  failsSendEmail,
  snackbarObjectPaymentFailed,
  successSendEmail,
} from '../components/payment-method/snckbar/snackbar';
import {
  snackbarFailedObject,
  snackbarSucceedObject,
} from '../components/password/snackbar/sbackbar';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { snackbarObjectFailedGenerate } from '../components/api-key/snackbar/snackbar';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import {
  snackbarCreatorSucceedObject,
  snackbarCreatorFailedObject,
} from '../components/creator-details/snackbar/snackbar';
import { catchError, tap } from 'rxjs/operators';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly apiUrl: string;
  private tabSubject = new BehaviorSubject<SideNavSectionsEnum | null>(
    SideNavSectionsEnum.PERSONAL_SETTINGS
  );
  tab$ = this.tabSubject.asObservable();

  private paymentMethod = new BehaviorSubject<Item | null>(null);
  paymentMethod$ = this.tabSubject.asObservable();

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private http: HttpClient,
    private generalService: GeneralService,
    private snackbarService: SnackbarService,
    private loaderService: LoaderService,
    private notificationService: NotificationsService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  setTab(tab: SideNavSectionsEnum) {
    this.tabSubject.next(tab);
    localStorage.setItem('activeTab', tab);
  }

  getTab() {
    return localStorage.getItem('activeTab');
  }

  getPaymentFromService() {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.getPaymentMethod;
    return this.http.get<string>(url, { headers }).pipe(
      tap(
        (res: any) => {
          this.paymentMethod.next(res.message[0]);
          return res.message[0];
        },
        (error: HttpErrorResponse) => {
          return this.generalService.catchError(
            snackbarObjectPaymentFailed,
            error
          );
        }
      )
    );
  }

  paymentMethodMail(paymentDetails: Partial<PaymentDetailsModel>) {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.paymentMethodEmail;
    return this.http
      .post<PaymentDetailsModel>(url, paymentDetails, { headers })
      .pipe(
        tap((res: any) => {
          if (res != null) {
            this.snackbarService.showSnackbar(successSendEmail);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return this.generalService.catchError(failsSendEmail, error);
        })
      );
  }

  resetPassword(newPassword: string, oldPassword: string) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.user;
    return this.http
      .put<any>(
        url,
        {
          password: newPassword,
          old_password: oldPassword,
        },
        { headers }
      )
      .pipe(
        tap((res: any) => {
          if (res != null) {
            this.snackbarService.showSnackbar(snackbarSucceedObject);
          }
        }),
        catchError((error: any) => {
          return this.generalService.catchError(snackbarFailedObject, error);
        })
      );
  }

  generateCode() {
    const headers = this.loginService.TokenFromStorage;
    const url = `${this.apiUrl}${this.appConfig.actions.api_token}`;

    return this.http.post(url, {}, { headers }).pipe(
      tap(
        (res: any) => {
          this.generalService.api_user_token = res.message.api_token;
          const notification = {
            notificationType: NotificationType.AffiliateLink,
          };
          const newNotification =
            this.notificationService.createNotificationObject(notification);

          this.notificationService
            .createNotification(newNotification)
            .subscribe(() => {
              this.notificationService.getNotifications();
            });
        },
        (error: HttpErrorResponse) => {
          this.loaderService.hide();
          return this.generalService.catchError(
            snackbarObjectFailedGenerate,
            error
          );
        }
      )
    );
  }

  updateCreatorDetails(body: CreatorModel) {
    const url = this.apiUrl + this.appConfig.actions.creator;
    const headers = this.loginService.TokenFromStorage;
    return this.http.put<any>(url, body, { headers }).pipe(
      tap((res: any) => {
        if (res != null) {
          localStorage.setItem('hasDetails', 'true');
          this.generalService.hasPicName.next(true);
          this.snackbarService.showSnackbar(snackbarCreatorSucceedObject);
          this.generalService.creator.next(body);
        }
      }),
      catchError((error: any) => {
        return this.generalService.catchError(
          snackbarCreatorFailedObject,
          error
        );
      })
    );
  }
}
