import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';

import { EmptyStateModule } from '../empty-state/empty-state.module';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { NotificationsSlideComponent } from './notifications-slide/notifications-slide.component';
import { AppDividerModule } from '../divider copy/divider.module';
import { ButtonModule } from '../button/button.module';
import { SidePanelLoaderModule } from '../side-panel-loader/side-panel-loader.module';
import { PipesModule } from '../../pipe/pipes.module';

@NgModule({
  declarations: [NotificationCardComponent, NotificationsSlideComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    TranslateModule,
    IconModule,
    EmptyStateModule,
    AppDividerModule,
    ButtonModule,
    SidePanelLoaderModule,
    PipesModule,
  ],
  exports: [NotificationsSlideComponent, NotificationCardComponent],
})
export class NotificationsModule {}
