import { ToggleOption } from 'src/app/shared/components/toggle-button/type/toggle-option';
import { GroupByEnum } from '../enums/groupby-enum';

export const groupBy: ToggleOption[] = [
  {
    id: GroupByEnum.REPORT_DATE,
    label: 'statistics.group_by.date',
  },
  {
    id: GroupByEnum.OPERATION,
    label: 'statistics.group_by.ad_formats',
  },
  {
    id: GroupByEnum.COUNTRY,
    label: 'statistics.group_by.country',
  },
  {
    id: GroupByEnum.PLACEMENT,
    label: 'statistics.group_by.placement',
  },
  {
    id: GroupByEnum.LINK,
    label: 'statistics.group_by.link',
  },
];
