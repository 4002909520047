<div [class.backdrop]="isBackdrop">
  <app-header
    (onNotificationsClick)="openNotifications()"
    (onSupportClick)="openSupport()"
  ></app-header>

  <div class="app-container">
    <app-creating-cards *ngIf="showCards && isDesktop"></app-creating-cards>
    <router-outlet></router-outlet>
    <app-notifications-slide>
      <ng-container *ngTemplateOutlet="notificationsTemplate"></ng-container>
    </app-notifications-slide>
    <app-support>
      <ng-container *ngTemplateOutlet="supportContent"></ng-container
    ></app-support>
  </div>
</div>

<ng-template #notificationsTemplate>
  <app-side-panel-loader
    *ngIf="isLoading && !isDesktop"
  ></app-side-panel-loader>

  <ng-container
    *ngIf="
      ((!isLoading && !isDesktop) || isDesktop) &&
        notificationsDataArray &&
        notificationsDataArray.length > 0;
      else emptyState
    "
  >
    <app-notification-card
      *ngFor="let notificationData of notificationsDataArray"
      [notificationData]="notificationData"
    ></app-notification-card>
  </ng-container>
</ng-template>
<ng-template #emptyState>
  <app-empty-state></app-empty-state>
</ng-template>

<ng-template #headerTemplate>
  <div class="header">
    <div class="title typography-heading3-bold">
      {{ 'notifications.title' | translate }}
    </div>
    <app-button color="naked" (buttonClick)="markAll()">
      <app-icon [name]="'mark_all'"></app-icon>
    </app-button>

    <app-divider class="divider" direction="vertical"></app-divider>

    <app-icon
      backgroundColor="naked"
      class="close-icon"
      name="close"
      (click)="closeBottomSheet()"
    ></app-icon>
  </div>
</ng-template>

<ng-template #supportContent>
  <div class="content">
    <div class="account-section">
      <div class="account-manager color-white">
        <img src="{{ accountManager.pic }}" alt="account pic" />
        <div class="details">
          <div class="typography-heading4-bold">{{ accountManagerName }}</div>
          <div class="typography-headline2">
            {{ 'support.account_manager' | translate }}
          </div>
        </div>
      </div>
      <app-divider class="divider-drawer" direction="horizontal"></app-divider>
      <div class="contact color-white">
        <div class="typography-heading4-bold">
          {{ 'support.contact' | translate }}
        </div>
        <div class="contact-details">
          <div class="line">
            <app-icon [name]="'mail'"> </app-icon>
            <a
              [href]="mailLink + accountManager.mail"
              target="_blank"
              class="typography-body2-bold color-primary link"
              >{{ accountManager.mail }}</a
            >
          </div>
          <div class="line" *ngIf="accountManager.skype">
            <app-icon [name]="'skype'"> </app-icon>

            <a
              class="typography-body2-bold color-primary link"
              target="_blank"
              (click)="openSkype()"
              >{{ accountManager.skype }}
            </a>
          </div>
          <div class="line" *ngIf="accountManager.discord">
            <app-icon [name]="'discord'"> </app-icon>
            <a
              class="typography-body2-bold color-primary link"
              target="_blank"
              [href]="discordLink + accountManager.discord"
              >{{ accountManager.discord }}</a
            >
          </div>
          <div class="line" *ngIf="accountManager.telegram">
            <app-icon [name]="'telegram'"> </app-icon>
            <a
              target="_blank"
              href="{{ telegramLink }}{{
                accountManager.telegram.substring(1)
              }}?text=example"
              class="typography-body2-bold color-primary link"
            >
              {{ accountManager.telegram }}</a
            >
          </div>
          <div class="line">
            <app-icon [name]="'question_mark'"> </app-icon>
            <a
              class="typography-body2-bold color-primary link"
              target="_blank"
              [href]="faqLink"
              >{{ 'support.faq' | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #headerSupport>
  <div class="header">
    <div class="title-support typography-heading3-bold">
      {{ 'support.title' | translate }}
    </div>

    <app-icon
      backgroundColor="naked"
      class="close-icon"
      name="close"
      (click)="closeBottomSheet()"
    ></app-icon>
  </div>
</ng-template>
