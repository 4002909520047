<div [class.padding-profit]="isDesktop">
  <div class="my-profit-container">
    <div class="titles-container">
      <span class="title typography-heading2">{{
        'my_profit.title' | translate
      }}</span>
      <span class="time typography-body4-thin"
        >{{ 'my_profit.last_update' | translate }} {{ updatedOn }}</span
      >
    </div>
    <swiper
      *ngIf="!isDesktop; else Desktop"
      [slidesPerView]="1.15"
      [navigation]="true"
      [pagination]="{ clickable: true }"
    >
      <ng-template swiperSlide *ngFor="let item of myProfitData">
        <div class="card">
          <span class="title typography-body4-thin">
            {{ item.title | translate }}
          </span>
          <span class="profit typography-heading3-bold">
            {{ item.profit }}
          </span>
        </div>
      </ng-template>
    </swiper>
    <ng-template #Desktop>
      <div class="cards-container">
        <ng-container *ngFor="let item of myProfitData">
          <div class="card" tooltip="{{item.tooltip | translate}}">
            <span class="title typography-body4-thin">{{
              item.title | translate
            }}</span>
            <span class="profit typography-heading3-bold">{{
              item.profit
            }}</span>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
