import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { checkCategoryValidation } from 'src/app/shared/helper/helper';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { Item } from 'src/app/shared/types/item';
import {DashboardService} from "../../../../dashboard/service/dashboard.service";
@Component({
  selector: 'app-create-link',
  templateUrl: './create-link.component.html',
  styleUrls: ['./create-link.component.scss'],
})
export class CreateLinkComponent implements OnInit {
  @Input() creatingLinkControls!: AbstractControl;
  @Input() isEditMode!: boolean;
  public createLinkForm!: FormGroup;
  @Output() closeFirstPanel = new EventEmitter<any>();
  @ViewChild('contentTemplate', { static: true })
  contentTemplate!: TemplateRef<any>;
  linkSelected!: string;
  categories!: Item[];

  constructor(
    public generalService: GeneralService,
    public dashboardService: DashboardService,

) {}

  ngOnInit(): void {
    this.dashboardService.getCategory().subscribe((val) => {
      const firstCategory: Item = {
        key: 'Other',
        value: 'Other'
      };
      val.message.unshift(firstCategory);
      const lastCategory: Item = {
        key: 'Create Folder',
        value: 'Create Folder'
      };
      val.message.push(lastCategory);
      this.categories = val.message.filter((category: Item) => category.value !== '');
    });
    this.createLinkForm = this.creatingLinkControls as FormGroup;
    this.createLinkForm.get('category')?.valueChanges.subscribe((value) => {
      this.checkCategoryValidation(value);
    });
  }

  checkCategoryValidation(value: string) {
    checkCategoryValidation(value, this.createLinkForm);
  }

  closeFirstAccordion() {
    this.closeFirstPanel.emit();
  }

  submitValidation() {
    if (
      this.createLinkForm.get('other_category')?.invalid ||
      this.createLinkForm.get('category')?.invalid ||
      this.createLinkForm.get('short_name')?.invalid ||
      this.createLinkForm.get('url')?.invalid
    ) {
      return true;
    } else return false;
  }
}
