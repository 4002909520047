import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { FullScriptStep } from './constants/steps';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Regex } from 'src/app/shared/validators/regex';
import { ListTypeEnum } from './enums/type-list.enum';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { AddFullScriptService } from './service/add-full-script.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-full-script',
  templateUrl: './add-full-script.component.html',
  styleUrls: ['./add-full-script.component.scss'],
})
export class AddFullScriptComponent implements OnInit {
  isDesktop = false;
  progressBars: number[] = [10];
  steps = FullScriptStep;
  fullScriptForm!: FormGroup;
  setupLinkForm!: FormGroup;
  typeListForm!: FormGroup;
  isStep1Complited = false;
  isStep2Complited = false;
  isCopyMode = false;
  isEditMode = false;
  //TO DO: add type when i can do edit from the table
  fullPageScriptData!: any;

  constructor(
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    public generalService: GeneralService,
    private addFullScriptService: AddFullScriptService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.steps[0].isOpen = true;
    this.steps[1].isOpen = false;
    this.steps[2].isOpen = false;
    this.steps[0].isValid = false;
    this.steps[1].isValid = false;
    this.steps[2].isValid = false;
    this.isEditMode = false;
  }

  ngOnInit(): void {
    if (this.ref.data) {
      this.fullPageScriptData = this.ref.data;
      if (this.fullPageScriptData.editMode) {
        this.isEditMode = true;
        this.addFullScriptService.siteId = this.ref.data.site_id;
        this.addFullScriptService.tagId = this.ref.data.id;
        this.addFullScriptService.getPlacementCode().subscribe();
      }
      if (this.fullPageScriptData.copyMode) {
        this.isCopyMode = true;
        this.addFullScriptService.tagId = this.ref.data.id;
        this.addFullScriptService.getPlacementCode().subscribe();
      }
    }

    this.initForm();
    this.setupLinkForm = this.fullScriptForm.get('setupLink') as FormGroup;
    this.typeListForm = this.fullScriptForm.get('list') as FormGroup;

    this.setupLinkForm.valueChanges.subscribe(() => {
      if (this.isStep1Complited) {
        if (this.setupLinkForm.invalid) {
          this.steps[0].isValid = false;
          this.updateProgressBar(this.progressBars[0], 10);
        }
      }
    });

    this.typeListForm.valueChanges.subscribe(() => {
      if (this.isStep2Complited) {
        this.isStep2Complited = false;
        if (this.typeListForm.invalid) {
          this.steps[1].isValid = false;
          this.updateProgressBar(this.progressBars[0], 33.3);
        }
      }
    });
  }

  initForm() {
    this.fullScriptForm = new FormGroup({
      setupLink: new FormGroup({
        url: new FormControl(this.fullPageScriptData.site_url || null, [
          Validators.required,
          Validators.pattern(Regex.ValidateUrl),
        ]),
        name: new FormControl(this.fullPageScriptData.name || null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(Regex.englishCharactersRegex),
        ]),
      }),
      list: new FormGroup({
        typeList: new FormControl(
          this.fullPageScriptData.editMode
            ? this.fullPageScriptData.typeList
            : ListTypeEnum.BLACK_LIST
        ),
        url: new FormArray([]),
      }),
    });
  }

  close() {
    this.ref.close();
  }

  panelOpened(index: number) {
    this.steps[index].isOpen = true;
  }

  panelClosed(index: number) {
    this.steps[index].isOpen = false;
  }

  updateProgressBar(progressBar: number, value: number) {
    this.progressBars[0] = value;
  }

  firstStepCompleted() {
    this.isStep1Complited = true;
    this.updateProgressBar(this.progressBars[0], 33.3);
    this.panelClosed(0);
    this.panelOpened(1);
    this.steps[0].isValid = true;
  }

  secondStepCompleted() {
    this.isStep2Complited = true;
    this.steps[1].isValid = true;
    this.panelClosed(1);
    this.panelOpened(2);
    this.updateProgressBar(this.progressBars[0], 100);
    this.isEditMode = true;
  }

  submitStep() {
    this.close();
  }
}
