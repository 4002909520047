import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollDisabled = false;

  disableScroll(): void {
    this.scrollDisabled = true;
    document.body.style.overflow = 'hidden';
  }

  enableScroll(): void {
    this.scrollDisabled = false;
    document.body.style.overflow = 'auto';
  }

  isScrollDisabled(): boolean {
    return this.scrollDisabled;
  }
}
