import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRadioButtonComponent } from '../radio-button/radio-button.component';
import {
  BaseControlValueAccessor,
  getValueAccessor,
} from '../../forms/reactive-form/base-control-value-accessor';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [getValueAccessor(AppRadioGroupComponent)],
})
export class AppRadioGroupComponent
  extends BaseControlValueAccessor<string>
  implements OnDestroy, AfterContentInit
{
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * List of content radio buttons.
   *
   * @type { QueryList<AppRadioButtonComponent>}
   */
  @ContentChildren(AppRadioButtonComponent)
  radioButtons!: QueryList<AppRadioButtonComponent>;

  /**
   * Group name associates radio buttons to a group for being able to prevent more then one button being checked.
   *
   * @type {string}
   */
  @Input() groupName = '';

  /**
   * Emitter for radio button change
   *
   * @type {EventEmitter<string>}
   */
  @Output() radioButtonSelected: EventEmitter<string> =
    new EventEmitter<string>();

  /**
   * Radio button's value to be checked using only on initialize
   *
   * @type {string | undefined}
   */
  private initCheckedRadioButtonValue: string | undefined = undefined;

  constructor() {
    super();
  }

  ngAfterContentInit() {
    for (const radioButton of this.radioButtons) {
      radioButton.groupName = this.groupName;
      radioButton.radioButtonSelected
        .pipe(takeUntil(this.destroy$))
        .subscribe((radioValue) => {
          this.radioButtonSelectedHandler(radioValue);
          this.setRadioButtonChecked(radioValue);
        });
    }

    this.setRadioButtonChecked(this.initCheckedRadioButtonValue);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(radioValue: string | undefined) {
    if (this.radioButtons) {
      this.setRadioButtonChecked(radioValue);
    } else {
      //on initialize writeValue execute before this.radioButtons initialized
      this.initCheckedRadioButtonValue = radioValue;
    }
  }

  setRadioButtonChecked(radioValue: string | undefined) {
    if (radioValue !== undefined) {
      for (const radioButton of this.radioButtons) {
        if (radioValue && radioButton.value === radioValue) {
          radioButton.checked = true;
        } else if (radioButton.checked) {
          radioButton.checked = false;
        }
      }
    }
  }

  radioButtonSelectedHandler(radioValue: string) {
    this.radioButtonSelected.emit(radioValue);
    this.onChange(radioValue);
  }
}
