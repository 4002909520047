import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BaseControlValueAccessor,
  getValueAccessor,
} from '../forms/reactive-form/base-control-value-accessor';
import { ToggleOption } from './type/toggle-option';

@Component({
  selector: 'app-group-by-button',
  templateUrl: './group-by-button.component.html',
  styleUrls: ['./group-by-button.component.scss'],
  providers: [getValueAccessor(groupByButtonComponent)],
})
export class groupByButtonComponent extends BaseControlValueAccessor<string> {
  private _selectedOptionId: number | string | undefined = undefined;

  /**
   * Current selected option
   *
   * @type {number | string | undefined}
   */
  get selectedOptionId(): number | string | undefined {
    return this._selectedOptionId;
  }
  private set selectedOptionId(value: number | string | undefined) {
    this._selectedOptionId = value;
  }

  /**
   * Options array
   *
   * @type {ToggleOption[]}
   */
  @Input() options: ToggleOption[] = [];

  /**
   * Make the buttons disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Set the buttons size
   *
   * @type {"sm" | "md" | "lg"}
   */
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  /**
   * Emitter for toggle selection change
   * @type {EventEmitter<number | string>}
   */
  @Output() toggleChange: EventEmitter<number | string> = new EventEmitter<
    number | string
  >();

  /**
   * Emit toggle change event
   *
   */
  onToggleChange(optionId: number | string): void {
    this.selectedOptionId = optionId;
    this.toggleChange.emit(optionId);
  }
  /**
   * Set the selected option
   *
   */
  setCurrentOption(value: number | string): void {
    this.selectedOptionId = value;
  }

  override writeValue(value: string): void {
    this.onToggleChange(value);
  }
}
