<input
  type="file"
  class="file-input"
  (change)="upload($event)"
  accept=".csv"
  #fileUpload
/>
<div
  class="file-upload"
  [class.error]="invalid"
  [ngClass]="{ 'not-upload': !isUpload, upload: isUpload }"
>
  <div *ngIf="!isUpload; else uploadCompleted">
    <app-button
      [color]="'transparent'"
      class="upload-btn"
      (buttonClick)="fileUpload.click()"
    >
      <ng-container>
        <span class="width"> {{ 'upload_file.upload_csv' | translate }}</span>
        <app-icon end name="upload"> </app-icon>
      </ng-container>
    </app-button>
  </div>

  <ng-template #uploadCompleted>
    <div class="options">
      <app-button (buttonClick)="deleteImage()" color="transparent">
        <div class="width">
          <span>{{ fileName }}</span>
        </div>
        <app-icon name="delete"></app-icon>
      </app-button>
    </div>
  </ng-template>
</div>
