import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [AppRadioGroupComponent],
  imports: [CommonModule],
  exports: [AppRadioGroupComponent],
})
export class AppRadioGroupModule {}
