import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseControlValueAccessor } from '../forms/reactive-form/base-control-value-accessor';

@Component({
  template: '',
})
export abstract class AppInputAbstractComponent extends BaseControlValueAccessor<string> {
  /**
   * Specifies input top label
   *
   * @type {string}
   */
  @Input() label = '';

  /**
   * Provide tooltip to the label
   *
   * @type {string}
   */
  @Input() tooltipText = '';

  /**
   * Specifies input field value
   *
   * @type {string}
   */
  @Input() textValue = '';

  /**
   * Input placeholder
   *
   * @type {string}
   */
  @Input() placeholder = '';

  /**
   * Maximum number of characters allowed in an input field
   *
   * @type {number | undefined}
   */
  @Input() maxLength: number | undefined = undefined;

  /**
   *  Make the input disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   *  Make the input invalid
   *
   * @type {boolean | undefined}
   */
  @Input() invalid: boolean | null | undefined = false;

  /**
   * Specifies that an input field must be filled out before submitting the form
   *
   * @type {boolean}
   */
  @Input() required = false;

  /**
   * Specifies that an input field is read-only
   *
   * @type {boolean}
   */
  @Input() readonly = false;

  /**
   *  Input error message
   *
   * @type {string | null}
   */
  @Input() errorMessage: string | null = null;

  /**
   * Auto select input field on focus
   *
   * @type {boolean}
   */
  @Input() selectOnFocus = false;

  /**
   * Auto focus on input
   *
   * @type {boolean}
   */
  @Input() autoFocus = false;

  /**
   * Display counter for characters
   *
   * @type {boolean}
   */
  @Input() textCounter = false;

  /**
   * Emitter for input changed
   *
   * @type {EventEmitter<string>}
   */
  @Output() inputChanged = new EventEmitter<string>();

  /**
   * Emitter for input blur
   *
   * @type {EventEmitter<string>}
   */
  @Output() inputBlur = new EventEmitter<FocusEvent>();

  /**
   * Emitter for input focus
   *
   * @type {EventEmitter<string>}
   */
  @Output() inputFocus = new EventEmitter<FocusEvent>();

  /**
   * Emitter for input focus out
   *
   * @type {EventEmitter<string>}
   */
  @Output() inputFocusOut = new EventEmitter<FocusEvent>();

  writeValue(value: string): void {
    this.textValue = value;
  }

  override setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  clickedHandler(inputElement: HTMLTextAreaElement | HTMLInputElement): void {
    if (this.selectOnFocus) {
      inputElement.select();
    }
  }

  valueChangeHandler(): void {
    this.emitChanges();
  }

  emitChanges(): void {
    this.inputChanged.emit(this.textValue);
    this.onChange(this.textValue);
  }

  getMaxLengthString(): string {
    return this.maxLength ? this.maxLength.toString() : '';
  }

  getDiff(text: string | null, maxLength?: number): number | undefined {
    if (text === null) {
      return maxLength;
    } else if (maxLength) {
      const diff = maxLength - text.length;
      return diff;
    } else {
      return maxLength;
    }
  }

  onBlur($event: FocusEvent) {
    this.inputBlur.emit($event);
  }

  onFocus($event: FocusEvent) {
    this.inputFocus.emit($event);
  }

  onFocusOut($event: FocusEvent) {
    this.inputFocusOut.emit($event);
  }
}
