import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover.component';
import { PopoverTriggerDirective } from './popover.directive';

@NgModule({
  declarations: [PopoverComponent, PopoverTriggerDirective],
  imports: [CommonModule],
  exports: [PopoverComponent, PopoverTriggerDirective],
})
export class PopoverModule {}
