import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { linkOptions } from '../add-single/constants/link-option';
import { MultipleStep } from './constants/steps';
import { AddMultipleService } from './service/add-multiple.service';
import { checkCategoryValidation } from 'src/app/shared/helper/helper';
import { youtubeValidator } from 'src/app/shared/validators/validatiors';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { DownloadCsvComponent } from 'src/app/shared/components/download-csv/download-csv.component';
import { DashboardService } from '../../dashboard/service/dashboard.service';

@Component({
  selector: 'app-add-multiple',
  templateUrl: './add-multiple.component.html',
  styleUrls: ['./add-multiple.component.scss'],
})
export class AddMultipleComponent implements OnInit {
  isDesktop = false;
  progressBars: number[] = [10];
  multipleLinkForm!: FormGroup;
  steps = MultipleStep;
  isStep1Next = false;
  formData!: FormData;

  constructor(
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    public generalService: GeneralService,
    private AddMultipleService: AddMultipleService,
    private overlay: OverlayService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.generalService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    this.multipleLinkForm?.get('first')?.valueChanges.subscribe(() => {
      if (this.multipleLinkForm?.get('first')?.invalid) {
        this.updateProgressBar(this.progressBars[0], 33);
        this.steps[0].isValid = false;
      }
    });

    checkCategoryValidation('Other', this.firstFormGroup);
    this.firstFormGroup.valueChanges.subscribe(() => {
      this.steps[0].isValid = this.firstFormGroup.valid && this.isStep1Next;
    });
  }

  ngOnDestroy(): void {
    this.steps[0].isOpen = true;
    this.steps[1].isOpen = false;

    this.steps[0].isValid = false;
    this.steps[1].isValid = false;
  }

  initForm() {
    this.multipleLinkForm = new FormGroup({
      first: new FormGroup({
        short_name: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
        ]),
        category: new FormControl('Other', [Validators.required]),
        other_category: new FormControl(null),
      }),
      second: new FormGroup({
        link_option: new FormControl(linkOptions[2].key || null, [
          Validators.required,
        ]),
        background: new FormControl(null),
        theme_id: new FormControl(null, [Validators.required]),
        tier_id: new FormControl(null, [Validators.required]),
        number_of_tasks: new FormControl(1, [Validators.required]),
        csv: new FormControl(null, [Validators.required]),
      }),
    });
    this.applyValidators();
  }

  applyValidators(): void {
    const backgroundControl = this.secondFormGroup.get('background');
    const linkOptionControl = this.secondFormGroup.get('link_option');

    backgroundControl?.setValidators([
      youtubeValidator({
        control: backgroundControl,
        additionalControls: linkOptionControl,
      }),
    ]);
    backgroundControl?.updateValueAndValidity();
  }

  get firstFormGroup() {
    return this.multipleLinkForm.get('first') as FormGroup;
  }

  get secondFormGroup() {
    return this.multipleLinkForm.get('second') as FormGroup;
  }

  panelOpened(index: number) {
    this.steps[index].isOpen = true;
  }

  panelClosed(index: number) {
    this.steps[index].isOpen = false;
  }

  updateProgressBar(progressBar: number, value: number) {
    this.progressBars[0] = value;
  }

  firstStepCompleted() {
    this.isStep1Next = true;
    this.steps[0].isValid = true;
    this.updateProgressBar(this.progressBars[0], 66);
    this.panelClosed(0);
    this.panelOpened(1);
  }

  close() {
    this.ref.close();
  }

  getCsvFile(event: any) {
    this.secondFormGroup.get('csv')?.setValue(event);
    this.formData = event;
  }

  submitStep() {
    if (this.multipleLinkForm.valid) {
      const body: any = {
        short_name: this.firstFormGroup.get('short_name')?.value,
        sub_id:
          this.firstFormGroup.get('category')?.value === 'Create Folder'
            ? this.firstFormGroup.get('other_category')?.value
            : this.firstFormGroup.get('category')?.value,
        background:
          this.secondFormGroup.get('background')?.value &&
          this.secondFormGroup.get('background')?.value != 'none'
            ? this.secondFormGroup.get('background')?.value
            : null,
        theme_id: this.secondFormGroup.get('theme_id')?.value,
        tier_id: this.secondFormGroup.get('tier_id')?.value,
        number_of_tasks: this.secondFormGroup.get('number_of_tasks')?.value,
        file: this.formData.get('file'),
        creator_name: localStorage.getItem('creatorName') || null,
        creator_picture: localStorage.getItem('creatorPic') || null,
      };
      this.AddMultipleService.createMultipleContentLocker(body).subscribe(
        (res) => {
          this.close();
          if (res.message.length > 0) {
            this.overlay.open(DownloadCsvComponent, {
              file: res.message,
              updateDataFn: () =>
                this.dashboardService.updateData().subscribe(),
            });
          }
        }
      );
    }
  }

  submitValidation() {
    if (
      this.firstFormGroup.invalid ||
      this.secondFormGroup.get('theme_id')?.invalid ||
      this.secondFormGroup.get('tier_id')?.invalid ||
      this.secondFormGroup.get('number_of_tasks')?.value == 0 ||
      this.secondFormGroup.get('csv')?.invalid ||
      this.secondFormGroup.get('background')?.invalid
    ) {
      return true;
    } else return false;
  }
}
