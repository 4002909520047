<label *ngIf="label.length" [for]="inputElement" class="typography-text">
  {{ label }}
</label>
<div
  class="{{ customStyle }}"
  [ngClass]="{
    'input-container': true,
    'has-text': textValue && textValue.length > 0,
    'width-content': maxWidthContent,
    disabled: disabled,
    error: invalid
  }"
>
  <app-icon *ngIf="prefix" class="prefix" [name]="prefix"></app-icon>
  <input
    #inputElement
    autocomplete="off"
    [placeholder]="placeholder"
    [mask]="mask"
    [dropSpecialCharacters]="maskSpecialCharacters"
    [type]="type"
    class="input-element typography-footnote1"
    [class]="typographyClass"
    [disabled]="disabled"
    [maxlength]="getMaxLengthString()"
    [required]="required"
    [readonly]="readonly"
    (click)="clickedHandler(inputElement)"
    [(ngModel)]="textValue"
    (ngModelChange)="valueChangeHandler()"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (focusout)="onFocusOut($event)"
    [class.error]="invalid"
    [appNumericOnly]="isNumericInputAllowed"
  />
  <app-icon *ngIf="suffix" class="suffix" [name]="suffix"></app-icon>
</div>

<div class="text-counter typography-footnote1" *ngIf="textCounter">
  <span>{{
    textValue && textValue.length ? textValue.length.toString() : 0
  }}</span
  >/{{ maxLength && maxLength.toString() }}
</div>

<app-error-message
  [errorText]="errorMessage ? errorMessage : ''"
  *ngIf="errorMessage && errorMessage.length"
>
</app-error-message>
