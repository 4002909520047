import { TableColumn } from '@swimlane/ngx-datatable';
import { ColumnsEnum } from '../enums/columns.enum';

const columnConfig: TableColumn = {
  draggable: false,
  resizeable: false,
};

export const columns: TableColumn[] = [
  {
    prop: ColumnsEnum.NAME,
    name: 'dashboard.columns.name',
    flexGrow: 0.85,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.SHORT_LINK,
    name: 'dashboard.columns.short_link',
    flexGrow: 0.9,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.TITLE,
    name: 'dashboard.columns.title',
    flexGrow: 0.7,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.DESTINATION_URL,
    name: 'dashboard.columns.destination_url',
    flexGrow: 0.8,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.THEME,
    name: 'dashboard.columns.theme',
    flexGrow: 0.4,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.AD_TIER,
    name: 'dashboard.columns.ad_tier',
    flexGrow: 0.75,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.MAXIMUM_TASKS,
    name: 'dashboard.columns.maximum_tasks',
    flexGrow: 0.4,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.CREATED_DATE,
    name: 'dashboard.columns.created_date',
    flexGrow: 0.7,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.CATEGORY,
    name: 'dashboard.columns.category',
    flexGrow: 0.45,
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.ACTIONS,
    name: 'dashboard.columns.actions',
    flexGrow: 0.8,
    sortable: false,
    ...columnConfig,
  },
];
