import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { sideBarData } from '../../constants/side_nav';
import { replaceSpacesWithUnderscores } from 'src/app/shared/helper/helper';
import { SideNavSectionsEnum } from '../../enums/side_nav_section';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { ProfileService } from '../../service/profile.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { PopupNoDetailsComponent } from '../popup-no-details/popup-no-details.component';
import { SideNavModel } from '../../models/side_nav.model';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit {
  sideBarData = sideBarData;
  @Output() titleClicked = new EventEmitter<string>();
  @Input() activeTitle: string = SideNavSectionsEnum.PERSONAL_SETTINGS;

  constructor(
    private generalService: GeneralService,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private overlayService: OverlayService
  ) {}

  ngOnInit(): void {
    this.loaderService.show();
    this.initSideBarData();
  }

  initSideBarData() {
    setTimeout(() => {
      this.generalService.hasPicName$.subscribe((value: boolean) => {
        if (value) {
          this.sideBarData = sideBarData;
          this.sideBarData.forEach((data: SideNavModel) => {
            data.disable = false;
          });
        } else {
          this.overlayService.open(PopupNoDetailsComponent, {});
          this.sideBarData.forEach((data: SideNavModel) => {
            if (data.key !== SideNavSectionsEnum.CREATOR_DETAILS) {
              data.disable = true;
            }
          });

          this.profileService.setTab(SideNavSectionsEnum.CREATOR_DETAILS);
        }
      });
      this.loaderService.hide();
    }, 700);
  }

  ngOnDestroy() {
    this.profileService.setTab(SideNavSectionsEnum.PERSONAL_SETTINGS);
  }

  changeTab(title: string) {
    this.activeTitle = replaceSpacesWithUnderscores(title);
    this.titleClicked.emit(title);
  }
}
