import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app';
import { UserModel } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginRequestModel } from '../../models/login-request.model';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { snackbarObjectUpdateUserSucceed } from '../../components/snackbar/snackbar';
import { snackbarObjectLoginFailed } from '../../components/snackbar/snackbar';
import { snackbarObjectUpdateUserFailed } from '../../components/snackbar/snackbar';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly apiUrl: string;
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedIn.asObservable();
  public token!: string | undefined | null;
  public user = new BehaviorSubject<UserModel>(new UserModel());
  public user$ = this.user.asObservable();
  redirectUrl!: string;

  private loginErr: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public loginErr$: Observable<string> = this.loginErr.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loaderService: LoaderService,
    private snackbarService: SnackbarService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  login(credentials: LoginRequestModel) {
    const url = this.apiUrl + this.appConfig.actions.user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, credentials, { headers }).pipe(
      tap(
        (res: any) => {
          if (res !== null) {
            this.token = res.message.token;
            this.user.next(res.message);

            localStorage.setItem('creatorId', res.message.id);
            localStorage.setItem(
              'accountType',
              res.message.account_type.toString()
            );
            if (res.message.is_first_time === false) {
              localStorage.setItem('oldUser', 'true');
            }
            return res.message;
          }
          this.loaderService.hide();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.loginErr.next(error.error.message);
          }
          this.loaderService.hide();
          this.snackbarService.showSnackbar(snackbarObjectLoginFailed);
        }
      )
    );
  }

  get TokenFromStorage() {
    const token = localStorage.getItem('authToken');
    const headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return headers;
  }

  updateUser(userDetails: UserModel) {
    const headers = this.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.user;
    return this.http
      .put<any>(
        url,
        {
          ...userDetails,
        },
        { headers }
      )
      .pipe(
        tap(
          (res: any) => {
            this.snackbarService.showSnackbar(snackbarObjectUpdateUserSucceed);
            if (res != null) {
              this.snackbarService.showSnackbar(
                snackbarObjectUpdateUserSucceed
              );
              return res.message;
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.loginErr.next(error.error.message);
            }
            this.loaderService.hide();
            this.snackbarService.showSnackbar(snackbarObjectUpdateUserFailed);
          }
        )
      );
  }

  forgetPassword(email: string) {
    const url = this.apiUrl + this.appConfig.actions.resetPasswordEmail + email;
    return this.http.get(url);
  }

  resetPassword(password: string, creatorId: string | null) {
    const url =
      this.apiUrl + this.appConfig.actions.user + `?publisher_id=${creatorId}`;
    return this.http
      .put<any>(url, {
        password: password,
      })
      .pipe(
        map((res: any) => {
          if (res != null && res !== undefined) {
            const { message } = res;
            return message;
          }
        })
      );
  }

  getAccountType() {
    return Number(localStorage.getItem('accountType'));
  }

  loginGuard() {
    if (this.token) {
      localStorage.setItem('authToken', this.token);
      this.isLoggedIn.next(true);
      return this.isLoggedIn$;
    } else {
      localStorage.removeItem('authToken');
      localStorage.removeItem('creatorId');
      localStorage.removeItem('accountType');
      this.isLoggedIn.next(false);
      return this.isLoggedIn$;
    }
  }

  logoutGuard(): Observable<boolean> {
    localStorage.removeItem('authToken');
    localStorage.removeItem('creatorId');
    localStorage.removeItem('accountType');
    localStorage.removeItem('hasDetails');

    this.isLoggedIn.next(false);
    return this.isLoggedIn$;
  }
}
