import { Component } from '@angular/core';
import { cards } from './constants/cards';
import { TooltipPosition } from 'src/app/shared/components/tooltip/enum/tooltip-position.enum';

@Component({
  selector: 'app-creating-cards',
  templateUrl: './creating-cards.component.html',
  styleUrls: ['./creating-cards.component.scss'],
})
export class CreatingCardsComponent {
  cards = cards;
  protected readonly TooltipPosition = TooltipPosition;
}
