import { StepModel } from '../../models/steps.model';

export const FullScriptStep: StepModel[] = [
  {
    index: 1,
    isOpen: true,
    isValid: false,
  },
  {
    index: 2,
    isOpen: false,
    isValid: false,
  },
  {
    index: 3,
    isOpen: false,
    isValid: false,
  },
];
