import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FiltersService } from '../../services/filters.service';
import {
  Filter,
  SelectedFilterObject,
  filtersListsKey,
} from '../../types/filters';
import { StatisticsService } from '../../services/statistics.service';
import { combineLatest } from 'rxjs';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { FilterType } from '../../model/filterItem.model';
import { Item } from 'src/app/shared/types/item';
import { rangeOptions } from 'src/app/shared/components/date-picker/date-preset';
import { formatDateRange } from 'src/app/shared/helper/helper';
import * as moment from 'moment';

interface SelectedDateItem {
  key: string;
  value: moment.Moment[];
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() isDesktop!: boolean;
  @Input() selected!: SelectedFilterObject;
  @ViewChild('contentTemplate', { static: true })
  contentTemplate!: TemplateRef<any>;
  filters!: Filter;
  isDisable = false;
  public rangeOption = Object.entries(rangeOptions).map(([label, range]) => ({
    key: label,
    value: range,
  }));
  selectedDate!: SelectedDateItem;
  private bottomSheetRef!: MatBottomSheetRef<BottomSheetComponent>;

  constructor(
    public filtersService: FiltersService,
    private statisticsService: StatisticsService,
    private loaderService: LoaderService,
    private bottomSheet: MatBottomSheet,
    public translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.filtersService.chooseFromList([], filtersListsKey.countries);
    this.filtersService.setBtnDisabled(true);
  }

  ngOnInit(): void {
    const initialDateRange = {
      startDate: rangeOptions['Last 7 Days'][0],
      endDate: rangeOptions['Last 7 Days'][1],
    };
    const dateRange = formatDateRange(initialDateRange);
    combineLatest([
      this.filtersService.lists$,
      this.statisticsService.filters$,
      this.filtersService.btnDisabled$,
    ]).subscribe(([lists, filters, disabled]) => {
      lists.map((list) => {
        this.selectAllForDropdownItems(list.data);
      });
      // this.isDisable = disabled;
      this.filters = filters;
      this.selectedDate = this.findItemForDateRange(
        filters.from_date || dateRange?.from_date || '',
        filters.to_date || dateRange?.to_date || ''
      );
    });
    this.getDateSelected(this.selectedDate);
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any) => {
      items.forEach((element: any) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  openBottomSheet() {
    this.bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: {
        contentTemplate: this.contentTemplate,
      },
    });
  }

  closeBottomSheet(event?: any) {
    this.bottomSheetRef.dismiss();
  }

  onSelectChange(selectedItems: Item[], key: keyof Filter) {
    const previousSelected = this.selected[key] || [];
    let list!: any;
    switch (key) {
      case filtersListsKey.links:
        list = this.filtersService.linkList.value.data;
        break;
      case filtersListsKey.tags:
        list = this.filtersService.placementList.value.data;
        break;
      case filtersListsKey.adFormats:
        list = this.filtersService.adFormatsList.value.data;
        break;
      default:
        list = this.filtersService.countryList.value.data;
        break;
    }
    const removedItems = list.filter(
      (item: Item) => !selectedItems.includes(item)
    );

    setTimeout(() => {
      if (selectedItems.length > previousSelected.length) {
        //Add
        this.filtersService.chooseFromList(selectedItems, key);
      } else {
        //Remove
        this.filtersService.removeFromList(removedItems, key, selectedItems);
      }
    }, 0);
  }

  compareFn(item: FilterType, selected: FilterType) {
    return item.key == selected.key;
  }

  clearAll() {
    this.filtersService.chooseFromList(
      [],
      this.filtersService.adFormatsList.value.key
    );
  }

  isClearBtnDisabled() {
    return this.filtersService.getSelectedValue().ad_formats.length === 0;
  }

  findItemForDateRange(fromDate: string, toDate: string): SelectedDateItem {
    const dateRange = [moment(fromDate), moment(toDate)];

    for (const [key, value] of Object.entries(rangeOptions)) {
      if (
        moment(value[0]).isSame(dateRange[0], 'day') &&
        moment(value[1]).isSame(dateRange[1], 'day')
      ) {
        return { key, value };
      }
    }
    return { key: 'This Year', value: rangeOptions['This Year'] };
  }

  getReport() {
    this.loaderService.show();
    const hasCountry = this.filtersService.getHasCountry();
    const numOfCountries = this.filtersService.numOfTotalCountries;
    this.statisticsService.getReport(hasCountry, numOfCountries);
    this.filtersService.setBtnDisabled(true);

    if (!this.isDesktop) {
      this.closeBottomSheet();
    }
  }

  getDateSelected(event: any) {
    const fullyDate = {
      startDate: event.value[0],
      endDate: event.value[1],
    };
    const dateRange = formatDateRange(fullyDate);
    this.statisticsService.updateFilters({
      from_date: dateRange?.from_date,
      to_date: dateRange?.to_date,
    });
  }
}
