<div class="error-container">
  <div class="header">
    <app-icon name="error"></app-icon>
    <span class="typography-heading4-thin">{{
      'error_popup.title' | translate
    }}</span>
  </div>
  <app-divider></app-divider>
  <div class="body">
    <span class="logo-title typography-headline2">{{
      'error_popup.description' | translate
    }}</span>
    <app-button
      class="typography-headline2"
      color="transparent"
      (buttonClick)="navigateToLogin()"
      >{{ 'error_popup.button' | translate }}</app-button
    >
  </div>
</div>
