import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ProfileModule } from './creator/profile/profile.module';
import { HeaderModule } from './common/components/header/header.module';
import { StatisticsModule } from './creator/statistics/statistics.module';
import { FullScriptApiModule } from './creator/full-script-api/full-script-api.module';
import { ReferralProgramModule } from './creator/referral-program/referral-program.module';
import { DashboardModule } from './creator/dashboard/dashboard.module';
import { CreatingCardsModule } from './creator/creating-flows/creating-cards/creating-cards.module';
import { PaymentModule } from './creator/payment/payment.module';

@NgModule({
  declarations: [PagesComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    TranslateModule,
    SharedModule,
    ProfileModule,
    HeaderModule,
    StatisticsModule,
    FullScriptApiModule,
    ReferralProgramModule,
    DashboardModule,
    CreatingCardsModule,
    StatisticsModule,
    PaymentModule,
  ],
})
export class PagesModule {}
