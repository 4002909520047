export class CreatorModel {
  publisher_id: number | null = null;
  publisher_username: string | null = null;
  registration_time: string | null = null;
  creator_name: string | null = null;
  main_game: string | null = null;
  social_channel: string | null = null;
  main_source_of_traffic: string | null = null;
  creator_picture: string | null = null;
  tiktok: string | null = null;
  youtube: string | null = null;
  twitch: string | null = null;
  facebook: string | null = null;
  discord: string | null = null;
  personal_site: string | null = null;
  instagram: string | null = null;
  twitter: string | null = null;
  panel_dark_theme: string | null = null;
}
