<div class="creating-card" (click)="onClick(type)">
  <div class="left">
    <img class="icon" [src]="icon" />
    <div class="description">
      <div class="title typography-body2-bold">{{ title }}</div>
      <div class="sub-title typography-body4-thin">{{ subtitle }}</div>
    </div>
  </div>
  <app-icon size="lg" class="icon" name="add"> </app-icon>
</div>
