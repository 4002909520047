import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { SnackbarService } from '../snackbar/snackbar.service';
import { MyOverlayRef } from '../../services/overlay/overlay-ref';

import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

interface LinkData {
  short_link: string;
}
@Component({
  selector: 'app-old-panel-modal',
  templateUrl: './old-panel-modal.component.html',
  styleUrls: ['./old-panel-modal.component.scss']
})
export class oldPanelModalComponent implements OnInit {

  constructor(
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    @Optional() @SkipSelf() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Optional()
    @SkipSelf()
    private bottomSheetRef: MatBottomSheetRef<oldPanelModalComponent>
  ) {}
  ngOnInit(): void {

  }


  redirectToOld(): void {
    window.location.href = 'https://creators.lootlabs.gg/#/login?useOld=true';
  }

  close() {
    if (this.ref) {
      this.ref.close();
    } else if (this.data) {
      this.bottomSheetRef.dismiss();
    }
  }
}
