import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { snackbarFailedObject } from '../../dashboard/snackbar/snackbar';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Injectable({
  providedIn: 'root',
})
export class MyProfitService {
  private readonly apiUrl!: string;
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private generalService: GeneralService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getProfit() {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.revenue;
    return this.http.get<string>(url, { headers }).pipe(
      tap(
        (res: any) => {
          return res.message[0];
        },
        (error: HttpErrorResponse) => {
          return this.generalService.catchError(snackbarFailedObject, error);
        }
      )
    );
  }
}
