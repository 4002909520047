import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../tooltip/tooltip.module';
import { IconModule } from '../icon/icon.module';
import { InputModule } from '../input/input.module';
import { oldPanelModalComponent } from './old-panel-modal.component';

@NgModule({
  declarations: [oldPanelModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    IconModule,
    InputModule,
  ],
  exports: [oldPanelModalComponent],
})
export class oldPanelModalModule {}
