import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddFullScriptService } from '../../service/add-full-script.service';

@Component({
  selector: 'app-copy-step',
  templateUrl: './copy-step.component.html',
  styleUrls: ['./copy-step.component.scss'],
})
export class CopyStepComponent {
  @Input() form!: FormGroup;

  constructor(private addFullScriptService: AddFullScriptService) {}

  getCopyCodeText() {
    if (this.addFullScriptService.codeMap) {
      return this.addFullScriptService.codeMap['js_code'];
    }
    return '';
  }
}
