import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class AppCheckboxInputComponent {
  /**
   * Is the checkbox checked
   *
   * @type {boolean}
   */
  @Input() checked = false;

  /**
   * Make the checkbox disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Is checkbox custom style
   *
   * @type {boolean}
   */
  @Input() custom = false;

  /**
   * Is checkbox custom style
   *
   * @type {boolean}
   */
  @Input() invalid = false;
}
