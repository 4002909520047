export const Links = [
  {
    title: 'header.dashboard',
    route: '/dashboard',
  },
  {
    title: 'header.statistics',
    route: '/statistics',
  },
  {
    title: 'header.full_script_api',
    route: '/full-script-api',
  },
  {
    title: 'header.referral_program',
    route: '/referral-program',
  },
  {
    title: 'header.payment',
    route: '/payment',
  },
];
