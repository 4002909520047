import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  @Input() title: string = 'empty_state.title';
  @Input() subtitle: string = 'empty_state.subtitle';
  @Input() isMobile = false;
}
