import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectUpdateUserSucceed: Snackbar = {
  duration: 2000,
  message: 'login.snack_bar_update_user',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarObjectUpdateUserFailed: Snackbar = {
  duration: 2000,
  message: 'login.snack_bar_update_user_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectLoginFailed: Snackbar = {
  duration: 2000,
  message: 'login.snack_message_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectGetGenerateFailed: Snackbar = {
  duration: 2000,
  message: 'login.snack_message_failed_generate',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectGetAccountFailed: Snackbar = {
  duration: 2000,
  message: 'support.snackbar_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
