import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddMultipleComponent } from './add-multiple.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SetUpComponent } from './components/create-link/set-up.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigureDesignComponent } from './components/configure-design/configure-design.component';

@NgModule({
  declarations: [AddMultipleComponent, SetUpComponent, ConfigureDesignComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTabsModule,
    NgSelectModule,
  ],
})
export class AddMultipleModule {}
