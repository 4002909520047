<div class="container background popupWith">
  <div class="header">
    <span class="typography-heading3-bold">{{
      'download_csv.title' | translate
    }}</span>
    <app-icon class="close" name="close" (click)="close()"></app-icon>
  </div>
  <div class="copy-frame-container"></div>
  <app-button
    class="button-flex"
    (buttonClick)="downloadCSV()"
    color="secondary"
  >
    {{ 'download_csv.download' | translate }}
  </app-button>
</div>
