import { Inject, Injectable } from '@angular/core';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { AddFullScriptComponent } from '../add-full-script.component';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { FullScriptBody } from '../constants/body-full-script';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import {
  snackbarFailedCreateFullPageContentLocker,
  snackbarFailedUpdateFullPageContentLocker,
  snackbarSuccessCreateFullPageContentLocker,
  snackbarSuccessUpdateullPageContentLocker,
} from '../snackbar/snackbar';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { FullScriptApiService } from '../../../full-script-api/sevice/full-script-api.service';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';
import { FiltersService } from '../../../statistics/services/filters.service';

@Injectable({
  providedIn: 'root',
})
export class AddFullScriptService {
  private readonly apiUrl: string;
  isCreatedEdit = false;
  tagId = 0;
  siteId = 0;
  codeMap: { [key: string]: string } | undefined;

  constructor(
    private overlay: OverlayService,
    private loaderService: LoaderService,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private http: HttpClient,
    private generalService: GeneralService,
    private snackbarService: SnackbarService,
    private fullScriptApiService: FullScriptApiService,
    private router: Router,
    private notificationService: NotificationsService,
    private filterService: FiltersService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  openAddFullScriptComponent<R = any, T = any>(data: T): MyOverlayRef<R> {
    const ref = this.overlay.open(AddFullScriptComponent, data);
    ref.afterClosed$.subscribe(() => {
      if (this.isCreatedEdit) {
        this.fullScriptApiService.updateData();
        this.router.navigate(['/full-script-api']);
        this.isCreatedEdit = false;
      }
    });
    return ref;
  }

  createFullPageContentLocker(body: FullScriptBody) {
    this.loaderService.show();
    const siteBody = { ...body };
    this.createSite(siteBody).subscribe((res: any) => {
      const ad_format = 52;
      const tagBody = {
        ...body,
        serving_method_id: Number(
          this.generalService.getServingMethod(ad_format)
        ),
        incentive_new_window: true,
        ad_unit: Number(this.generalService.getAdUnit(ad_format)),
        allow_all_external_links: true,
      };

      this.createNewTag(tagBody, res.message.id).subscribe((res: any) => {
        this.filterService.getFilters(false);
        this.tagId = res.message.id;
        this.getPlacementCode().subscribe(
          () => {
            this.loaderService.hide();
          },
          (error: any) => {
            this.loaderService.hide();
          }
        );

        this.isCreatedEdit = true;
        this.snackbarService.showSnackbar(
          snackbarSuccessCreateFullPageContentLocker
        );

        const notification = {
          notificationType: NotificationType.ContentJs,
          id: res.message.id,
        };

        const newNotification =
          this.notificationService.createNotificationObject(notification);

        this.notificationService
          .createNotification(newNotification)
          .subscribe(() => {
            this.notificationService.getNotifications();
          });
      }),
        catchError((error: HttpErrorResponse) => {
          return this.generalService.catchError(
            snackbarFailedCreateFullPageContentLocker,
            error
          );
        });
    });
  }

  editFullPageContentLocker(body: any) {
    const siteBody = { ...body, site_id: this.siteId };
    this.createSite(siteBody, true).subscribe((res: any) => {
      body.tag_id = this.tagId;
      const tagBody = {
        ...body,
        incentive_new_window: true,
      };

      tagBody.external_links_blacklist =
        tagBody.external_links_blacklist.filter(
          (link: string) => link.trim() !== ''
        );
      tagBody.external_links_whitelist =
        tagBody.external_links_whitelist.filter(
          (link: string) => link.trim() !== ''
        );

      this.createNewTag(tagBody, this.siteId, true).subscribe((res: any) => {
        this.filterService.getFilters(false);
        this.loaderService.hide();

        this.isCreatedEdit = true;
        this.getPlacementCode().subscribe();
        //TO DO: get filters

        this.snackbarService.showSnackbar(
          snackbarSuccessUpdateullPageContentLocker
        );
      }),
        catchError((error: any) => {
          this.loaderService.hide();

          return this.generalService.catchError(
            snackbarFailedUpdateFullPageContentLocker,
            error
          );
        });
    });
  }

  createSite(body: any, edit = false) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.site;

    delete body.external_links_whitelist;
    delete body.external_links_blacklist;

    if (edit) {
      return this.http.put(url, body, { headers }).pipe(tap());
    } else {
      return this.http.post(url, body, { headers }).pipe(tap());
    }
  }

  createNewTag(body: any, siteId: number, edit = false) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.tag;

    delete body.url;
    delete body.vertical;
    const ad_format = 52;
    const bodyToTag = {
      ...body,
      operation_id: ad_format,
      site_id: siteId,
      ad_unit: Number(this.generalService.getAdUnit(ad_format)),
      serving_method_id: Number(
        this.generalService.getServingMethod(ad_format)
      ),
    };

    if (edit) {
      return this.http.put(url, body, { headers }).pipe(tap());
    } else {
      return this.http.post(url, bodyToTag, { headers }).pipe();
    }
  }

  getPlacementCode() {
    this.loaderService.show();
    const url =
      this.apiUrl +
      this.appConfig.actions.get_placement_code +
      `?tag_id=${this.tagId}&&operation_id=52`;
    const headers = this.loginService.TokenFromStorage;
    return this.http.get(url, { headers }).pipe(
      tap((res: any) => {
        this.loaderService.hide();
        this.codeMap = res.message;
      })
    );
  }
}
