<app-card class="table-container">
  <div class="table-header">
    <div class="title typography-heading4-bold">
      {{ 'payments.title' | translate }}
    </div>
    <app-date-picker
      (dateSelected)="getDateSelected($event)"
      class="date-picker"
    ></app-date-picker>
  </div>
  <div *ngIf="thereIsData; else emptyState">
    <ngx-datatable
      #table
      class="table typography-body4-thin"
      [rows]="paymentService.rows$ | async"
      [columns]="(paymentService.columns$ | async) || []"
      [headerHeight]="50"
      [footerHeight]="100"
      rowHeight="auto"
      columnMode="force"
      [swapColumns]="false"
      [limit]="limit"
    >
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
        >
          <div class="footer-container">
            <div class="pager-container">
              <app-button
                [ngClass]="{ 'icon-disabled-style': curPage <= 1 }"
                color="naked"
                (buttonClick)="pager.prevPage()"
              >
                <app-icon start name="navigation_left"></app-icon>
              </app-button>
              <datatable-pager
                #pager
                [page]="paymentService.currentPage"
                [size]="pageSize"
                [count]="rowCount"
                (change)="changePage($event) && table.onFooterPage($event)"
              ></datatable-pager>
              <app-button
                [ngClass]="{
                  'icon-disabled-style': curPage === numOfPages
                }"
                color="naked"
                (buttonClick)="pager.nextPage()"
              >
                <app-icon start name="navigation_right"></app-icon>
              </app-button>
            </div>
            <ng-select
              class="pagination"
              [ngModel]="limit"
              (ngModelChange)="paymentService.setChosenLimit($event)"
              [items]="paymentService.limitOptions"
              appendTo="body"
              [clearable]="false"
              [searchable]="false"
            ></ng-select>
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>

  <ng-template #paymentMethod let-row="row" let-value="value">
    <div>{{ getPaymentValue(row.payment_type) }}</div>
  </ng-template>

  <ng-template #emptyState>
    <app-empty-state></app-empty-state>
  </ng-template>
</app-card>
