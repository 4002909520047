<div class="content">
  <app-card>
    <div class="header typography-heading3-bold">
      <span>{{
        'single.edit_multiple.title'
          | translate
            : {
                variable: (dashboardService.selectedCampaigns$ | async)?.length
              }
      }}</span>
      <img
        class="close"
        (click)="close()"
        src="../../../../../assets/svg/white_x.svg"
      />
    </div>
    <app-set-up-screen
      [background]="false"
      [isSingle]="false"
      [setUpForm]="singleLinkForm"
      (continue)="submit()"
    ></app-set-up-screen>
  </app-card>
</div>
