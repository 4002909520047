export class AccountManagerModel {
  mail: string;
  name: string;
  pic: string;
  skype: string | null;
  telegram: string | null;
  discord: string | null;

  constructor() {
    this.mail = 'contact@lootlabs.gg';
    this.name = 'Loot labs';
    this.pic = './assets/svg/placeholder_image.svg';
    this.skype = null;
    this.telegram = null;
    this.discord = null;
  }
}
