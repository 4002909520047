import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { loadCountries } from 'src/app/shared/helper/helper';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import { Item } from 'src/app/shared/types/item';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private contactPlatform = new BehaviorSubject<string[]>([]);
  public contactPlatform$ = this.contactPlatform.asObservable();
  private countries = new BehaviorSubject<Item[]>([]);
  public countries$ = this.countries.asObservable();
  private states = new BehaviorSubject<Item[]>([]);
  public states$ = this.states.asObservable();
  private cities = new BehaviorSubject<string[]>([]);
  public cities$ = this.cities.asObservable();

  constructor(private facade: GeneralFacade) {}

  loadContactPlatform() {
    this.facade.enums$
      .pipe(first((val: any) => !!val))
      .subscribe((state: any) => {
        const messaginApp: string[] = Object.values(
          state?.message?.contact_platform
        );
        this.contactPlatform.next(messaginApp);
      });
  }

  loadCountries() {
    this.facade.enums$.pipe(first((val: any) => !!val)).subscribe((state) => {
      this.countries.next(loadCountries(state));
    });
  }

  loadCities(countryCode: string | null, stateCode: string | null) {
    if (
      (countryCode === '' || stateCode === null) &&
      (stateCode === '' || countryCode === null)
    ) {
      this.cities.next([]);
      return;
    }
    this.facade.enums$
      .pipe(first((val: any) => !!val))
      .subscribe((state: any) => {
        const countryObj = state.message.countries_map;

        if (countryObj && countryCode) {
          const country = countryObj[countryCode];
          if (
            countryCode &&
            stateCode &&
            country &&
            country.states &&
            country.states[stateCode] &&
            country.states[stateCode].cities
          ) {
            const citiesArray = Object.values(
              state.message.countries_map[countryCode].states[stateCode].cities
            );

            const stringCities = citiesArray.filter(
              (city) => typeof city === 'string'
            ) as string[];

            const sortedCities = stringCities.sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            });

            this.cities.next(sortedCities);
          } else {
            const cityObj = state.message.cities_map;
            this.cities.next(
              this.getCitiesByCountry(countryCode, countryObj, cityObj)
            );
          }
        }
      });
  }

  loadStates(countryCode: string | null) {
    this.facade.enums$
      .pipe(first((val: any) => !!val))
      .subscribe((state: any) => {
        const countryObj = state.message.countries_map;
        if (countryCode && this.thereIsState(countryCode)) {
          const country = countryObj[countryCode];
          if (country && country.states) {
            const statesObj = country.states;

            const states = Object.keys(statesObj)
              .map((stateCode) => statesObj[stateCode].name)
              .sort((a, b) => a.localeCompare(b));

            this.states.next(states);
          } else {
            this.states.next([]);
          }
        } else {
          this.states.next([]);
        }
      });
  }

  getCitiesByCountry(countryCode: any, countriesMap: any, citiesMap: any) {
    const countryObject = countriesMap[countryCode];
    if (countryObject && countryObject.country_name) {
      const cities = Object.keys(citiesMap)
        .filter((city) => citiesMap[city] === countryCode)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      return cities;
    } else {
      return [];
    }
  }

  thereIsState(countryCode: string) {
    let statesExist = false;
    this.facade.enums$
      .pipe(first((val: any) => !!val))
      .subscribe((state: any) => {
        const countryObj = state.message.countries_map;
        const country = countryObj[countryCode];
        if (country && country.hasOwnProperty('states')) {
          statesExist = true;
        } else {
          statesExist = false;
        }
      });
    return statesExist;
  }
}
