import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { groupByButtonComponent } from './group-by-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [groupByButtonComponent],
  imports: [CommonModule, TranslateModule],
  exports: [groupByButtonComponent],
})
export class groupByButtonModule {}
