import { Action, createReducer, on } from '@ngrx/store';
import * as LoginActions from './login.action';
import { LoginState, initialLoginState } from './login.state';

const loginReducer = createReducer(
  initialLoginState,
  on(LoginActions.Login, (state, {}) => ({
    ...state,
    status: 'loading',
  })),
  on(LoginActions.LoginSuccess, (state, { user }) => ({
    ...state,
    user: user,
    status: 'success',
    error: null,
  })),
  on(LoginActions.LoginFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error,
  })),

  on(LoginActions.updateUser, (state, {}) => ({
    ...state,
    status: 'loading',
  })),
  on(LoginActions.UpdateUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
    status: 'success',
    error: null,
  })),
  on(LoginActions.UpdateUserFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error,
  }))
);

export function reducer(state: LoginState | undefined, action: Action) {
  return loginReducer(state, action);
}
