import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectSucceed: Snackbar = {
  duration: 2000,
  message: 'email_send.snack_bar_succeed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarObjectFailed: Snackbar = {
  duration: 2000,
  message: 'email_send.snack_bar_succeed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
