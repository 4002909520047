import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | null): string {
    if (!value) return '';

    const now = new Date();
    const seconds = Math.floor((now.getTime() - value.getTime()) / 1000);

    if (seconds < 60) {
      return 'few seconds ago';
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }

    const days = Math.floor(hours / 24);
    if (days < 7) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    }

    const weeks = Math.floor(days / 7);
    if (weeks < 52) {
      return weeks === 1 ? '1 week ago' : `${days} weeks ago`;
    }

    const years = Math.floor(weeks / 52);
    return years === 1 ? '1 year ago' : `${days} years ago`;
  }
}
