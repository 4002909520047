import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio-circle',
  templateUrl: './radio-circle.component.html',
  styleUrls: ['./radio-circle.component.scss'],
})
export class AppRadioCircleComponent {
  /**
   * Radio label
   *
   * @type {string}
   */
  @Input() label = '';

  /**
   * Radio checked
   *
   * @type {string}
   */
  @Input() checked = false;

  /**
   * Radio disabled
   *
   * @type {string}
   */
  @Input() disabled = false;

  /**
   * Custom Style for Radio
   *
   * @type {string}
   */
  @Input() customStyle? = '';
}
