import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';

@Component({
  selector: 'app-popup-no-details',
  templateUrl: './popup-no-details.component.html',
  styleUrls: ['./popup-no-details.component.scss'],
})
export class PopupNoDetailsComponent {
  constructor(private ref: MyOverlayRef) {}

  close() {
    this.ref.close();
  }
}
