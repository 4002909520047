<div [formGroup]="setupLinkForm" class="container">
  <div class="form-container">
    <div class="form-line">
      <div class="col">
        <label class="typography-body4-thin" for="title">{{
          'full_page.step_1.url' | translate
        }}</label>
        <div class="line">
          <app-input
            type="text"
            [disabled]="isEditMode || addFullScriptService.isCreatedEdit"
            class="title"
            formControlName="url"
            [placeholder]="'full_page.step_1.url_placeholder' | translate"
            [invalid]="
              setupLinkForm.get('url')?.hasError('pattern') ||
              (setupLinkForm.get('url')?.hasError('required') &&
                setupLinkForm.get('url')?.dirty)
            "
          ></app-input>
        </div>
      </div>
    </div>
    <div class="col">
      <label class="typography-body4-thin" for="title">{{
        'full_page.step_1.tag_name' | translate
      }}</label>

      <app-input
        type="text"
        class="title"
        formControlName="name"
        [maxLength]="30"
        [textCounter]="true"
        [placeholder]="'full_page.step_1.name_placeholder' | translate"
        [invalid]="
          (setupLinkForm.get('name')?.hasError('minlength') &&
            !setupLinkForm.get('name')?.pristine) ||
          (setupLinkForm.get('name')?.hasError('required') &&
            !setupLinkForm.get('name')?.pristine) ||
          (setupLinkForm.get('name')?.hasError('maxlength') &&
            !setupLinkForm.get('name')?.pristine)
        "
      ></app-input>
    </div>
  </div>
  <app-button
    class="btn"
    (buttonClick)="next()"
    [disabled]="setupLinkForm.invalid"
    >{{ 'full_page.step_1.btn' | translate }}</app-button
  >
</div>
