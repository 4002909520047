import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]',
})
export class NumericOnlyDirective {
  @Input() public appNumericOnly!: boolean;
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    if (this.appNumericOnly) {
      const input = event.target as HTMLInputElement;

      const inputValue = input.value;

      const numericValue = inputValue.replace(/[^0-9]/g, '');

      input.value = numericValue;
    }
  }
}
