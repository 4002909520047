import { createAction, props } from '@ngrx/store';
import { LootLabsData } from '../enums/enum.model';

export const initLootLabsData = createAction('[LootLabs Page] Init LootLabsData');

export const loadLootLabsData = createAction('[LootLabs Page] Load LootLabsData');

export const loadLootLabsDataSuccess = createAction(
  '[LootLabs/API] Load LootLabsData Success',
  props<{ lootLabsData: LootLabsData }>()
);

export const loadLootLabsDataFailure = createAction(
  '[LootLabs/API] Load LootLabsData Failure',
  props<{ error: any }>()
);
