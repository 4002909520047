import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordComponent } from './components/password/password.component';
import { ApiKeyComponent } from './components/api-key/api-key.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';

import { CreatorDetailsComponent } from './components/creator-details/creator-details.component';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { PersonalSettingsComponent } from './components/personal-settings/personal-settings.component';
import { GeneralInfoComponent } from './components/personal-settings/components/general-info/general-info.component';
import { AddressComponent } from './components/personal-settings/components/address/address.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { SocialChannelsComponent } from './components/social-channels/social-channels.component';
import { PopupNoDetailsComponent } from './components/popup-no-details/popup-no-details.component';

@NgModule({
  declarations: [
    ProfileComponent,
    SideNavbarComponent,
    PasswordComponent,
    ApiKeyComponent,
    PaymentMethodComponent,
    PersonalSettingsComponent,
    CreatorDetailsComponent,
    PaymentMethodFormComponent,
    GeneralInfoComponent,
    AddressComponent,
    UserDetailsComponent,
    SocialChannelsComponent,
    PopupNoDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgSelectModule,
  ],
})
export class ProfileModule {}
