import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { DashboardService } from './service/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private generalService: GeneralService,
    private dashboardService: DashboardService
  ) {}
  ngOnInit(): void {
    this.generalService.loadEnums();
    this.dashboardService.updateData().subscribe();
  }
}
