import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../store/login-state/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { snackbarObjectSucceed } from './snackbar/snackbar';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss'],
})
export class EmailSendComponent implements OnInit {
  email!: string | null;
  message!: string;
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
  }

  sendAgain() {
    if (this.email) {
      this.loginService.forgetPassword(this.email).subscribe(
        (res: any) => {
          this.snackbarService.showSnackbar(snackbarObjectSucceed);
        },
        (error: HttpErrorResponse) => {
          this.message = error.error.message;
          //TO DO: add error function in general service
        }
      );
    }
  }
}
