import { SideNavSectionsEnum } from '../enums/side_nav_section';
import { SideNavModel } from '../models/side_nav.model';

export const sideBarData: Array<SideNavModel> = [
  {
    key: SideNavSectionsEnum.PERSONAL_SETTINGS,
    title: 'profile.side_nav.personal_settings',
    icon: 'account',
    iconActive: 'active_account',
    disable: false,
  },
  {
    key: SideNavSectionsEnum.PAYMENT_METHOD,
    title: 'profile.side_nav.payment_method',
    icon: 'payment',
    iconActive: 'active_payment',
    disable: false,
  },
  {
    key: SideNavSectionsEnum.CREATOR_DETAILS,
    title: 'profile.side_nav.creator_details',
    icon: 'computer_mouse',
    iconActive: 'active_computer_mouse',
    disable: false,
  },
  {
    key: SideNavSectionsEnum.PASSWORD,
    title: 'profile.side_nav.password',
    icon: 'lock',
    iconActive: 'active_lock',
    disable: false,
  },
  {
    key: SideNavSectionsEnum.API_KEY,
    title: 'profile.side_nav.api_key',
    icon: 'key',
    iconActive: 'active_key',
    disable: false,
  },
];
