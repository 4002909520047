<div class="referral">
  <div class="header">
    <div class="title typography-heading4-bold">
      {{ 'referral_program.title' | translate }}
    </div>
    <div class="subtitle typography-body4-thin">
      {{ 'referral_program.subtitle' | translate }}
    </div>
  </div>
  <div class="commision-container">
    <div class="left-container">
      <div class="title-referral typography-heading2">
        {{ 'referral_program.commission.title' | translate }}
      </div>
      <div class="title-referral typography-headline2 subtitle">
        {{ 'referral_program.commission.subtitle' | translate }}
      </div>
    </div>
    <div class="right-container">
      <div class="title-referral title typography-footnote1">
        {{ 'referral_program.commission.unique' | translate }}
      </div>
      <div class="copy">
        <app-button
          *ngIf="!isGenerate"
          color="secondary"
          (buttonClick)="generate()"
        >
          {{ 'referral_program.commission.generate' | translate }}</app-button
        >
        <app-copy-code
          [showCopy]="isGenerate"
          [linkText]="getCode()"
        ></app-copy-code>
      </div>
    </div>
  </div>

  <div class="commision-container advertising-material">
    <div class="titles">
      <span class="typography-heading4-bold">{{
        'referral_program.advertising_material.title' | translate
      }}</span>
      <span class="coming-soon typography-footnote2-bold">{{
        'referral_program.advertising_material.coming_soon' | translate
      }}</span>
    </div>
    <div class="buttons">
      <app-button color="secondary" [disabled]="isAdvertisingMaterialDisabled"
        ><app-icon
          [ngClass]="{
            'disabled-icon': isAdvertisingMaterialDisabled
          }"
          name="website"
        ></app-icon
        >{{
          'referral_program.advertising_material.website' | translate
        }}</app-button
      >
      <app-button color="secondary" [disabled]="isAdvertisingMaterialDisabled"
        ><app-icon
          [ngClass]="{
            'disabled-icon': isAdvertisingMaterialDisabled
          }"
          name="youtube"
        ></app-icon
        >{{
          'referral_program.advertising_material.youtube' | translate
        }}</app-button
      >
    </div>
  </div>
</div>
<app-card>
  <div class="table-header">
    <div class="title typography-heading4-bold">
      {{ 'referral_program.your_referrals' | translate }}
    </div>
  </div>
  <app-input
    class="input"
    prefix="search"
    [placeholder]="'referral_program.search_placeholder' | translate"
    (inputChanged)="searchElement($event)"
  ></app-input>
  <app-referral-table></app-referral-table>
</app-card>
