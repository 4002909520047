import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Regex } from './regex';
import { linkOptions } from 'src/app/pages/creator/creating-flows/add-single/constants/link-option';

export function passwordMatcher(control: AbstractControl): void | null {
  const passwordControl = control.get('password');
  const confirmPasswordControl = control.get('confirmPassword');

  if (passwordControl?.pristine || confirmPasswordControl?.pristine) {
    return null;
  }

  if (passwordControl?.value === confirmPasswordControl?.value) {
    return null;
  }

  confirmPasswordControl?.setErrors({ match: true });
}

export const atLeastOneCompleted: (controlNames: string[]) => ValidatorFn = (
  controlNames: string[]
) => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (!(formGroup instanceof FormGroup)) {
      return null;
    }
    const isAtLeastOneCompleted = controlNames.some((controlName) => {
      const control = formGroup.get(controlName) as AbstractControl;
      return control?.value !== null && control?.value !== '';
    });

    return isAtLeastOneCompleted ? null : { atLeastOneCompleted: true };
  };
};

export function youtubeValidator(config: {
  control: AbstractControl | null;
  additionalControls: AbstractControl | null;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      config.additionalControls &&
      config.additionalControls.value === linkOptions[2].key
    ) {
      return null;
    }
    if (
      config.additionalControls &&
      config.additionalControls.value === linkOptions[0].key
    ) {
      if (!control.value) {
        return { image: true };
      } else {
        return null;
      }
    }
    const result = Regex.youtubeRegex.test(control.value);
    if (result) {
      return null;
    } else {
      return { youtube: true };
    }
  };
}
