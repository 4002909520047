import { UserModel } from '../models/user.model';

export const USER = 'user';
export const ERROR = 'error';

export interface LoginState {
  user: UserModel | null;
  error: string | null;
  status: 'pending' | 'loading' | 'error' | 'success';
}

export const initialLoginState: LoginState = {
  user: null,
  error: null,
  status: 'pending',
};
