import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectFailedFilters: Snackbar = {
  duration: 2000,
  message: 'statistics.filters_failed_snack_bar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectFailedColumns: Snackbar = {
  duration: 2000,
  message: 'statistics.columns_failed_snack_bar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarObjectFailedGetReport: Snackbar = {
  duration: 2000,
  message: 'statistics.report_failed_snack_bar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
