import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadCsvComponent } from './download-csv.component';
import { IconModule } from '../icon/icon.module';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DownloadCsvComponent],
  imports: [CommonModule, IconModule, ButtonModule, TranslateModule],
  exports: [DownloadCsvComponent],
})
export class DownloadCsvModule {}
