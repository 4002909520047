<app-card>
  <div class="form-container">
    <div class="layout">
      <div class="multiple-link-container">
        <div class="header-container">
          <div class="header">
            <span class="title typography-heading3-bold">
              {{ 'multiple.create_title' | translate }}
            </span>
            <img
              *ngIf="isDesktop"
              class="close"
              (click)="close()"
              src="../../../../../assets/svg/white_x.svg"
            />
          </div>
          <ng-container *ngFor="let bar of progressBars">
            <mat-progress-bar
              *ngIf="(isDesktop && !ref.data.editMode) || !isDesktop"
              mode="determinate"
              [value]="bar"
            ></mat-progress-bar>
          </ng-container>
        </div>
        <div class="content">
          <div class="form">
            <mat-accordion [formGroup]="multipleLinkForm">
              <mat-expansion-panel
                (opened)="panelOpened(0)"
                (closed)="panelClosed(0)"
                [expanded]="steps[0].isOpen"
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    'edit-mode':
                      multipleLinkForm.get('first')?.valid && !steps[0].isOpen,
                    closed:
                      multipleLinkForm.get('first')?.valid && !steps[0].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[0].isValid,
                      true: steps[0].isOpen && !this.steps[0].isValid,
                      false: !steps[0].isOpen && !this.steps[0].isValid
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[0].isValid && steps[0].isOpen) ||
                          (!this.steps[0].isValid && !steps[0].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">{{
                        steps[0].index
                      }}</span>
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span class="typography-heading4-bold">{{
                      'single.step_1.title' | translate
                    }}</span>
                    <div
                      class="values"
                      *ngIf="
                        multipleLinkForm.get('first')?.valid && !steps[0].isOpen
                      "
                    >
                      <div class="left-edit">
                        <app-icon name="edit"></app-icon>
                        <span
                          class="typography-body3-bold txt-w-dots"
                          [tooltip]="firstFormGroup.get('short_name')?.value"
                          >{{ 'single.step_1.source_name_title' | translate }}:
                          {{ firstFormGroup.get('short_name')?.value }}</span
                        >
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <set-up
                  (continue)="firstStepCompleted()"
                  [setUpForm]="firstFormGroup"
                ></set-up>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion [formGroup]="multipleLinkForm">
              <mat-expansion-panel
                (opened)="panelOpened(1)"
                (closed)="panelClosed(1)"
                [expanded]="steps[1].isOpen && !steps[0].isOpen"
                [disabled]="
                  multipleLinkForm.get('second')?.invalid &&
                  !this.steps[0].isValid
                "
              >
                <mat-expansion-panel-header
                  class="disable_ripple"
                  [ngClass]="{
                    'edit-mode':
                      multipleLinkForm.get('second')?.valid &&
                      !steps[1].isOpen &&
                      isDesktop,
                    closed:
                      multipleLinkForm.get('second')?.valid && !steps[1].isOpen
                  }"
                >
                  <div
                    class="circle"
                    [ngClass]="{
                      valid: this.steps[1].isValid,
                      true: steps[1].isOpen,
                      false: !steps[1].isOpen
                    }"
                  >
                    <ng-container
                      *ngIf="
                        (!this.steps[1].isValid && steps[0].isOpen) ||
                          (!this.steps[1].isValid && !steps[0].isOpen);
                        else iconContent
                      "
                    >
                      <span class="typography-footnote1">
                        {{ steps[1].index }}</span
                      >
                    </ng-container>
                    <ng-template #iconContent>
                      <app-icon name="white_v"></app-icon>
                    </ng-template>
                  </div>
                  <div class="title-w-values">
                    <span class="color-white typography-heading4-bold">{{
                      'multiple.step_2.title' | translate
                    }}</span>
                  </div>
                </mat-expansion-panel-header>
                <app-configure-design
                  (csvFile)="getCsvFile($event)"
                  [configureDesignForm]="secondFormGroup"
                >
                </app-configure-design>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="submit-button">
    <app-button [disabled]="submitValidation()" (buttonClick)="submitStep()">{{
      'multiple.submit_button' | translate
    }}</app-button>
  </div>
</app-card>
