import { AppIcon } from 'src/app/shared/components/icon/icons';
import { CreatingCardType } from '../enums/creating-card-type.enum';

export const cards = [
  {
    type: CreatingCardType.SINGLE,
    title: 'creating_cards.single.title',
    subtitle: 'creating_cards.single.subtitle',
    icon: '../../../../../assets/svg/single.svg',
    color: 'blue',
    tooltip: 'In this tab you can create a monetized link, you can choose a link, match it with an appropriate ad tier, title and thumbnail in order to earn revenue from a single link. Tip: Rewards and limited content works the best.',
  },
  {
    type: CreatingCardType.MULTIPLE,
    title: 'creating_cards.multiple.title',
    subtitle: 'creating_cards.multiple.subtitle',
    icon: '../../../../../assets/svg/multiple.svg',
    color: 'blue',
    tooltip: 'This tab allows you to upload a csv format file in order to create multiple single links in the same time, allowing a somewhat easy solution for mass bulk link import from websites or other services.',
  },
  {
    type: CreatingCardType.FULL_SCRIPT,
    title: 'creating_cards.full_script.title',
    subtitle: 'creating_cards.full_script.subtitle',
    icon: '../../../../../assets/svg/fullscript.svg',
    color: 'blue',
    tooltip: 'change automatic to automatically - This tab allows you to automatically monetize a website using javascript, this helps site owners create monetized links on their site using blacklist and whitelist filters, helping website owners earn from their outgoing link with ease.',
  },
];
