import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import { LoginService } from './common/login/store/login-state/login.service';
import { LootLabsData } from 'src/app/shared/enums/enum.model';
import { GeneralService } from '../shared/states/general-state.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { NotificationModel } from '../shared/components/notifications/models/notifications.model';
import { NotificationsService } from '../shared/components/notifications/services/notifications.service';
import { NotificationSlideService } from '../shared/components/notifications/services/notification-slide.service';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../shared/components/bottom-sheet/bottom-sheet.component';
import { AccountManagerModel } from '../shared/types/account-manager';
import {
  capitalizeFirstLetter,
  removeUnderLine,
} from '../shared/helper/helper';
import { SupportService } from '../shared/components/support/services/support.service';
import { FiltersService } from './creator/statistics/services/filters.service';
import { StatisticsService } from './creator/statistics/services/statistics.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  @ViewChild('notificationsTemplate')
  private notificationsTemplate!: TemplateRef<any>;
  @ViewChild('headerTemplate') private headerTemplate!: TemplateRef<any>;

  @ViewChild('supportContent') private supportContent!: TemplateRef<any>;
  isLoading = false;
  @ViewChild('headerSupport') private headerSupport!: TemplateRef<any>;
  private bottomSheetRef!: MatBottomSheetRef<BottomSheetComponent>;
  lootLabsData!: LootLabsData | null;
  showCards = true;
  isDesktop = true;
  isBackdrop = false;
  isNotificationsOpen!: boolean;
  accountManager: AccountManagerModel = new AccountManagerModel();
  accountManagerName!: string;
  discordLink = 'https://discord.com/channels/@';
  faqLink = 'https://help.lootlabs.gg/en/';
  telegramLink = 'https://telegram.me/';
  mailLink = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=';
  public notificationsDataArray!: Array<NotificationModel>;
  constructor(
    private generalFacade: GeneralFacade,
    private loginService: LoginService,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private notificationsService: NotificationsService,
    private notificationSlideService: NotificationSlideService,
    private bottomSheet: MatBottomSheet,
    private supportService: SupportService,
    private filtersService: FiltersService,
    private statisticsService: StatisticsService
  ) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.getAllNotifications();
    this.generalService.getAccountManager();
    this.notificationsService.getNotifications();
    this.generalService.getSiteReferral();
    if (this.statisticsService.getMaps().length === 0) {
      this.filtersService.getFilters(false);
      this.filtersService.getUserColums();
    }
    this.showCreatingCards(this.router.url);
    if (!this.generalService.geCreatorDetails()) {
      this.generalService.getCreator().subscribe();
    }
    if (this.loginService.TokenFromStorage) {
      this.generalFacade.loadLootLabsData();
    }
    this.generalFacade.lootLabsData$.subscribe((data) => {
      this.lootLabsData = data;
    });

    this.generalService.isDesktop$.subscribe((value: boolean) => {
      this.isDesktop = value;
    });

    this.notificationSlideService.notificationSlideOpen.subscribe(
      (res: boolean) => {
        this.isNotificationsOpen = res;
      }
    );

    this.generalService.accountManager$.subscribe(
      (account: AccountManagerModel) => {
        this.accountManager = account;
        this.accountManagerName = this.customName();
      }
    );

    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => this.showCreatingCards(event.url));
    this.generalService.loadEnums();
  }

  showCreatingCards(url: string) {
    const isInProfile = url.includes('/profile');
    const isInLoginFlow =
      url.includes('/login') ||
      url.includes('/forgot-password') ||
      url.includes('/email-send') ||
      url.includes('/reset-password');
    this.showCards = !(isInProfile || isInLoginFlow);
  }

  customName() {
    if (this.accountManager && this.accountManager.name) {
      const arrayOfName = removeUnderLine(this.accountManager.name || '').split(
        ' '
      );

      return arrayOfName.map(capitalizeFirstLetter).join(' ');
    } else {
      return 'LootLabs';
    }
  }

  openSupport() {
    this.isDesktop
      ? this.supportService.toggleSidenav()
      : this.openBottomSheetSupport();
  }

  openSkype() {
    const link = 'skype:' + this.accountManager.skype + '?chat';
    window.location.href = link;
  }
  openNotifications() {
    if (!this.isNotificationsOpen) {
      this.notificationsService.updateNotificationsSeen().subscribe(() => {
        this.notificationsService.getNotifications();
      });
    }

    this.isDesktop
      ? this.notificationSlideService.toggleSidenav()
      : this.openBottomSheetNotifications();
  }

  getAllNotifications() {
    this.notificationsService.notificationsData$.subscribe(
      (res: Array<NotificationModel>) => {
        this.notificationsDataArray = [];
        let count = 0;
        for (const notification of res) {
          if (notification.clicked && notification.seen) {
            if (count < 10) {
              this.notificationsDataArray.push(notification);
              count++;
            }
          } else {
            this.notificationsDataArray.push(notification);
          }
        }
      }
    );
  }

  openBottomSheetSupport() {
    this.bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: {
        contentTemplate: this.supportContent,
        headerTemplate: this.headerSupport,
      },
    });
  }

  markAll() {
    this.notificationsService.onClickAllNotification();
  }

  openBottomSheetNotifications() {
    if (this.notificationsDataArray.length === 0) {
      this.handelLoader();
    }

    this.bottomSheetRef = this.bottomSheet.open(BottomSheetComponent, {
      data: {
        contentTemplate: this.notificationsTemplate,
        headerTemplate: this.headerTemplate,
      },
    });
    this.bottomSheetRef.afterDismissed().subscribe(() => {
      this.isNotificationsOpen = false;
    });
  }

  handelLoader() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }
}
