<div class="address" [formGroup]="addressForm">
  <div class="title typography-heading4-thin">
    {{ 'profile.personal_settings_page.address.title' | translate }}
  </div>
  <div class="form">
    <div class="line">
      <label class="typography-headline2">
        {{
          'profile.personal_settings_page.address.form_fields.country'
            | translate
        }}
      </label>
      <ng-select
        class="dropdown typography-headline2"
        [multiple]="false"
        [items]="accountService.countries$ | async"
        bindLabel="value"
        bindValue="key"
        formControlName="country"
        class="dropdown"
        [clearable]="false"
        [ngClass]="{
          'has-value': addressForm.get('country') !== null
        }"
        [class.error]="addressForm.get('country')?.hasError('required')"
      ></ng-select>
    </div>
    <div class="line">
      <label class="typography-headline2">
        {{
          'profile.personal_settings_page.address.form_fields.state' | translate
        }}
      </label>
      <ng-select
        class="dropdown typography-headline2"
        [multiple]="false"
        [items]="accountService.states$ | async"
        bindLabel="value"
        bindValue="key"
        formControlName="state"
        [dropdownPosition]="'bottom'"
        class="dropdown"
        formControlName="state"
        [readonly]="!thereIsState"
        [ngClass]="{
          'has-value': addressForm.get('state') !== null
        }"
      ></ng-select>
    </div>

    <div class="line">
      <label class="typography-headline2">
        {{
          'profile.personal_settings_page.address.form_fields.city' | translate
        }}
      </label>
      <ng-select
        class="dropdown typography-headline2"
        [multiple]="false"
        [items]="accountService.cities$ | async"
        bindLabel="value"
        bindValue="key"
        formControlName="city"
        [virtualScroll]="true"
        class="dropdown"
        [readonly]="disableCities"
        [ngClass]="{
          'has-value': addressForm.get('city') !== null
        }"
        [class.error]="addressForm.get('city')?.hasError('required')"
      ></ng-select>
    </div>
    <div class="line">
      <label class="typography-headline2">
        {{ 'profile.personal_settings_page.address.title' | translate }}
      </label>
      <app-input
        class="typography-headline2"
        typographyClass="typography-paragraph-dark"
        formControlName="street"
        [invalid]="
          addressForm.get('street')?.hasError('required') &&
          !addressForm.get('street')?.pristine
        "
        [placeholder]="
          'profile.personal_settings_page.address.form_fields.place_holder_street'
            | translate
        "
      ></app-input>
    </div>
    <div class="line">
      <label class="typography-headline2">{{
        'profile.personal_settings_page.address.form_fields.postal' | translate
      }}</label>
      <app-input
        class="typography-headline2"
        typographyClass="typography-paragraph-dark"
        formControlName="zip_code"
        [placeholder]="
          'profile.personal_settings_page.address.form_fields.place_holder_postal'
            | translate
        "
        [invalid]="
          addressForm.get('zip_code')?.hasError('minlength') &&
          !addressForm.get('zip_code')?.pristine
        "
      ></app-input>
    </div>
  </div>
</div>
