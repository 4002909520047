import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class AppDividerComponent {
  @Input() color = '#E3E3E3';

  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
}
