import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { UploadFileService } from 'src/app/shared/services/upload-file.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() userDetails!: UserModel;
  @Input() creatorDetails!: CreatorModel;
  imageInfos?: Observable<any>;

  constructor(private uploadService: UploadFileService) {}

  ngOnChanges(): void {
    this.imageInfos = this.uploadService.getFiles();
  }
}
