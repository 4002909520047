import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnInit {
  token: string | undefined;
  @Input() invalid!: boolean | null;
  @Output() userToken = new EventEmitter<string>();

  ngOnInit(): void {
    this.token = undefined;
  }

  public handleConfirmCaptcha(event: string) {
    this.userToken.emit(event);
  }
}
