import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FiltersComponent } from './components/filters/filters.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { SwiperModule } from 'swiper/angular';
import { GroupByComponent } from './components/group-by/group-by.component';
import { StatisticsTableComponent } from './components/statistics-table/statistics-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StatisticsComponent } from './statistics.component';
import { MyProfitModule } from '../my-profit/my-profit.module';

@NgModule({
  declarations: [
    StatisticsComponent,
    StatisticsTableComponent,
    GroupByComponent,
    FiltersComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    MyProfitModule,
  ],
})
export class StatisticsModule {}
