import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FullScriptApiService } from '../../sevice/full-script-api.service';
import { RowsModel } from '../../model/rows.model';
import { combineLatest } from 'rxjs';
import { SelectionType } from '@swimlane/ngx-datatable';
import { FiltersService } from '../../../statistics/services/filters.service';
import { filtersListsKey } from '../../../statistics/types/filters';
import { Router } from '@angular/router';
import { ColumnsEnum } from '../../enums/columns.enum';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { AddFullScriptService } from '../../../creating-flows/add-full-script/service/add-full-script.service';

@Component({
  selector: 'app-full-script-table',
  templateUrl: './full-script-table.component.html',
  styleUrls: ['./full-script-table.component.scss'],
})
export class FullScriptTableComponent implements OnInit, AfterViewInit {
  @ViewChild('actions') actions!: TemplateRef<any>;
  isRowsExist = false;
  limit = 0;
  rows!: RowsModel[];
  numOfPages!: number;
  SelectionType = SelectionType;

  constructor(
    public fullScriptService: FullScriptApiService,
    private cdr: ChangeDetectorRef,
    private filtersService: FiltersService,
    private router: Router,
    private generalService: GeneralService,
    private addFullScriptService: AddFullScriptService
  ) {}

  async ngAfterViewInit() {
    combineLatest([
      this.fullScriptService.chosenLimit$,
      this.fullScriptService.numOfPages$,
      this.fullScriptService.columns$,
      this.fullScriptService.rows$,
    ]).subscribe(([chosenLimit, numOfPages, columns, rows]) => {
      this.rows = rows;
      this.limit = chosenLimit;
      this.numOfPages = numOfPages;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.fullScriptService.rows$.subscribe((rows: RowsModel[]) => {
      this.isRowsExist = rows.length > 0;
      this.updateColumns();
    });
  }

  updateColumns() {
    this.fullScriptService.updateColumnsData(
      [
        {
          prop: ColumnsEnum.ACTIONS,
          cellTemplate: this.actions,
        },
      ],

      {}
    );
  }

  editFullScript(row: RowsModel) {
    const contentData = {
      name: row.name,
      site_url: row.site_url,
      showFirstComponent: false,
      editMode: true,
      vertical: row.vertical,
      typeList: row.typeList,
      urls: row.urls,
      site_id: row.site_id,
      id: row.id,
    };
    this.addFullScriptService.openAddFullScriptComponent(contentData);
  }

  copyContentLocker(row: RowsModel) {
    const contentData = {
      copyMode: true,
      id: row.id,
    };
    this.addFullScriptService.openAddFullScriptComponent(contentData);
  }

  getVerticalValue(vertical: number) {
    return this.generalService.getVertical(vertical);
  }

  navigateToStatistics(row: any) {
    this.filtersService.navigateFromPage = true;

    this.filtersService.navigateFrom(
      [
        {
          key: row.id || '',
          value: row.name || '',
        },
      ],
      filtersListsKey.tags
    );

    this.router.navigate(['/statistics']);
  }

  changePage(event: any) {
    this.fullScriptService.currentPage = event.page;
    return true;
  }

  searchElement(filterTerm: string) {
    this.fullScriptService.filterTable(filterTerm);
  }
}
