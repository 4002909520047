import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationSlideService {
  public notificationSlideToggle = new Subject<void>();
  public notificationSlideToggle$ = this.notificationSlideToggle.asObservable();

  public notificationSlideOpen = new BehaviorSubject<boolean>(false);
  public notificationSlideOpen$ = this.notificationSlideOpen.asObservable();

  toggleSidenav = () => {
    this.notificationSlideToggle.next();
  };
}
