import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';

@Injectable({
  providedIn: 'root',
})
export class ExportToCsvService {
  export(data: any, columns: any[], title: string) {
    const headers = columns.map(column => column.name);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: title,
      useTextFile: false,
      useBom: true,
      headers: headers,  // Using the extracted headers
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }
}
