<app-card class="table-container">
  <div class="title-container typography-heading4-bold">
    <app-icon name="route"></app-icon>
    <span class="title">{{ 'full_script_table.title' | translate }}</span>
  </div>
  <app-input
    class="input"
    prefix="search"
    [placeholder]="'full_script_table.search_placeholder' | translate"
    (inputChanged)="searchElement($event)"
  ></app-input>
  <ngx-datatable
    #table
    *ngIf="isRowsExist; else emptyState"
    class="table typography-body4-thin"
    [rows]="(fullScriptService.rows$ | async) || []"
    [columns]="(fullScriptService.columns$ | async) || []"
    [headerHeight]="50"
    [footerHeight]="100"
    rowHeight="auto"
    columnMode="force"
    [selectAllRowsOnPage]="false"
    [summaryRow]="false"
    [limit]="limit"
    [offset]="fullScriptService.currentPage - 1"
  >
    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div class="footer-container">
          <div class="pager-container">
            <app-button
              [ngClass]="{ 'icon-disabled-style': curPage <= 1 }"
              color="naked"
              (buttonClick)="pager.prevPage()"
            >
              <app-icon start name="navigation_left"></app-icon>
            </app-button>
            <datatable-pager
              #pager
              [page]="fullScriptService.currentPage"
              [size]="pageSize"
              [count]="rowCount"
              (change)="changePage($event) && table.onFooterPage($event)"
            ></datatable-pager>
            <app-button
              [ngClass]="{
                'icon-disabled-style': curPage === numOfPages
              }"
              color="naked"
              (buttonClick)="pager.nextPage()"
            >
              <app-icon start name="navigation_right"></app-icon>
            </app-button>
          </div>
          <ng-select
            class="pagination"
            [ngModel]="limit"
            (ngModelChange)="fullScriptService.setChosenLimit($event)"
            [items]="fullScriptService.limitOptions"
            appendTo="body"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</app-card>

<ng-template
  #actions
  let-value="value"
  let-row="row"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <div class="cell">
    <div (click)="editFullScript(row)" class="action-button">
      <app-icon size="lg" name="edit"></app-icon>
    </div>
    <div (click)="navigateToStatistics(row)" class="action-button">
      <app-icon size="lg" name="analytics"></app-icon>
    </div>
    <div (click)="copyContentLocker(row)" class="action-button">
      <img size="lg" src="/assets/svg/copy_code.svg" />
    </div>
  </div>
</ng-template>

<ng-template #emptyState>
  <app-empty-state></app-empty-state>
</ng-template>
