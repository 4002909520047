<div class="password">
  <div class="header">
    <span class="typography-heading3-bold title">
      {{ 'profile.password.title' | translate }}
    </span>
    <span class="typography-heading4-bold subtitle">
      {{ 'profile.password.subtitle' | translate }}
    </span>
    <span class="typography-headline2">
      {{ 'profile.password.description' | translate }}
    </span>
  </div>

  <div class="form" [formGroup]="passwordForm">
    <div class="line">
      <label class="typography-headline2">{{
        'profile.password.old_password' | translate
      }}</label>
      <app-input
        placeholder="{{ 'profile.password.old_password' | translate }}"
        [type]="isPasswordVisible.old ? 'text' : 'password'"
        formControlName="oldPassword"
      ></app-input>
      <button class="emoji-btn" (click)="togglePasswordVisibility('old')">
        <app-icon class="icon" [name]="passwordIcon['old']"></app-icon>
      </button>
    </div>
    <div class="line">
      <label class="typography-headline2">{{
        'profile.password.new_password' | translate
      }}</label>
      <app-input
        placeholder="{{ 'profile.password.new_password' | translate }}"
        [type]="isPasswordVisible.new ? 'text' : 'password'"
        formControlName="newPassword"
      ></app-input>
      <button class="emoji-btn" (click)="togglePasswordVisibility('new')">
        <app-icon class="icon" [name]="passwordIcon['new']"></app-icon>
      </button>
    </div>
    <div class="column">
      <div class="line">
        <label class="typography-headline2">{{
          'profile.password.confirm_password' | translate
        }}</label>
        <div>
          <app-input
            placeholder="{{ 'profile.password.confirm_password' | translate }}"
            [type]="isPasswordVisible.confirm ? 'text' : 'password'"
            formControlName="confirmPassword"
          ></app-input>
        </div>

        <button class="emoji-btn" (click)="togglePasswordVisibility('confirm')">
          <app-icon class="icon" [name]="passwordIcon['confirm']"></app-icon>
        </button>
      </div>
      <p
        class="error typography-footnote2-thin"
        *ngIf="
          passwordForm.get('confirmPassword')?.hasError('passwordsNotMatch') &&
          passwordForm.get('confirmPassword')?.dirty
        "
      >
        {{ 'profile.password.error' | translate }}
      </p>
    </div>
  </div>
  <div class="change-btn">
    <app-button
      [disabled]="!passwordForm.valid || isButtonDisabled"
      (click)="onSubmit()"
      >{{ 'profile.password.submit' | translate }}</app-button
    >
  </div>
</div>
