<span
  class="radio-circle"
  [class.disabled]="disabled"
  [class.checked]="checked"
></span>
<span
  *ngIf="label.length"
  class="radio-label typography-text {{ customStyle }}"
>
  {{ label }}
</span>
