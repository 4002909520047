import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferralProgramService } from './service/referral-program.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-referral-program',
  templateUrl: './referral-program.component.html',
  styleUrls: ['./referral-program.component.scss'],
})
export class ReferralProgramComponent implements OnInit, OnDestroy {
  isGenerate = true;
  //TODO: remove it when advertising material is active
  isAdvertisingMaterialDisabled = true;
  constructor(
    private referralService: ReferralProgramService,
    private loaderService: LoaderService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.loaderService.show();
    setTimeout(() => {
      this.isGenerate = this.generalService.siteReferral !== null;

      this.loaderService.hide();
    }, 2000);
    this.referralService.getDataTable().subscribe();
  }

  ngOnDestroy() {
    this.referralService.currentPage = 1;
  }

  generate() {
    this.isGenerate = true;
    this.referralService.generateCode().subscribe();
  }

  getCode() {
    if (!this.isGenerate || !this.generalService.siteReferral) {
      return '';
    } else {
      return `https://lootlabs.gg/#formStart?rpid=${this.generalService.siteReferral}`;
    }
  }

  searchElement(filterTerm: string) {
    this.referralService.filterTable(filterTerm);
  }
}
