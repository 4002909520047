import { Component, Input, OnChanges } from '@angular/core';
import { ThemeEnum } from '../../enunms/theme.enum';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnChanges {
  @Input() title!: string;
  @Input() theme!: string;
  @Input() social_links!: FormGroup;
  @Input() number_of_tasks!: number;
  @Input() backgroundForm!: string;

  background!: string;
  creator_name!: string;
  creator_picture!: string;

  constructor(private generalService: GeneralService) {}

  ngOnChanges(): void {
    this.getCreatorDetails();
    this.getBackground();
  }

  getCreatorDetails() {
    this.generalService.creator$.subscribe((creator) => {
      this.creator_name = creator?.creator_name || '';
      this.creator_picture =
        creator?.creator_picture || './assets/png/preview_logo.png';
    });
  }
  getBackground() {
    switch (this.theme) {
      case ThemeEnum.CLASSIC:
        this.background = './assets/png/classic.jpeg';
        break;

      case ThemeEnum.GTA:
        this.background = './assets/png/gta.jpeg';
        break;

      case ThemeEnum.MINECRAFT:
        this.background = './assets/png/minecraft.jpeg';
        break;

      case ThemeEnum.SIMS:
        this.background = './assets/png/sims.jpeg';
        break;

      case ThemeEnum.SPACE:
        this.background = './assets/png/space.jpeg';
        break;

      default:
        break;
    }
  }
}
