<app-login-modal [formGroup]="forgotPasswordGroup">
  <div class="titles-container">
    <span class="typography-heading3-thin title">
      {{ 'forgot_password.title' | translate }}
    </span>
    <span class="typography-body3-thin subtitle">
      {{ 'forgot_password.subtitle' | translate }}
    </span>
  </div>
  <div class="input-fields-container" [formGroup]="forgotPasswordGroup">
    <span class="typography-body4-thin input-label">
      {{ 'forgot_password.email_label' | translate }}
    </span>
    <app-input
      [type]="'email'"
      class="input-field"
      [errorMessage]="
        forgotPasswordGroup.controls['email'].errors &&
        forgotPasswordGroup.controls['email'].touched
          ? 'Enter valid email address'
          : null
      "
      [invalid]="
        forgotPasswordGroup.controls['email'].errors &&
        forgotPasswordGroup.controls['email'].touched
      "
      (inputFocusOut)="forgotPasswordGroup.controls['email'].markAsTouched()"
      formControlName="email"
      [placeholder]="'forgot_password.email_place_holder' | translate"
    ></app-input>
  </div>
  <div class="buttons-container">
    <app-button
      class="log-in-button full-width-button"
      (buttonClick)="submit()"
      [fullWidth]="true"
    >
      {{ 'forgot_password.main_button' | translate }}
    </app-button>
    <app-button
      class="cancel-button full-width-button"
      [fullWidth]="true"
      (buttonClick)="cancel()"
      [color]="isDesktop ? 'naked' : 'white'"
    >
      {{ 'forgot_password.cancel_button' | translate }}
    </app-button>
  </div>
</app-login-modal>
