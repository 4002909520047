import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardTableComponent } from './components/dashboard-table/dashboard-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyProfitModule } from '../my-profit/my-profit.module';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';

@NgModule({
  declarations: [DashboardComponent, DashboardTableComponent, DeletePopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MyProfitModule,
  ],
})
export class DashboardModule {}
