import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListTypeEnum } from '../../enums/type-list.enum';
import { Regex } from 'src/app/shared/validators/regex';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { AddFullScriptService } from '../../service/add-full-script.service';
import { FullScriptBody } from '../../constants/body-full-script';

@Component({
  selector: 'app-wl-bl-step',
  templateUrl: './wl-bl-step.component.html',
  styleUrls: ['./wl-bl-step.component.scss'],
})
export class WlBlStepComponent implements OnInit {
  @Input() wlBlForm!: FormGroup;
  @Output() continueStep2 = new EventEmitter<any>();
  @Input() setupLinkForm!: FormGroup;
  @Input() isEditMode!: boolean;
  @Input() urls: string[] = [];
  listEnumType = ListTypeEnum;
  isChanged = false;
  isSubmit = false;

  constructor(
    private loaderService: LoaderService,
    private addFullScriptService: AddFullScriptService
  ) {}

  ngOnInit(): void {
    if (this.isEditMode && this.urls.length > 0) {
      this.urls.forEach((url) => {
        this.addUrl(url);
      });
    }

    this.setupLinkForm.valueChanges.subscribe(() => {
      if (this.isSubmit || this.isEditMode) {
        this.isChanged = true;
      }
    });

    this.wlBlForm.valueChanges.subscribe(() => {
      if (this.isSubmit || this.isEditMode) {
        this.isChanged = true;
      }
    });
  }

  get Urls() {
    return this.wlBlForm.get('url') as FormArray;
  }

  addUrl(url: null | string = null) {
    const urlControl = new FormControl(url ? url : null, [
      Validators.pattern(Regex.ValidateUrl),
    ]);

    (this.wlBlForm.get('url') as FormArray).push(urlControl);
  }

  deleteUrl(urlIndex: number) {
    this.Urls.removeAt(urlIndex);
  }

  next() {
    this.isSubmit = true;
    let body: FullScriptBody = {
      external_links_blacklist: [],
      external_links_whitelist: [],
      name: '',
      url: '',
      vertical: 0,
    };

    if (this.listEnumType.BLACK_LIST) {
      const blacklistUrls =
        this.wlBlForm
          .get('url')
          ?.value.filter((value: any) => value !== null) || [];
      body = {
        ...this.setupLinkForm.value,
        external_links_blacklist: Array.from(new Set(blacklistUrls)),
        external_links_whitelist: [],
      };
    } else {
      const whitelistUrls =
        this.wlBlForm
          .get('url')
          ?.value.filter((value: any) => value !== null) || [];
      body = {
        ...this.setupLinkForm.value,
        external_links_whitelist: Array.from(new Set(whitelistUrls)),
        external_links_blacklist: [],
      };
    }

    this.loaderService.show();
    if (this.isEditMode || this.addFullScriptService.isCreatedEdit) {
      this.addFullScriptService.editFullPageContentLocker(body);
    } else {
      this.addFullScriptService.createFullPageContentLocker(body);
    }

    this.continueStep2.emit();
  }
}
