import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() userDetails!: UserModel;

  constructor(public accountService: AccountService) {}

  ngOnChanges(): void {
    this.generalInfoForm
      .get('signup_contact_type')
      ?.valueChanges.subscribe((val) => {
        if (val) {
          this.generalInfoForm.get('signup_contact_id')?.enable();
        } else {
          this.generalInfoForm.get('signup_contact_id')?.disable();
        }

        this.generalInfoForm.get('signup_contact_id')?.reset();
      });
  }

  get generalInfoForm() {
    return this.form.get('general_info') as FormGroup;
  }
}
