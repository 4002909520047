<div class="group-by">
  <app-group-by-button
    *ngIf="isDesktop; else isMobile"
    [options]="typeButtons"
    (toggleChange)="groupByChanges($event)"
  >
  </app-group-by-button>
</div>

<ng-template #isMobile>
  <app-button (buttonClick)="openBottomSheet()" color="naked">
    <div class="typography-heading4-bold color-white">
      {{ 'statistics.group_by.title' | translate }}
      {{ picker | translate }}
    </div>
    <app-icon
      [class.clicked]="open"
      class="icon"
      name="chevron_down"
    ></app-icon>
  </app-button>
</ng-template>

<ng-template #contentTemplate>
  <div class="bottom">
    <div class="header">
      <app-icon
        (click)="closeBottomSheet()"
        class="close-icon"
        name="close"
      ></app-icon>
      <div class="title typography-heading3-bold">
        {{ 'statistics.group_by.title' | translate }}
      </div>
    </div>
    <app-radio-group class="radio-buttons-group" [groupName]="'choise'">
      <app-radio-button
        *ngFor="let preset of typeButtons"
        class="radio-button"
        value="{{ preset.label }}"
        [checked]="preset.label === picker"
        [class.checked]="preset.label === picker"
        (click)="onClickStatus(preset)"
      >
        <ng-template let-checked="checked" let-disabled="disabled">
          <div
            class="background"
            [class.checked]="checked"
            [class.disabled]="disabled"
          >
            <div class="label">
              <app-radio-circle class="circle" [checked]="checked">
              </app-radio-circle>
              <span class="typography-text">
                {{ preset.label | translate }}</span
              >
            </div>
          </div>
        </ng-template>
      </app-radio-button>
    </app-radio-group>
  </div>
</ng-template>
