import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from 'src/app';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailSendComponent } from './components/email-send/email-send.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginFacade } from './store/login-state/login.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { USER } from './store/login-state/login.state';
import { reducer } from './store/login-state/login.reducer';
import { LoginEffects } from './store/login-state/login.effect';

@NgModule({
  declarations: [
    LoginComponent,
    LoginModalComponent,
    CaptchaComponent,
    ForgotPasswordComponent,
    EmailSendComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    StoreModule.forFeature(USER, reducer),
    EffectsModule.forFeature([LoginEffects]),
  ],
  providers: [
    LoginFacade,
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
