import { Component, Input, OnInit } from '@angular/core';
import {
  NotificationModel,
  NotificationRedirect,
  NotificationType,
} from '../models/notifications.model';
import { AppIcon } from '../../icon/icons';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from '../services/notifications.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { NotificationSlideService } from '../services/notification-slide.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../loader/service/loader.service';
import { ProfileService } from 'src/app/pages/creator/profile/service/profile.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SideNavSectionsEnum } from 'src/app/pages/creator/profile/enums/side_nav_section';
import { RedirectRouteEnum } from '../enum/notification.enum';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notificationData!: NotificationModel;
  public notificationIcon!: AppIcon;
  public notificationSubject!: Array<string>;
  private notificationNavigation!: NotificationRedirect;
  public isDesktop!: boolean;
  public notificationTypeEnum = NotificationType;

  private timestamp = new BehaviorSubject<Date | null>(null);
  public timestamp$ = this.timestamp.asObservable();
  constructor(
    private notificationsService: NotificationsService,
    private generalService: GeneralService,
    private notificationsSlideService: NotificationSlideService,
    private loaderService: LoaderService,
    private router: Router,
    private profileService: ProfileService,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
    this.timestamp.next(new Date(this.notificationData.report_time));

    this.notificationNavigation = this.notificationNavigation =
      this.notificationsService.getNavigation(
        this.notificationData.subject as keyof typeof RedirectRouteEnum,
        this.notificationData.original_id?.toString()
      );
  }

  redirect() {
    if (this.isDesktop) {
      this.notificationsSlideService.toggleSidenav();
    } else {
      this.bottomSheet.dismiss();
    }
    this.notificationsService
      .updateNotificationSeen(this.notificationData.id)
      .subscribe(() => {
        this.notificationsService.getNotifications();
        this.loaderService.hide();
      });

    this.loaderService.show();
    let route = this.notificationNavigation.route || '';

    switch (this.notificationData.subject) {
      case NotificationType.BulkLink:
      case NotificationType.ContentJs:
      case NotificationType.ContentLocker:
        this.router.navigate([route]);

        break;

      case NotificationType.PersonalDetails:
        this.profileService.setTab(SideNavSectionsEnum.PERSONAL_SETTINGS);
        this.router.navigate([route]);

        break;

      case NotificationType.AffiliateLink:
        this.profileService.setTab(SideNavSectionsEnum.API_KEY);
        this.router.navigate([route]);

        break;
    }
  }
}
