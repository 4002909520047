import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @Input() color = '#FFFFFF';

  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
}
