import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../store/login-state/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { snackbarObjectSucceed } from './ snackbar/snackbar';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordGroup!: FormGroup;
  message!: string;
  emailInvalid = false;
  public isDesktop!: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private snackbarService: SnackbarService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
    this.initForm();
  }

  initForm() {
    this.forgotPasswordGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    this.loginService
      .forgetPassword(this.forgotPasswordGroup.controls['email'].value)
      .subscribe(
        (res: any) => {
          this.snackbarService.showSnackbar(snackbarObjectSucceed);
          if (this.forgotPasswordGroup.valid) {
            this.router.navigate(['email-send']);
            this.router
              .navigate([
                '/email-send',
                { email: this.forgotPasswordGroup.controls['email'].value },
              ])
              .then();
          }
        },
        (error: HttpErrorResponse) => {
          this.forgotPasswordGroup
            .get('email')
            ?.setErrors({ customError: 'Your custom error message' });
          this.message = error.error.message;
          //TO DO: add error function in general service
        }
      );
  }

  cancel() {
    this.router.navigate(['login']).then();
  }
}
