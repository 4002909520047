import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { AccountTypeEnum } from '../shared/enums/account-type.enum';
import { ProfileComponent } from './creator/profile/profile.component';
import { HasDetailsGuard } from '../hasDetails.guard';
import { FullScriptApiComponent } from './creator/full-script-api/full-script-api.component';
import { ReferralProgramComponent } from './creator/referral-program/referral-program.component';
import { DashboardComponent } from './creator/dashboard/dashboard.component';
import { AddSingleComponent } from './creator/creating-flows/add-single/add-single.component';
import { StatisticsComponent } from './creator/statistics/statistics.component';
import { PaymentComponent } from './creator/payment/payment.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, HasDetailsGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [AuthGuard, HasDetailsGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'full-script-api',
    component: FullScriptApiComponent,
    canActivate: [AuthGuard, HasDetailsGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard, HasDetailsGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'referral-program',
    component: ReferralProgramComponent,
    canActivate: [AuthGuard, HasDetailsGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },
  {
    path: 'single-link',
    component: AddSingleComponent,
    canActivate: [AuthGuard],
    data: { role: [AccountTypeEnum.CREATOR, AccountTypeEnum.ADMIN] },
  },

  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
