import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginRequestModel } from './models/login-request.model';
import { AppIcon } from 'src/app/shared/components/icon/icons';
import { APP_CONFIG } from 'src/app';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { LoginService } from './store/login-state/login.service';
import { Login } from './store/login-state/login.action';
import { UserModel } from './store/models/user.model';
import { Observable, Subject } from 'rxjs';
import { LoginFacade } from './store/login-state/login.facade';
import { LoginState } from './store/login-state/login.state';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { map, takeUntil } from 'rxjs/operators';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  termsOfUseLink!: string;
  privacyPolicyLink!: string;
  loginGroup!: FormGroup;
  isPasswordVisible = false;
  passwordIcon: AppIcon = 'hide_eye';
  error!: string | null;
  loginError = '';
  user$!: Observable<UserModel | null>;
  error$!: Observable<string | null>;
  private loginRequestBody: LoginRequestModel = {
    username: null,
    password: null,
    captcha_token: null,
  };
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    @Inject(APP_CONFIG) private appConfig: any,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private store: Store,
    private loginService: LoginService,
    private router: Router,
    private loginFacade: LoginFacade,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.termsOfUseLink = this.appConfig.links.termsOfUseLink;
    this.privacyPolicyLink = this.appConfig.links.privacyPolicyLink;
    this.error$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error: string | null | undefined) => {
        if (error !== undefined) {
          this.error = error;
        } else {
          this.error = null;
        }
      });
  }

  initForm() {
    this.loginGroup = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      captcha_token: [null, Validators.required],
    });

    this.user$ = this.loginFacade.user$.pipe(
      map((state: LoginState) => state?.user)
    );

    this.error$ = this.loginFacade.user$.pipe(
      map((state: LoginState) => state?.error)
    );

    this.loginService.loginErr$.subscribe((val) => {
      this.loginError = val;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    if (this.isPasswordVisible) {
      this.passwordIcon = 'visibility';
    } else {
      this.passwordIcon = 'hide_eye';
    }
  }

  errorMessage(value: string): string | null {
    return this.isFieldValid(value)
      ? this.translateService.instant(`login.error_messages.${value}`)
      : null;
  }

  setCaptchaToken(event: string) {
    if (event) {
      this.loginRequestBody.captcha_token = event;

      this.loginGroup.get('captcha_token')?.setValue(event);
    }
  }

  isFieldValid(value: string): boolean {
    return (
      !!this.loginGroup.controls[value].errors &&
      this.loginGroup.controls[value].touched
    );
  }

  updateLoginRequestBody(formValue: any) {
    this.loginRequestBody = {
      ...this.loginRequestBody,
      username: formValue.email,
      password: formValue.password,
      captcha_token: formValue.captcha_token,
    };
  }

  checkSwitchStatus() {
    let status = localStorage.getItem('switchStatus')
    return status === 'true';
  }

  submit() {
    Object.keys(this.loginGroup.controls).forEach((field) => {
      const control = this.loginGroup.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.loginGroup.valid && this.loginRequestBody.captcha_token !== '') {
      this.loaderService.show();
      this.store.dispatch(Login({ credentials: this.loginRequestBody }));
      setTimeout(() => {
        this.generalService.getCreator().subscribe((res) => {
          if (res.message.creator_name && res.message.creator_picture) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/profile']);
          }
        });
      }, 4000);
    }
  }
}
