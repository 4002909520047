import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { PaymentService } from '../../services/payment.service';
import { combineLatest } from 'rxjs';
import { ColumnsEnum } from '../../enums/columns.enum';
import { formatDateRange } from 'src/app/shared/helper/helper';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss'],
})
export class PaymentTableComponent implements OnInit {
  limit = 0;
  numOfPages!: number;
  thereIsData!: boolean;
  @ViewChild('paymentMethod') paymentMethod!: TemplateRef<any>;

  constructor(
    public paymentService: PaymentService,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.paymentService.rows$.subscribe((rows: any) => {
      this.thereIsData = rows.length > 0;
      this.updateColumn();
    });
  }

  getDateSelected(event: { startDate: any; endDate: any }) {
    const dateRange = formatDateRange(event);
    this.paymentService.updateFilters({
      from_date: dateRange?.from_date,
      to_date: dateRange?.to_date,
    });

    this.paymentService.getDataTable().subscribe();
  }

  async ngAfterViewInit() {
    combineLatest([
      this.paymentService.chosenLimit$,
      this.paymentService.numOfPages$,
    ]).subscribe(([limit, numPages]) => {
      this.limit = limit;
      this.numOfPages = numPages;
      this.cdr.detectChanges();
    });
  }

  changePage(event: any) {
    this.paymentService.currentPage = event.page;
    return true;
  }

  updateColumn() {
    this.paymentService.updateColumnsData(
      [
        {
          prop: ColumnsEnum.PAYMENT_TYPE,
          cellTemplate: this.paymentMethod,
          checkboxable: true,
          headerCheckboxable: true,
        },
      ],
      {}
    );
  }

  getPaymentValue(payment: string) {
    return this.generalService.getPaymentValue(payment);
  }
}
