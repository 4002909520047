import { Inject, Injectable } from '@angular/core';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { AddMultipleComponent } from '../add-multiple.component';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { APP_CONFIG } from 'src/app';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as saveAs from 'file-saver';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import {
  snackbarFailedCreateMultipleContentLocker,
  snackbarFailedCsvFailed,
  snackbarFailedDownloadFile,
  snackbarSuccessCreateMultipleContentLocker,
  snackbarSuccessDownloadFile,
} from '../snackbar/snackbar';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';
import { FiltersService } from '../../../statistics/services/filters.service';
@Injectable({
  providedIn: 'root',
})
export class AddMultipleService {
  private readonly apiUrl: string;
  short_link!: string;
  short_id!: number;
  siteId = 0;
  tagId = 0;
  isCreatedEdit = false;

  constructor(
    private overlay: OverlayService,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private http: HttpClient,
    private generalService: GeneralService,
    private snackbarService: SnackbarService,
    private loaderService: LoaderService,
    private dashboardService: DashboardService,
    private router: Router,
    private notificationService: NotificationsService,
    private filterService: FiltersService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  openAddSiteComponent<R = any, T = any>(data: T): MyOverlayRef<R> {
    const ref = this.overlay.open(AddMultipleComponent, data);
    ref.afterClosed$.subscribe(() => {
      if (this.isCreatedEdit) {
        this.router.navigate(['/dashboard']);
        this.isCreatedEdit = false;
      }
    });
    return ref;
  }

  downloadFile() {
    const url =
      this.apiUrl + this.appConfig.actions.download_content_locker_file;
    const headers = this.loginService.TokenFromStorage;

    return this.http.get(url, { headers, responseType: 'blob' }).subscribe(
      (data: Blob) => {
        const filename = 'example.csv';
        saveAs(data, filename);
        this.snackbarService.showSnackbar(snackbarSuccessDownloadFile);
      },
      catchError((error: any) => {
        return this.generalService.catchError(
          snackbarFailedDownloadFile,
          error
        );
      })
    );
  }

  createMultipleContentLocker(body: {
    file: File;
    background: string;
    sub_id: string;
    short_name: string;
    theme_id: string;
    number_of_tasks: string;
    tier_id: string;
  }) {
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('background', body.background);
    formData.append('sub_id', body.sub_id);
    formData.append('short_name', body.short_name);
    formData.append('theme_id', body.theme_id);
    formData.append('number_of_tasks', body.number_of_tasks);
    formData.append('tier_id', body.tier_id);

    const headers = this.loginService.TokenFromStorage;
    headers.set('Content-Type', 'multipart/form-data');
    const url =
      this.apiUrl + this.appConfig.actions.content_locker + '?is_bulk=1';
    return this.http.post(url, formData, { headers }).pipe(
      tap((res: any) => {
        this.loaderService.hide();
        this.isCreatedEdit = true;
        this.short_id = res.message.short_id;
        this.filterService.getFilters(false);
        this.snackbarService.showSnackbar(
          snackbarSuccessCreateMultipleContentLocker
        );
        const notification = {
          notificationType: NotificationType.BulkLink,
          id: this.short_id,
        };
        const newNotification =
          this.notificationService.createNotificationObject(notification);

        this.notificationService
          .createNotification(newNotification)
          .subscribe(() => {
            this.notificationService.getNotifications();
          });
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error.message === 'Error in csv file') {
          return this.generalService.catchError(snackbarFailedCsvFailed, error);
        }
        this.loaderService.hide();
        return this.generalService.catchError(
          snackbarFailedCreateMultipleContentLocker,
          error
        );
      })
    );
  }
}
