export interface Filter {
  from_date?: string;
  to_date?: string;
  countries_list?: string[];
  tags?: number[] | string[];
  links?: number[] | string[];
  ad_formats?: number[] | string[];
  group_by?: string;
  placement_list?: number[] | string[];
}

export interface FilterList<T> {
  title: string;
  key: keyof Filter;
  data: { key: keyof T; value: T }[];
  multiple_search: boolean;
  groupBy: string;
  placeholder: string;
}

export interface SelectedFilterOption {
  name: string;
  value: number;
}

export interface SelectedFilterObject {
  [key: string]: SelectedFilterOption[];
}

export enum filtersListsKey {
  tags = 'tags',
  adFormats = 'ad_formats',
  countries = 'countries_list',
  links = 'links',
  placement = 'placement_list',
}
