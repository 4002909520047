import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay.component';
import { ScrollService } from '../../services/overlay/scroll.service';

@NgModule({
  declarations: [OverlayComponent],
  imports: [CommonModule],
  providers: [ScrollService],
  exports: [OverlayComponent],
})
export class OverlayModule {}
