import { Component, Input } from '@angular/core';
import { AddSingleService } from '../../../add-single/service/add-single.service';
import { AddMultipleService } from '../../../add-multiple/service/add-multiple.service';
import { AddFullScriptService } from '../../../add-full-script/service/add-full-script.service';

@Component({
  selector: 'app-creating-card',
  templateUrl: './creating-card.component.html',
  styleUrls: ['./creating-card.component.scss'],
})
export class CreatingCardComponent {
  @Input() icon!: string;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() color = '';
  @Input() type = '';

  constructor(
    private addSingleService: AddSingleService,
    private addMultipleService: AddMultipleService,
    private fullScriptService: AddFullScriptService
  ) {}

  onClick(type: string) {
    switch (type) {
      case 'Single':
        this.addSingleService.openSingleContentLockerComponent({
          showFirstComponent: true,
          editMode: false,
        });
        break;

      case 'Multiple':
        this.addMultipleService.openAddSiteComponent({
          showFirstComponent: true,
          editMode: false,
        });
        break;

      case 'Full_script':
        this.fullScriptService.openAddFullScriptComponent({
          showFirstComponent: true,
          editMode: false,
        });
        break;

      default:
        break;
    }
  }
}
