import { Component } from '@angular/core';
import { formatDateRange } from 'src/app/shared/helper/helper';
import { PaymentService } from './services/payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {
  constructor(private paymentService: PaymentService) {}

  ngOnDestroy() {
    this.paymentService.currentPage = 1;
  }

  getDateSelected(event: { startDate: any; endDate: any }) {
    const dateRange = formatDateRange(event);
    this.paymentService.updateFilters({
      from_date: dateRange?.from_date,
      to_date: dateRange?.to_date,
    });

    this.paymentService.getDataTable().subscribe();
  }
}
