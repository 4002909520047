import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { StatisticsService } from '../../services/statistics.service';
import {
  DataTablePagerComponent,
  DatatableComponent,
} from '@swimlane/ngx-datatable';
import { combineLatest } from 'rxjs';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { DataTableModel } from '../../model/data-table.model';
import { columns } from '../../constants/columns';
import { reduceColumn } from 'src/app/shared/helper/helper';

@Component({
  selector: 'app-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss'],
})
export class StatisticsTableComponent implements OnInit, OnDestroy {
  columns = columns;
  limit = 50;
  numOfPages!: number;
  isDesktop = true;
  data: DataTableModel | null = null;
  rows!: any;
  numOfRows = 0;

  @ViewChild('table') table!: DatatableComponent;
  @ViewChild('pager') pager!: DataTablePagerComponent;
  @ViewChild('summaryLine') summaryLine!: TemplateRef<any>;

  constructor(
    public statisticsService: StatisticsService,
    public generalService: GeneralService
  ) {}
  ngOnDestroy(): void {
    this.statisticsService.data$.next(null);
  }

  currencyComparator(a: any, b: any): number {
    // Remove the dollar sign and convert to number
    const numA = parseFloat(a.replace(/[$,]/g, ''));
    const numB = parseFloat(b.replace(/[$,]/g, ''));

    if (numA < numB) {
      return -1;
    } else if (numA > numB) {
      return 1;
    } else {
      return 0;
    }
  }


  ngOnInit(): void {
    combineLatest([
      this.statisticsService.columns$,
      this.statisticsService.numOfPages$,
      this.statisticsService.rows$,
      this.statisticsService.chosenLimit$,
      this.generalService.isDesktop$,
      this.statisticsService.data$,
    ]).subscribe(([columns, numOfPage, rows, limit, isDesktop, data]) => {
      this.data = data;
      this.isDesktop = isDesktop;
      this.columns = columns;
      this.numOfPages = numOfPage;
      this.numOfRows = rows.length;
      this.rows = rows;
      this.limit = limit;
    });
  }

  ngAfterViewInit(): void {
    this.statisticsService.data$.subscribe((r) => {
      this.updateColumn();
    });
  }

  updateColumn() {
    this.statisticsService.updateColumnsFromServer();
  }
  changePage(event: any) {
    this.statisticsService.currentPage = event.page;

    return true;
  }

  getTooltip(columnName?: string) {
    if (columnName === 'Total Impressions') {
      return 'statistics.tooltips.total_impressions';
    }
    if (columnName === 'CPM') {
      return 'statistics.tooltips.cpm';
    }
    if (columnName === 'Sessions CPM') {
      return 'statistics.tooltips.sessions_cpm';
    }
    if (columnName === 'Total Sessions') {
      return 'statistics.tooltips.total_sessions';
    }
    if (columnName === 'Total Completed Tasks') {
      return 'statistics.tooltips.total_completed_tasks';
    }
    return '';
  }

  calculateColumnSum(columnName: string) {
    let sum = reduceColumn(columnName, this.rows);
    return this.statisticsService.formatColumnValue(columnName, sum);
  }
}
