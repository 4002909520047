import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioTemplateRefModel } from '../models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppRadioButtonComponent),
  multi: true,
};

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [DEFAULT_VALUE_ACCESSOR],
})
export class AppRadioButtonComponent implements ControlValueAccessor {
  /**
   * Is the radio button checked
   *
   * @type {boolean}
   */
  @Input() checked = false;

  /**
   * Make the radio button disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Group name associates radio buttons to a group for being able to prevent more then one button being checked.
   *
   * @type {string}
   */
  @Input() groupName = '';

  /**
   * Radio value
   *
   * @type {string}
   */
  @Input() value = '';

  /**
   * Emitter for radio button change
   *
   * @type {EventEmitter<string>}
   */
  @Output() radioButtonSelected: EventEmitter<string> =
    new EventEmitter<string>();

  @ContentChild(TemplateRef) templateRef!: TemplateRef<RadioTemplateRefModel>;

  onSelect(radioElement: HTMLInputElement): void {
    this.radioButtonSelected.emit(radioElement.value);
    this.onChange(radioElement.value);
  }

  writeValue(checkedStatus: boolean) {
    this.checked = checkedStatus;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onChange = (selectedValue: string) => {};

  registerOnChange = (onChange: any) => {
    this.onChange = onChange;
  };

  onTouched = () => {};

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
