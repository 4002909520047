import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StatisticsService } from './services/statistics.service';

import { combineLatest, forkJoin } from 'rxjs';

import { GeneralService } from 'src/app/shared/states/general-state.service';

import { rangeOptions } from 'src/app/shared/components/date-picker/date-preset';
import { formatDateRange } from 'src/app/shared/helper/helper';
import { FiltersService } from './services/filters.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { SelectedFilterObject } from './types/filters';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  isDesktop = true;
  selectedRange = {
    startDate: rangeOptions['Last 7 Days'][0],
    endDate: rangeOptions['Last 7 Days'][1],
  };
  firstTimeGetReportPageOpen = true;
  selected!: SelectedFilterObject;
  rowsToExport!: any;
  columnsToExport!: any;
  constructor(
    private statisticsService: StatisticsService,
    private generalService: GeneralService,
    private filtersService: FiltersService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loaderService.show();
    combineLatest([
      this.statisticsService.rows$,
      this.statisticsService.columns$,
      this.generalService.isDesktop$,
      this.filtersService.selected$,
      this.statisticsService.data$,
    ]).subscribe(([rows, columns, isDesktop, selected, data]) => {
      if (data?.results.length == 0) {
        this.rowsToExport = [];
      } else {
        this.rowsToExport = rows;
      }
      this.columnsToExport = columns;
      this.isDesktop = isDesktop;
      this.selected = selected;
      this.cdr.detectChanges();
    });

    forkJoin([
      this.filtersService.loadCountries(),
      this.statisticsService.getAdFormatEnum(),
    ]);

    if (!this.filtersService.navigateFromPage) {
      this.filtersService.getFilters();
    }
  }

  ngOnDestroy() {
    this.statisticsService.currentPage = 1;
  }

  getDateSelected(event: { startDate: any; endDate: any }) {
    if (!this.firstTimeGetReportPageOpen) {
      this.filtersService.setBtnDisabled(false);
    } else {
      this.firstTimeGetReportPageOpen = false;
    }
    const dateRange = formatDateRange(event);
    this.statisticsService.updateFilters({
      from_date: dateRange?.from_date,
      to_date: dateRange?.to_date,
    });

    if (this.filtersService.navigateFromPage) {
      setTimeout(() => {
        this.statisticsService.getReport(
          this.filtersService.getHasCountry(),
          this.filtersService.numOfTotalCountries
        );
      }, 1500);

      this.filtersService.navigateFromPage = false;
    }
  }
}
