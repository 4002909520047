<div class="social" [formGroup]="socialForm">
  <div class="titles">
    <div class="title typography-headline2">
      {{ 'single.add_social.subtitle' | translate }}
    </div>
  </div>
  <div class="form">
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.tiktok' | translate }}</span>
      <app-input class="input-field" formControlName="tiktok"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.facebook' | translate }}</span>
      <app-input class="input-field" formControlName="facebook"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.instagram' | translate }}</span>
      <app-input class="input-field" formControlName="instagram"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.youtube' | translate }}</span>
      <app-input class="input-field" formControlName="youtube"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.discord' | translate }}</span>
      <app-input class="input-field" formControlName="discord"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.twitter' | translate }}</span>
      <app-input class="input-field" formControlName="twitter"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.twitch' | translate }}</span>
      <app-input class="input-field" formControlName="twitch"></app-input>
    </div>
    <div class="formControl typography-body3-thin color-light-gray">
      <span>{{ 'single.add_social.personal_site' | translate }}</span>
      <app-input
        class="input-field"
        formControlName="personal_site"
      ></app-input>
    </div>
  </div>
</div>
