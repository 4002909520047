export class singleFormModel {
  url: string | null;
  short_name: string | null;
  title: string | null;
  theme_id: number | null;
  tier_id: number | null;
  background: string | null;
  sub_id: string | null;
  number_of_tasks: number | null;
  social_links: {
    youtube: string | null;
    tiktok: string | null;
    facebook: string | null;
    instagram: string | null;
    discord: string | null;
    twitter: string | null;
    twitch: string | null;
    personal_site: string | null;
  } | null;
  social_channel!: number | null;

  constructor() {
    this.url = null;
    this.short_name = null;
    this.title = null;
    this.theme_id = null;
    this.tier_id = null;
    this.background = null;
    this.sub_id = null;
    this.social_links = null;
    this.number_of_tasks = null;
    this.social_channel = null;
  }
}
