<div class="api">
  <span class="typography-heading3-bold title">
    {{ 'profile.api_key.title' | translate }}
  </span>
  <div class="subtitle typography-heading4-thin">
    <div>{{ 'profile.api_key.subtitle1' | translate }}</div>
    <div>
      <div class="typography-headline2">
        {{ 'profile.api_key.subtitle2' | translate }}
        <a href="https://help.lootlabs.gg/en/category/api-documentation-1vutrk2/" class="here" target="_blank">
          {{ 'profile.api_key.here' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="generator">
<!--    <div class="typography-headline2 generator-key">-->
<!--      {{ 'profile.api_key.title' | translate }}:-->
<!--    </div>-->
    <div class="copy">
      <app-button
        *ngIf="!isGenerate"
        color="primary"
        (buttonClick)="generate()"
      >
        {{ 'profile.api_key.btn' | translate }}</app-button
      >
      <app-copy-code
        [showCopy]="isGenerate"
        [linkText]="getCode()"
      ></app-copy-code>
    </div>
  </div>
</div>
