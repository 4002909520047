import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: '',
})
export abstract class AppAbstractButtonComponent {
  /**
   * The size of the button
   *
   * @type {"sm" | "md" | "lg"}
   */
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  /**
   * Button color
   * Use header button in dark background only
   *
   * @type {"primary" | "secondary"  | "grey"  | "transparent | "naked" | "white"}
   */
  @Input() color:
    | 'primary'
    | 'secondary'
    | 'transparent'
    | 'naked'
    | 'grey'
    | 'white' = 'primary';
  /**
   * Set width %
   *
   * @type {boolean}
   */
  @Input() fullWidth = false;

  /**
   * Make the button disabled
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /**
   * Value for 'aria-label'
   *
   * @type {string}
   */
  @Input() ariaLabel = '';

  /**
   * Emitter for button click
   *
   * @type {EventEmitter<void>}
   */
  @Output() buttonClick: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Emit button click event
   *
   */
  onButtonClick($event: Event): void {
    this.buttonClick.emit($event);
  }
}
