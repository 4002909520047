import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';
import {
  formatDateTime,
  paginationDropDown,
} from 'src/app/shared/helper/helper';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { columns } from '../constants/columns';
import { rowModel } from '../model/row.model';
import {
  snackbarObjectFailedGenerate,
  snackbarObjectFailedReferral,
} from '../snackbar/snackbar';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { TableColumn } from '@swimlane/ngx-datatable';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramService {
  private readonly apiUrl: string;
  referralId!: number;
  limitPerPage = 50;
  public limitOptions = [50];
  currentPage = 1;
  rowsLen = 0;

  private tempRows: rowModel[] = [];

  private numOfPages = new BehaviorSubject<number>(1);
  public numOfPages$ = this.numOfPages.asObservable();

  private chosenLimit = new BehaviorSubject<number>(this.limitOptions[0]);
  public chosenLimit$ = this.chosenLimit.asObservable();

  private columns = new BehaviorSubject<TableColumn[]>(columns);
  public columns$ = this.columns.asObservable();

  private rows = new BehaviorSubject<rowModel[]>([]);
  public rows$ = this.rows.asObservable();

  constructor(
    private loginService: LoginService,
    private http: HttpClient,
    private generalService: GeneralService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: any,
    private loaderService: LoaderService,
    private notificationService: NotificationsService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }
  getDataTable() {
    const headers = this.loginService.TokenFromStorage;
    const url =
      this.apiUrl + this.appConfig.actions.tag + this.appConfig.params.referral;
    return this.http.get<Array<any>>(url, { headers }).pipe(
      debounceTime(700),
      map(
        (res: any) => {
          if (res !== null) {
            this.numOfPages.next(
              Math.ceil(res.message?.length / this.limitPerPage)
            );
            this.rowsLen = res.message.length;
            this.limitOptions = paginationDropDown(
              this.numOfPages.value,
              this.limitPerPage
            );
            const data =
              res.message?.map((item: any) => ({
                id: item._id,
                name: item.name,
                created_date: formatDateTime(item.created_on),
              })) || [];
            this.rows.next(data);
            this.tempRows = [...this.rows.value];
          }
        },
        (error: HttpErrorResponse) => {
          this.loaderService.hide();
          return this.generalService.catchError(
            snackbarObjectFailedReferral,
            error
          );
        }
      )
    );
  }

  updateColumnsData(
    initTemplates: TableColumn[],
    genericTemplate: TableColumn
  ) {
    const updatedArray = this.columns.value.map((obj: any) => {
      const nameKey = obj.name || '';
      const name = this.translateService.instant(nameKey);
      const updatedObj = initTemplates.find(
        (updated: any) => updated.prop === obj.prop
      );
      if (updatedObj) {
        return { ...obj, ...updatedObj, name: name };
      }
      return { ...obj, ...genericTemplate, name: name };
    });
    this.columns.next(updatedArray);
  }

  setChosenLimit(limit: number) {
    this.chosenLimit.next(limit);
    this.numOfPages.next(Math.ceil(this.rowsLen / limit));
  }

  generateCode() {
    const headers = this.loginService.TokenFromStorage;
    const url = `${this.apiUrl}${this.appConfig.actions.site}`;
    const userId = this.generalService.getUser().id;
    const body = {
      name: 'Referral',
      url: `https:://www.u${userId}referrals.com`,
      vertical: 171,
      enable: true,
    };
    return this.http.post(url, body, { headers }).pipe(
      tap(
        (res: any) => {
          const notification = {
            notificationType: NotificationType.AffiliateLink,
          };
          const newNotification =
            this.notificationService.createNotificationObject(notification);

          this.notificationService
            .createNotification(newNotification)
            .subscribe(() => {
              this.notificationService.getNotifications();
            });
          this.generalService.siteReferral = res.message.id;
        },
        (error: HttpErrorResponse) => {
          this.loaderService.hide();
          return this.generalService.catchError(
            snackbarObjectFailedGenerate,
            error
          );
        }
      )
    );
  }

  filterTable(filterTerm: string) {
    const temp = this.tempRows.filter((item) => {
      const name = item.name?.toString().toLowerCase();
      const filterTermLowercase = filterTerm.toLowerCase();

      return name?.includes(filterTermLowercase);
    });
    this.rows.next(temp);
  }
}
