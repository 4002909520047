import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRadioCircleComponent } from './radio-circle.component';

@NgModule({
  declarations: [AppRadioCircleComponent],
  imports: [CommonModule],
  exports: [AppRadioCircleComponent],
})
export class AppRadioCircleModule {}
