import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../states/general-state.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  img = './assets/svg/logo.svg';
  public isDesktop!: boolean;
  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.generalService.isDesktop$.subscribe((val) => {
      this.isDesktop = val;
    });
  }
}
