<div class="cards">
  <div class="card" *ngFor="let card of cards">
    <app-creating-card
      [type]="card.type"
      [title]="card.title | translate"
      [subtitle]="card.subtitle | translate"
      [icon]="card.icon"
      [color]="card.color"
      [tooltip]="card.tooltip | translate"
      [tooltipPosition]="TooltipPosition.BELOW"
    ></app-creating-card>
  </div>
</div>
