export enum ColumnsEnum {
  DATE = 'Report Date',
  OPERATION = 'Operation id',
  COUNTRY = 'Country code',
  TAG = 'Tag id',
  LINK = 'Link',
  IMPRESSIONS = 'Total Impressions',
  REVENUE = 'Total Revenue',
  CPM = 'CPM',
  SESSIONS_CPM = 'Sessions CPM',
  TOTAL_SESSIONS = 'Total Sessions',
  TOTAL_CLICKS = 'Total clicks',
}
