<div class="input-container">
  <div class="calendar-wrapper">
    <input
      type="text"
      matInput
      ngxDaterangepickerMd
      [locale]="localeConfig"
      [ranges]="ranges"
      [showCustomRangeLabel]="true"
      [alwaysShowCalendars]="true"
      [keepCalendarOpeningWithRange]="true"
      [showCancel]="false"
      autocomplete="off"
      (ngModelChange)="formatSelectedRange($event)"
      [(ngModel)]="selectedRange"
      class="date-picker-box typography-headline2"
      name="daterange"
      readonly
    />
  </div>
  <app-icon class="calendar-icon" name="calendar"></app-icon>
</div>
