import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-social',
  templateUrl: './add-social.component.html',
  styleUrls: ['./add-social.component.scss'],
})
export class AddSocialComponent {
  @Input() socialForm!: FormGroup;
}
