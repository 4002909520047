import { Component, OnInit } from '@angular/core';
import { FullScriptApiService } from './sevice/full-script-api.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-full-script-api',
  templateUrl: './full-script-api.component.html',
  styleUrls: ['./full-script-api.component.scss'],
})
export class FullScriptApiComponent implements OnInit {
  constructor(
    private fullScriptApiService: FullScriptApiService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.generalService.loadEnums();
    this.fullScriptApiService.updateData();
  }
}
