<div>
  <form #form="ngForm">
    <div>
      <div class="flex">
        <re-captcha
          id="recaptcha"
          name="recaptcha"
          #recaptcha="ngModel"
          [(ngModel)]="token"
          required
          [class.is-invalid]="
            recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)
          "
          (resolved)="$event && handleConfirmCaptcha($event)"
        >
        </re-captcha>
        <div
          *ngIf="
            recaptcha.invalid &&
            (recaptcha.dirty || recaptcha.touched || invalid)
          "
          class="invalid-feedback typography-footnote1"
        >
          <div *ngIf="recaptcha.errors?.['required']">
            {{ "login.require" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
