import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';
import { Snackbar } from '../../types/snackbar';
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public snackbarDefaultObject: Snackbar = {
    duration: 2000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    message: '',
    type: 'pending-snackbar',
  };

  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(snackBar: Snackbar) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBar.message,
        type: snackBar.type || this.snackbarDefaultObject.type,
      },
      duration: snackBar.duration || this.snackbarDefaultObject.duration,
      verticalPosition:
        snackBar.verticalPosition ||
        this.snackbarDefaultObject.verticalPosition,
      horizontalPosition:
        snackBar.horizontalPosition ||
        this.snackbarDefaultObject.horizontalPosition,
      panelClass: snackBar.type,
    });
  }
}
