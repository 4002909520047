import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationSlideService } from '../services/notification-slide.service';

import { NotificationsService } from '../services/notifications.service';
import { NotificationModel } from '../models/notifications.model';

@Component({
  selector: 'app-notifications-slide',
  templateUrl: './notifications-slide.component.html',
  styleUrls: ['./notifications-slide.component.scss'],
})
export class NotificationsSlideComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  public notificationsDataArray!: Array<NotificationModel>;
  isLoading = true;
  constructor(
    private notificationSlideService: NotificationSlideService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.notificationSlideService.notificationSlideToggle$.subscribe(() => {
      this.sidenav.toggle();
      this.notificationSlideService.notificationSlideOpen.next(
        this.sidenav.opened
      );
    });

    this.notificationSlideService.notificationSlideToggle$.subscribe(() => {
      this.handelLoader();
      this.notificationSlideService.notificationSlideOpen.next(
        this.sidenav.opened
      );
    });
  }

  handelLoader() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  closeNotification() {
    this.sidenav.close();
    this.notificationSlideService.notificationSlideOpen.next(false);
  }

  markAll() {
    this.notificationsService.onClickAllNotification();
  }
}
