import { Component, OnInit } from '@angular/core';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { ScrollService } from '../../services/overlay/scroll.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
  content!: any;
  disableClose: boolean = false;

  constructor(
    private ref: MyOverlayRef,
    private scrollService: ScrollService
  ) {}

  close() {
    this.ref.close(null);
  }

  ngOnInit() {
    this.content = this.ref.content;
    this.scrollService.disableScroll();
  }

  ngOnDestroy(): void {
    this.scrollService.enableScroll();
  }
}
