<div class="preview-container">
  <img class="background" *ngIf="background" [src]="background" />
  <div class="content">
    <app-content
      [creator_name]="creator_name"
      [creator_picture]="creator_picture"
      [title]="title"
      [social_links]="social_links"
      [number_of_tasks]="number_of_tasks"
      [theme]="theme"
      [background]="backgroundForm"
    ></app-content>
  </div>
</div>
