<div class="container">
  <app-icon (click)="close()" class="x" name="white_x" alt="x"></app-icon>
  <span class="typography-heading3-bold">{{
    'dashboard.delete_popup.title' | translate
  }}</span>
  <span class="typography-headline2">{{
    'dashboard.delete_popup.subtitle' | translate
  }}</span>
  <div class="buttons">
    <app-button (buttonClick)="close(false)" color="secondary">{{
      'dashboard.delete_popup.back' | translate
    }}</app-button>
    <app-button (buttonClick)="close(true)" color="primary">{{
      'dashboard.delete_popup.delete' | translate
    }}</app-button>
  </div>
</div>
