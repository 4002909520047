import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfitComponent } from './my-profit.component';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import {TooltipModule} from "../../../shared/components/tooltip/tooltip.module";

@NgModule({
  declarations: [MyProfitComponent],
    imports: [CommonModule, TranslateModule, SwiperModule, TooltipModule],
  exports: [MyProfitComponent],
})
export class MyProfitModule {}
