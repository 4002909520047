import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReferralProgramComponent } from './referral-program.component';
import { ReferralTableComponent } from './components/referral-table/referral-table.component';

@NgModule({
  declarations: [ReferralProgramComponent, ReferralTableComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
  ],
})
export class ReferralProgramModule {}
