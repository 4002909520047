<label
  class="checkbox-container typography-body4-bold"
  [ngClass]="{ disabled: disabled }"
>
  <app-checkbox-input
    (change)="onCheckboxChange()"
    [custom]="custom"
    [checked]="checked"
    [disabled]="disabled"
    [invalid]="invalid"
  ></app-checkbox-input>
  {{ label }}
  <ng-template *ngTemplateOutlet="templateRef; context: { checked, disabled }">
  </ng-template>
</label>
