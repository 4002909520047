import { Component, Input, OnInit } from '@angular/core';
import { AppIcon, icons } from './icons';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /**
   * Icon name from the list of icons
   *
   * @param value
   */
  @Input() set name(value: AppIcon) {
    this._src = icons[value] || '';
  }

  /**
   * Icon size
   *
   * @type {"sm" | "md" | "lg"}
   */
  @Input() size: 'sm' | 'md' | 'hg' | 'hg-extra' | 'lg' = 'md';

  _src = '';

  /**
   * Icon style
   *
   */
  @Input() styles: any = {};
}
