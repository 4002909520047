import { Component, OnInit } from '@angular/core';
import { MyProfitModel } from './model/my-profit.model';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { formatCurrency, formatDateTime } from 'src/app/shared/helper/helper';
import { MyProfitService } from './services/my-profit.service';
import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';
SwiperCore.use([Navigation, Pagination, Controller]);
@Component({
  selector: 'app-my-profit',
  templateUrl: './my-profit.component.html',
  styleUrls: ['./my-profit.component.scss'],
})
export class MyProfitComponent implements OnInit {
  myProfitData: MyProfitModel[] = [
    new MyProfitModel(),
    new MyProfitModel(),
    new MyProfitModel(),
    new MyProfitModel(),
  ];
  isDesktop = true;

  updatedOn!: string;

  constructor(
    private myProfitService: MyProfitService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.generalService.isDesktop$.subscribe((value: boolean) => {
      this.isDesktop = value;
    });

    this.updateProfitData();
  }

  updateProfitData() {
    this.myProfitService.getProfit().subscribe((res: any) => {
      this.updatedOn = formatDateTime(res.message.updated_on);
      this.myProfitData[0].profit = formatCurrency(
        res.message.revenue_yesterday
      );
      this.myProfitData[0].title = 'my_profit.yesterday';
      this.myProfitData[0].tooltip = ' Estimation of Ad profit, this data gets finalized after 2 days.';

      this.myProfitData[1].profit = formatCurrency(
        res.message.revenue_this_month
      );
      this.myProfitData[1].title = 'my_profit.this_month';
      this.myProfitData[1].tooltip = 'How much money was made in the current month.';

      this.myProfitData[2].profit = formatCurrency(
        res.message.revenue_last_month
      );
      this.myProfitData[2].title = 'my_profit.last_month';
      this.myProfitData[2].tooltip = 'How much money was made last month.';
      this.myProfitData[3].profit = formatCurrency(
        res.message.revenue_overall
      );
      this.myProfitData[3].title = 'my_profit.revenue_overall';
      this.myProfitData[3].tooltip = 'How much money was made overall.';
    });
  }
}
