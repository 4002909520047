import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddFullScriptComponent } from './add-full-script.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SetUpLinkComponent } from './components/set-up-link/set-up-link.component';
import { CopyStepComponent } from './components/copy-step/copy-step.component';
import { WlBlStepComponent } from './components/wl-bl-step/wl-bl-step.component';

@NgModule({
  declarations: [AddFullScriptComponent, SetUpLinkComponent, CopyStepComponent, WlBlStepComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTabsModule,
    NgSelectModule,
  ],
})
export class AddFullScriptModule {}
