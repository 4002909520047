import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { UploadFileService } from '../../services/upload-file.service';
import {
  BaseControlValueAccessor,
  getValueAccessor,
} from '../forms/reactive-form/base-control-value-accessor';
import { LoaderService } from '../loader/service/loader.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [getValueAccessor(UploadImageComponent)],
})
export class UploadImageComponent
  extends BaseControlValueAccessor<any>
  implements OnInit
{
  override writeValue(obj: any): void {}
  event!: Event;
  selectedFiles?: FileList;
  currentFile?: File;
  message = '';
  isUpload = false;
  disableCrop = false;
  landscape = './assets/svg/upload.svg';
  avatar = '../../../../assets/png/Avatar.png';
  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;

  @Input() preview: string | null = null;
  @Input() formControlName!: string;
  @Input() type!: string;
  @Input() invalid: boolean | undefined = false;
  @Input() profile = false;
  @Output() imageChanged: EventEmitter<any> = new EventEmitter();
  @Input() displayLoader = false;

  imageInfos?: Observable<any>;

  constructor(
    private uploadService: UploadFileService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.preview !== null) {
      this.isUpload = true;
      this.disableCrop = true;
    }
    if (this.profile) {
      this.isUpload = false;
    }
    this.imageInfos = this.uploadService.getFiles();
  }

  public selectFile(event?: any): void {
    this.preview = '';
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        if (this.displayLoader) {
          this.loaderService.show();
        }
        this.event = event;
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
          this.isUpload = true;
          this.disableCrop = false;
          this.upload();
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  deleteImage() {
    this.preview = this.landscape;
    this.isUpload = false;
    this.disableCrop = false;
    this.onChange(this.preview);
    this.fileUpload.nativeElement.value = null;
  }

  upload(): void {
    if (this.currentFile) {
      this.uploadService.uploadImage(this.currentFile).subscribe({
        next: (event: any) => {
          if (event != null) {
            if (this.displayLoader) {
              this.loaderService.hide();
            }
            this.message = event;
            this.onChange(this.message);
            this.imageChanged.emit(this.message);
            this.imageInfos = this.uploadService.getFiles();
          }
        },
        error: (err: any) => {
          if (err.error && err.error.message) {
            this.message = err.error.message;
          } else {
            this.message = 'Could not upload the image!';
          }

          this.currentFile = undefined;
        },
      });
    }

    this.selectedFiles = undefined;
  }

  private async dataUrlToFile(
    dataUrl: string,
    fileName: string
  ): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName + '_cropped.png', { type: 'image/png' });
  }
}
