import { RedirectRouteEnum, RedirectTypeEnum } from '../enum/notification.enum';

export class NotificationModel {
  id: number | null;
  report_time: Date | string | number;
  report_date: Date | number;
  publisher_id: number | null;
  original_id: number | null;
  subject: string;
  title: string | null;
  body: string | null;
  redirect: string | null;
  clicked: boolean | null;
  seen: boolean | null;

  constructor() {
    this.id = null;
    this.report_time = 0;
    this.report_date = 0;
    this.publisher_id = null;
    this.original_id = null;
    this.subject = '';
    this.title = null;
    this.body = null;
    this.redirect = null;
    this.clicked = null;
    this.seen = null;
  }
}

export enum NotificationType {
  ContentLocker = 'new_content_locker_link',
  ContentJs = 'new_content_js',
  PersonalDetails = 'personal_details_updated',
  AffiliateLink = 'affiliate_link_created',
  BulkLink = 'bulk_link_uploaded',
}

export class NotificationRedirect {
  type?: RedirectTypeEnum | null;
  route: RedirectRouteEnum | null;
  data: object | string | undefined;

  constructor() {
    this.type = null;
    this.route = null;
    this.data = {};
  }
}
