import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppInputAbstractComponent } from './input.abstract';
import { AppIcon } from '../icon/icons';
import { getValueAccessor } from '../forms/reactive-form/base-control-value-accessor';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [getValueAccessor(InputComponent)],
})
export class InputComponent
  extends AppInputAbstractComponent
  implements AfterViewInit
{
  @ViewChild('inputElement') inputElement: ElementRef | undefined;

  @Input() maxWidthContent = false;
  @Input() isNumericInputAllowed = false;
  /**
   * Input type
   *
   * @type {'text' | 'email' | 'password'}
   */
  @Input() type: 'text' | 'email' | 'number' | 'password' = 'text';

  /**
   * Add mask to the input
   *
   * @type {string}
   */
  private _mask = '';
  get mask(): string {
    return this._mask;
  }
  @Input() set mask(value: string) {
    this._mask = this.validateMask(value);
  }

  /**
   * Change class for typography
   *
   * @type {string}
   */
  @Input() typographyClass = 'typography-paragraph';

  /**
   * Add custom style to input
   *
   * @type {string}
   */
  @Input() customStyle?: string;

  /**
   * Use mask special characters
   *
   * @type {boolean}
   */
  @Input() maskSpecialCharacters = true;

  /**
   * Input's prefix text
   *
   * @type {string}
   */
  @Input() prefix?: AppIcon;

  /**
   * Input's suffix text
   *
   * @type {string}
   */
  @Input() suffix?: AppIcon;

  /**
   * Emitter for input changed
   *
   * @type {EventEmitter<string>}
   */
  @Output() override inputChanged = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.inputElement?.nativeElement.focus();
    }
  }

  private validateMask(newPattern: string): string {
    const acceptedCharactersRegex = /^[ASULdMy09\s-/(){}[\].*:+,@"'|]*$/;
    if (acceptedCharactersRegex.test(newPattern)) {
      return newPattern;
    }
    console.warn(`Mask '${newPattern}' is not valid.`);
    return '';
  }
}
