<div
  class="container"
  [ngClass]="{
    background:
      (ref && ref.data.isCopyCodePopup) || (data && data.isCopyCodePopup),
    bottomSheetWidth: data && data.isCopyCodePopup,
    popupWith: ref && ref.data.isCopyCodePopup
  }"
>
  <div
    class="header"
    *ngIf="(ref && ref.data.isCopyCodePopup) || (data && data.isCopyCodePopup)"
  >
    <span class="typography-heading3-thin">{{
      'copy_code.popup_title' | translate
    }}</span>
    <app-icon class="close" name="close" (click)="close()"></app-icon>
  </div>
  <div class="copy-frame-container">
    <app-button
      *ngIf="showCopy"
      class="button-flex"
      size="md"
      (buttonClick)="copyToClipboard()"
      color="naked"
    >
      <app-icon start name="content_copy"></app-icon>
    </app-button>

    <ng-container>
      <app-input
        class="text-flex"
        [(ngModel)]="linkText"
        [disabled]="true"
      ></app-input>
    </ng-container>
    <app-button
      (buttonClick)="closeWithEvent()"
      *ngIf="ref && ref.data.displayButton"
      >{{ ref.data.displayButton | translate }}</app-button
    >
  </div>
</div>
