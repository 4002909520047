import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './time-age.pipe';
import { IsNumberPipe } from './is-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TimeAgoPipe, IsNumberPipe],
  exports: [TimeAgoPipe, IsNumberPipe],
})
export class PipesModule {}
