import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-side-panel-loader',
  templateUrl: './side-panel-loader.component.html',
  styleUrls: ['./side-panel-loader.component.scss'],
})
export class SidePanelLoaderComponent {
  public options: AnimationOptions = {
    path: '/assets/lottie/loader.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    width: '150px',
    height: '170px',
    zIndex: '999999',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}
