import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { SnackbarService } from '../snackbar/snackbar.service';
import { snackbarCopyCode } from './snackbar/snackbar';
import { Clipboard } from '@angular/cdk/clipboard';
import { MyOverlayRef } from '../../services/overlay/overlay-ref';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-copy-code',
  templateUrl: './copy-code.component.html',
  styleUrls: ['./copy-code.component.scss'],
})
export class CopyCodeComponent implements OnInit {
  @Input() linkText!: string;
  @Input() showCopy = true;

  constructor(
    private clipboard: Clipboard,
    private snackbarService: SnackbarService,
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    @Optional() @SkipSelf() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Optional()
    @SkipSelf()
    private bottomSheetRef: MatBottomSheetRef<CopyCodeComponent>
  ) {}
  ngOnInit(): void {
    if ((this.ref && this.ref.data.isCopyCodePopup) || this.data) {
      this.linkText = this.ref?.data?.linkText || this.data.linkText;
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.linkText);
    this.snackbarService.showSnackbar(snackbarCopyCode);
  }

  close() {
    if (this.ref) {
      this.ref.close();
    } else if (this.data) {
      this.bottomSheetRef.dismiss();
    }
  }

  closeWithEvent() {
    this.ref.close(true);
  }
}
