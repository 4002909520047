import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { PipesModule } from '../../pipe/pipes.module';
import { SidePanelLoaderModule } from '../side-panel-loader/side-panel-loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppDividerModule } from '../divider copy/divider.module';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [SupportComponent],
  imports: [
    CommonModule,
    PipesModule,
    EmptyStateModule,
    SidePanelLoaderModule,
    MatSidenavModule,
    TranslateModule,
    IconModule,
    ButtonModule,
    AppDividerModule,
  ],
  exports: [SupportComponent],
})
export class SupportModule {}
