import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from './components/loader/loader.module';
import { LogoModule } from './components/logo/logo.module';
import { ButtonModule } from './components/button/button.module';
import { InputModule } from './components/input/input.module';
import { IconModule } from './components/icon/icon.module';
import { ErrorMessageModule } from './components/error-message/error-message.module';
import { SnackbarModule } from './components/snackbar/snackbar.module';
import { GeneralFacade } from './states/general-state.facade';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TimeAgoPipe } from './pipe/time-age.pipe';
import { StoreModule } from '@ngrx/store';
import { LOOTLABS_DATA } from './states/general-state.state';
import { reducer } from './states/general-state.reducer';
import { GeneralEffects } from './states/general-state.effect';
import { EffectsModule } from '@ngrx/effects';
import { OverlayModule } from './components/overlay/overlay.module';
import { ErrorPopupModule } from './components/error-popup/error-popup.module';
import { DividerModule } from './components/divider/divider.module';
import { UploadImageModule } from './components/upload-image/upload-image.module';
import { CopyCodeModule } from './components/copy-code/copy-code.module';
import { PopoverModule } from './components/popover/popover.module';
import { CardModule } from './components/card/card.module';
import { AppCheckboxModule } from './components/checkbox/checkbox.module';
import { TooltipModule } from './components/tooltip/tooltip.module';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { EmptyStateModule } from './components/empty-state/empty-state.module';
import { BottomSheetModule } from './components/bottom-sheet/bottom-sheet.module';
import { AppRadioButtonModule } from './components/radio-button/radio-button/radio-button.module';
import { AppRadioCircleModule } from './components/radio-button/radio-circle/radio-circle.module';
import { AppRadioGroupModule } from './components/radio-button/radio-group/radio-group.module';
import { UploadCsvModule } from './components/upload-csv/upload-csv.module';
import { groupByButtonModule } from './components/toggle-button/group-by-button.module';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { ExportToCsvModule } from './components/export-to-csv/export-to-csv.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { SidePanelLoaderModule } from './components/side-panel-loader/side-panel-loader.module';
import { PipesModule } from './pipe/pipes.module';
import { SupportModule } from './components/support/support.module';
import { DownloadCsvModule } from './components/download-csv/download-csv.module';
import { oldPanelModalModule } from "./components/old-panel-modal/old-panel-modal.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TooltipModule,
    StoreModule.forFeature(LOOTLABS_DATA, reducer),
    EffectsModule.forFeature([GeneralEffects]),
  ],
  exports: [
    LoaderModule,
    LogoModule,
    ButtonModule,
    InputModule,
    IconModule,
    InputModule,
    ErrorMessageModule,
    OverlayModule,
    SnackbarModule,
    ErrorPopupModule,
    DividerModule,
    UploadImageModule,
    CopyCodeModule,
    PopoverModule,
    CardModule,
    AppCheckboxModule,
    TooltipDirective,
    EmptyStateModule,
    DividerModule,
    BottomSheetModule,
    AppRadioButtonModule,
    AppRadioCircleModule,
    AppRadioGroupModule,
    UploadCsvModule,
    groupByButtonModule,
    DatePickerModule,
    ExportToCsvModule,
    NotificationsModule,
    SidePanelLoaderModule,
    PipesModule,
    SupportModule,
    DownloadCsvModule,
    oldPanelModalModule
  ],
  providers: [
    GeneralFacade,
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    TimeAgoPipe,
  ],
})
export class SharedModule {}
