<nav class="sidebar-nav">
  <ul class="nav">
    <li class="nav-item" *ngFor="let item of sideBarData">
      <a
        *ngIf="!item.disable"
        class="nav-link links"
        [class.active]="item.key === activeTitle"
        (click)="changeTab(item.key)"
      >
        <span
          [class.circle-icon-active]="item.key === activeTitle"
          class="circle-icon"
        >
          <app-icon
            class="icon"
            size="lg"
            [name]="item.key === activeTitle ? item.iconActive : item.icon"
          ></app-icon>
        </span>
        <div class="title typography-body4-thin">
          {{ item.title | translate }}
        </div>
      </a>
      <a *ngIf="item.disable" class="nav-link" [class.disable]="item.disable">
        <span
          [class.circle-icon-active]="item.key === activeTitle"
          class="circle-icon"
        >
          <app-icon
            class="icon"
            size="lg"
            [name]="item.key === activeTitle ? item.iconActive : item.icon"
          ></app-icon>
        </span>
        <div class="title typography-body4-thin">
          {{ item.title | translate }}
        </div>
      </a>
    </li>
  </ul>
</nav>
