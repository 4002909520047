import { Component, Input } from '@angular/core';
import { AppAbstractButtonComponent } from './abstract/button.abstract';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./abstract/button.basic.scss', './button.component.scss'],
})
export class ButtonComponent extends AppAbstractButtonComponent {
  /**
   * Custom style for the button
   *
   * @type {string}
   */
  @Input() customStyle?: string;
}
