import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppIcon } from 'src/app/shared/components/icon/icons';
import { ProfileService } from '../../service/profile.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public passwordForm!: FormGroup;
  isButtonDisabled = false;
  public isPasswordVisible = {
    old: false,
    new: false,
    confirm: false,
  };
  passwordIcon: { [key: string]: AppIcon } = {
    old: 'hide_eye',
    new: 'hide_eye',
    confirm: 'hide_eye',
  };
  isSuccessChanges!: string;
  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.passwordForm.valueChanges.subscribe(() => {
      this.isButtonDisabled = false;
    });
  }

  initForm() {
    this.passwordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  passwordsMatchValidator(formGroup: FormGroup) {
    const newPasswordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (newPasswordControl && confirmPasswordControl) {
      const newPassword = newPasswordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (newPassword !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordsNotMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  togglePasswordVisibility(type: string): void {
    switch (type) {
      case 'old':
        this.isPasswordVisible.old = !this.isPasswordVisible.old;
        if (this.isPasswordVisible['old']) {
          this.passwordIcon['old'] = 'visibility';
        } else {
          this.passwordIcon['old'] = 'hide_eye';
        }
        break;
      case 'new':
        this.isPasswordVisible.new = !this.isPasswordVisible.new;
        if (this.isPasswordVisible['new']) {
          this.passwordIcon['new'] = 'visibility';
        } else {
          this.passwordIcon['new'] = 'hide_eye';
        }
        break;
      case 'confirm':
        this.isPasswordVisible.confirm = !this.isPasswordVisible.confirm;
        if (this.isPasswordVisible['confirm']) {
          this.passwordIcon['confirm'] = 'visibility';
        } else {
          this.passwordIcon['confirm'] = 'hide_eye';
        }
        break;
    }
  }
  onSubmit() {
    if (this.passwordForm.valid) {
      const oldPassword = this.passwordForm.value.oldPassword;
      const newPassword = this.passwordForm.value.newPassword;
      const response = this.profileService
        .resetPassword(newPassword, oldPassword)
        .subscribe();
    }
    this.isButtonDisabled = true;
  }
}
