import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CopyCodeComponent } from 'src/app/shared/components/copy-code/copy-code.component';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { Regex } from 'src/app/shared/validators/regex';
import { SingleStep } from './constants/steps';
import { singleFormModel } from '../models/single-form.model';
import { Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import { linkOptions } from './constants/link-option';
import { checkCategoryValidation } from 'src/app/shared/helper/helper';
import { ServerSingleObject } from './models/server-single.model';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { AddSingleService } from './service/add-single.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { convertFromValueToKey } from 'src/app/shared/helper/helper';
import { takeUntil } from 'rxjs/operators';
import { youtubeValidator } from 'src/app/shared/validators/validatiors';

@Component({
  selector: 'app-add-single',
  templateUrl: './add-single.component.html',
  styleUrls: ['./add-single.component.scss'],
})
export class AddSingleComponent implements OnInit {
  progressBars: number[] = [10];
  singleLinkForm!: FormGroup;
  steps = SingleStep;
  isDesktop = true;
  singleLinkData = new singleFormModel();
  isEditMode = false;
  public creatorDetails!: CreatorModel | null;
  private unsubscribe$ = new Subject<void>();
  private shouldOpenPopup = false;

  constructor(
    @Optional() @SkipSelf() public ref: MyOverlayRef,
    public generalService: GeneralService,
    public router: Router,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private addSingleService: AddSingleService,
    private overlay: OverlayService
  ) {}

  ngOnDestroy(): void {
    this.steps[0].isOpen = true;
    this.steps[1].isOpen = false;
    this.steps[2].isOpen = false;
    this.steps[0].isValid = false;
    this.steps[1].isValid = false;
    this.steps[2].isValid = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.generalService.getSocialChanneFromEnum();
    combineLatest([
      this.generalService.isDesktop$,
      this.generalService.creator$,
    ]).subscribe(([isDesktop, creator]) => {
      this.isDesktop = isDesktop;
      if (!this.ref || !this.ref.data.editMode) {
        this.creatorDetails = creator;
      }
    });

    if (this.isDesktop && this.ref.data.editMode) {
      this.isEditMode = true;

      this.steps[0].isValid = true;
      this.steps[1].isValid = true;
      this.singleLinkData = this.ref.data;
    }
    this.initForm();

    this.checkCategoryValidation();

    this.singleLinkForm?.get('first')?.valueChanges.subscribe((res) => {
      if (!this.singleLinkForm.get('first')?.valid) {
        this.updateProgressBar(this.progressBars[0], 10);
        this.steps[0].isValid = false;
      }
    });

    this.singleLinkForm?.get('second')?.valueChanges.subscribe((res) => {
      if (!this.singleLinkForm.get('second')?.valid) {
        this.updateProgressBar(this.progressBars[0], 33);
        this.steps[1].isValid = false;
      }
    });
  }

  checkCategoryValidation() {
    checkCategoryValidation(
      this.singleLinkForm.get('first.category')?.value,
      this.firstFormGroup
    );
  }

  updateProgressBar(progressBar: number, value: number) {
    this.progressBars[0] = value;
  }

  close() {
    this.ref.close();
  }

  initForm() {
    this.singleLinkForm = new FormGroup({
      first: new FormGroup({
        url: new FormControl(this.singleLinkData?.url || null, [
          Validators.required,
          Validators.pattern(Regex.ValidateUrl),
        ]),
        short_name: new FormControl(this.singleLinkData?.short_name || null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
        ]),
        category: new FormControl(this.getCategotyValue(), [
          Validators.required,
        ]),
        other_category: new FormControl(this.getOtherCategoryValue()),
      }),
      second: new FormGroup({
        title: new FormControl(this.singleLinkData?.title || null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Validators.pattern(Regex.englishCharactersRegex),
        ]),
        theme_id: new FormControl(
          this.generalService.getThemes(this.singleLinkData?.theme_id) || null,
          [Validators.required]
        ),
        link_option: new FormControl(
          this.singleLinkData.background === null ||
          this.singleLinkData.background === 'null' ||
          this.singleLinkData.background === 'None' ||
          this.singleLinkData.background === 'none'
            ? linkOptions[2].key
            : this.singleLinkData.background?.includes('youtube') ||
              this.singleLinkData.background?.includes('youtu')
            ? linkOptions[1].key
            : linkOptions[0].key
        ),
        background: new FormControl(this.singleLinkData.background || null, []),
        tier_id: new FormControl(this.singleLinkData?.tier_id || null, [
          Validators.required,
        ]),
        number_of_tasks: new FormControl(
          this.singleLinkData?.number_of_tasks || 1,
          [Validators.required]
        ),
        social_channel: new FormControl(
          this.singleLinkData?.social_channel,
          null
        ),
      }),
      third: new FormGroup({
        tiktok: new FormControl(
          this.singleLinkData.social_links?.tiktok ||
            this.creatorDetails?.tiktok
        ),
        facebook: new FormControl(
          this.singleLinkData.social_links?.facebook ||
            this.creatorDetails?.facebook
        ),
        instagram: new FormControl(
          this.singleLinkData.social_links?.instagram ||
            this.creatorDetails?.instagram
        ),
        youtube: new FormControl(
          this.singleLinkData.social_links?.youtube ||
            this.creatorDetails?.youtube
        ),
        discord: new FormControl(
          this.singleLinkData.social_links?.discord ||
            this.creatorDetails?.discord
        ),
        twitter: new FormControl(
          this.singleLinkData.social_links?.twitter ||
            this.creatorDetails?.twitter
        ),
        twitch: new FormControl(
          this.singleLinkData.social_links?.twitch ||
            this.creatorDetails?.twitch
        ),
        personal_site: new FormControl(
          this.singleLinkData.social_links?.personal_site ||
            this.creatorDetails?.personal_site
        ),
      }),
    });
    this.applyValidators();
  }

  applyValidators(): void {
    const backgroundControl = this.secondFormGroup.get('background');
    const linkOptionControl = this.secondFormGroup.get('link_option');

    backgroundControl?.setValidators([
      youtubeValidator({
        control: backgroundControl,
        additionalControls: linkOptionControl,
      }),
    ]);
    backgroundControl?.updateValueAndValidity();
  }

  get firstFormGroup() {
    return this.singleLinkForm.get('first') as FormGroup;
  }

  get secondFormGroup() {
    return this.singleLinkForm.get('second') as FormGroup;
  }

  get thirdFormGroup() {
    return this.singleLinkForm.get('third') as FormGroup;
  }

  getCategotyValue() {
    if (this.singleLinkData?.sub_id === 'Minecraft') {
      return 'Minecraft';
    } else {
      return 'Other';
    }
  }

  getOtherCategoryValue() {
    if (this.singleLinkData?.sub_id === 'Minecraft') {
      return null;
    } else {
      return this.singleLinkData?.sub_id;
    }
  }

  panelOpened(index: number) {
    this.steps[index].isOpen = true;
  }

  panelClosed(index: number) {
    this.steps[index].isOpen = false;
  }

  firstStepCompleted() {
    this.panelClosed(0);
    this.panelOpened(1);
    this.steps[0].isValid = true;
    this.updateProgressBar(this.progressBars[0], 33);
    this.cdr.detectChanges();
  }

  secondStepCompleted() {
    this.panelClosed(1);
    this.panelOpened(2);
    this.steps[1].isValid = true;
    this.updateProgressBar(this.progressBars[0], 100);
    this.cdr.detectChanges();
  }

  updateValues() {
    this.updateProgressBar(this.progressBars[0], 100);
    if (this.isDesktop) {
      this.close();
      this.shouldOpenPopup = true;

      this.loaderService.isLoading$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((load) => {
          if (!load && this.shouldOpenPopup) {
            const ref = this.overlay.open(CopyCodeComponent, {
              linkText: this.addSingleService.short_link,
              isCopyCodePopup: true,
              displayButton: 'single.add_btn',
            });
            ref.afterClosed$.subscribe((res) => {
              if (res.data) {
                this.addSingleService.openSingleContentLockerComponent({
                  showFirstComponent: true,
                  editMode: false,
                });
              }
            });
            this.shouldOpenPopup = false;
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
          }
        });
    } else {
      this.router.navigate(['dashboard']).then(() => {
        this.openBottomSheet();
      });
    }
  }

  openBottomSheet(): void {
    this.bottomSheet.open(CopyCodeComponent, {
      data: {
        linkText: this.addSingleService.short_link,
        isCopyCodePopup: true,
      },
    });
  }

  createServerObject() {
    const singleServer: ServerSingleObject = {
      background:
        this.secondFormGroup.get('background')?.value &&
        this.secondFormGroup.get('background')?.value != 'none' &&
        this.secondFormGroup.get('background')?.value != 'null'
          ? this.secondFormGroup.get('background')?.value
          : null,
      number_of_tasks: this.secondFormGroup.get('number_of_tasks')?.value,
      short_name: this.firstFormGroup.get('short_name')?.value,
      sub_id:
        this.firstFormGroup.get('category')?.value === 'Create Folder'
          ? this.firstFormGroup.get('other_category')?.value
          : this.firstFormGroup.get('category')?.value,
      theme_id: convertFromValueToKey(
        this.generalService.getThemeAsArray(),
        this.secondFormGroup.get('theme_id')?.value
      ),
      tier_id: convertFromValueToKey(
        this.generalService.getTierAsArray(),
        this.secondFormGroup.get('tier_id')?.value
      ),
      title: this.secondFormGroup.get('title')?.value,
      url: this.firstFormGroup.get('url')?.value,
      social_links: this.deleteNullFields(),
      social_channel: this.secondFormGroup.get('social_channel')?.value,
      creator_name: localStorage.getItem('creatorName') || null,
      creator_picture: localStorage.getItem('creatorPic') || null,
    };
    return singleServer;
  }

  deleteNullFields() {
    let socialLinks: any = null;
    Object.keys(this.thirdFormGroup.controls).forEach((controlName) => {
      const controlValue = this.thirdFormGroup.get(controlName)?.value;
      if (controlValue !== null) {
        if (!socialLinks) {
          socialLinks = {};
        }
        socialLinks[controlName] = controlValue;
      }
    });
    return socialLinks;
  }

  submit() {
    if (this.singleLinkForm.valid) {
      const singleServer: ServerSingleObject = this.createServerObject();
      this.loaderService.show();

      if (this.isDesktop && this.ref.data.editMode) {
        singleServer.short_ids = [this.ref.data.id];
        singleServer.tag_id = Number(this.ref.data.tag_id);
        this.addSingleService
          .editSingleContentLocker(singleServer)
          .subscribe((res) => {
            this.updateValues();
          });
        this.loaderService.hide();
        this.close();
      } else {
        this.addSingleService
          .createSingleContentLocker(singleServer)
          .subscribe((res) => {
            if (this.addSingleService.short_link) {
              this.updateValues();
            }
            this.loaderService.hide();
            this.close();
            const ref = this.overlay.open(CopyCodeComponent, {
              linkText: res.message[0].full_short,
              isCopyCodePopup: true,
              // displayButton: 'single.add_btn',
            });
          });
      }
    }
  }
}
