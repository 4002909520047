import { Component, Input, OnInit } from '@angular/core';
import { ExportToCsvService } from '../../services/export-to-csv.service';

@Component({
  selector: 'app-export-to-csv',
  templateUrl: './export-to-csv.component.html',
  styleUrls: ['./export-to-csv.component.scss'],
})
export class ExportToCsvComponent {
  @Input() public rows: any;
  @Input() public columns: any;
  @Input() public disable = true;
  @Input() public title!: string;

  selectedRowIds: Set<number> = new Set<number>();

  constructor(private exportToCsvService: ExportToCsvService) {}

  cleanData() {
    if (this.rows && this.rows.length > 0 && this.columns !== undefined) {
      const cleanedRows = this.rows.map((row: any) => {
        const cleanedRow: any = {};
        for (const key in row) {
          if (row[key] !== undefined && typeof row[key] !== 'object') {
            cleanedRow[key] = row[key];
          } else if (
            row[key] !== undefined &&
            typeof row[key] === 'object' &&
            Array.isArray(row[key])
          ) {
            cleanedRow[key] = row[key];
          }
        }
        return cleanedRow;
      });
      const cleanedColumns = this.columns.filter((column: any) => {
        return cleanedRows.some((row: any) => column.prop in row);
      });
      this.rows = cleanedRows;
      this.columns = cleanedColumns;
    }
  }

  convertArrayToSet() {
    this.rows.forEach((item: any) => this.selectedRowIds.add(item));
  }

  exportToCsv() {
    this.cleanData();
    this.exportToCsvService.export(this.rows, this.columns, this.title);
  }
}
