<div class="notification-container color-white">
  <div
    class="new-notification-container"
    [ngClass]="{
      'new-notification': !notificationData.clicked,
      old: notificationData.clicked
    }"
  >
    <div class="noti-data-container">
      <div class="typography-caption">
        {{ timestamp$ | async | timeAgo }}
      </div>
      <div class="typography-footnote2-bold">{{ notificationData.title }}</div>
      <div class="typography-caption">{{ notificationData.body }}</div>
      <div class="redirect-container" *ngIf="isDesktop">
        <app-button
          color="transparent"
          class="redirect-button"
          (buttonClick)="redirect()"
          customStyle="redirect"
        >
          <div class="typography-caption">
            {{ notificationData.redirect }}
          </div>
          <app-icon name="move_arrow"></app-icon>
        </app-button>
      </div>
    </div>
  </div>
</div>
