<mat-sidenav-container (backdropClick)="closeNotification()">
  <mat-sidenav
    #sidenav
    (keydown.escape)="closeNotification()"
    [position]="'end'"
    disableClose
  >
    <app-side-panel-loader
      *ngIf="isLoading; else content"
    ></app-side-panel-loader>

    <ng-template #content>
      <div class="sidenav-header">
        <span class="typography-heading3-thin">
          {{ 'notifications.title' | translate }}
        </span>
        <div class="sidenav-icons">
          <app-button color="naked" (buttonClick)="markAll()">
            <app-icon [name]="'mark_all'"></app-icon>
          </app-button>

          <app-divider class="divider" direction="vertical"></app-divider>
          <app-button (buttonClick)="closeNotification()" color="naked">
            <app-icon name="back"></app-icon>
          </app-button>
        </div>
      </div>

      <div class="notification-card-containenr">
        <ng-content></ng-content>
      </div>
    </ng-template>
  </mat-sidenav>
</mat-sidenav-container>
