<div *ngIf="isDesktop; else isMobile" class="filters-container">
  <div class="container">
    <div
      class="filters-list"
      *ngFor="let list of filtersService.lists$ | async; let index = index"
    >
      <span class="typography-body4-thin">{{ list.title | translate }}</span>

      <ng-select
        #filterList
        class="filter-dropdown typography-body3-thin"
        [items]="list.data"
        [multiple]="list.multiple_search"
        bindLabel="value"
        [searchable]="true"
        (ngModelChange)="onSelectChange($event, list.key)"
        [(ngModel)]="selected[list.key]"
        [ngClass]="{
          'has-value': selected[list.key] !== null
        }"
        [compareWith]="compareFn"
        groupBy="selectedAllGroup"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        [placeholder]="list.placeholder | translate"
        [clearable]="false"
      >
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <input
            id="item-{{ index }}"
            type="checkbox"
            [ngModel]="item$.selected"
            class="all"
          />
          {{ 'statistics.filters.all' | translate }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <input type="checkbox" [ngModel]="item$.selected" />
          {{ item.value }}
        </ng-template>
      </ng-select>
    </div>
  </div>
  <app-button
    [disabled]="isClearBtnDisabled()"
    color="secondary"
    (buttonClick)="clearAll()"
  >
    {{ 'statistics.filters.clear_all' | translate }}
  </app-button>
  <app-button [disabled]="isDisable" (buttonClick)="getReport()">
    {{ 'statistics.filters.btn' | translate }}
  </app-button>
</div>

<ng-template #isMobile>
  <app-button color="naked" (buttonClick)="openBottomSheet()">
    <app-icon name="filter"> </app-icon>
  </app-button>
</ng-template>

<ng-template #contentTemplate>
  <div class="bottom-sheet">
    <div class="header">
      <app-icon
        (click)="closeBottomSheet()"
        class="close-icon"
        name="close"
      ></app-icon>
      <div class="title typography-heading3-bold">
        {{ 'statistics.filters.title' | translate }}
      </div>
      <app-button class="report" (buttonClick)="getReport()">
        {{ 'statistics.filters.btn' | translate }}
      </app-button>
    </div>

    <div class="filters-container container-mobile">
      <div class="container mobile">
        <div class="filters-list">
          <span class="typography-body4-thin">{{
            'statistics.filters.date' | translate
          }}</span>

          <ng-select
            class="filter-dropdown typography-body3-thin"
            [items]="rangeOption"
            [multiple]="false"
            bindLabel="key"
            bindValue="key"
            [(ngModel)]="selectedDate.key"
            (change)="getDateSelected($event)"
            [searchable]="false"
            [clearable]="false"
            [closeOnSelect]="true"
            [placeholder]="'statistics.filters.date_placeholder' | translate"
            [ngClass]="{
              'has-value': selectedDate !== null
            }"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$">
              <input type="checkbox" [ngModel]="item$.selected" />
              {{ item.key }}
            </ng-template>
          </ng-select>
        </div>
        <div
          class="filters-list"
          *ngFor="let list of filtersService.lists$ | async; let index = index"
        >
          <span class="typography-body4-thin">{{
            list.title | translate
          }}</span>

          <ng-select
            #filterList
            class="filter-dropdown typography-body3-thin"
            [items]="list.data"
            [multiple]="list.multiple_search"
            bindLabel="value"
            [searchable]="false"
            (ngModelChange)="onSelectChange($event, list.key)"
            [(ngModel)]="selected[list.key]"
            [ngClass]="{
              'has-value': selected[list.key] !== null
            }"
            [compareWith]="compareFn"
            groupBy="selectedAllGroup"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            [closeOnSelect]="false"
            [placeholder]="list.placeholder | translate"
            [clearable]="false"
          >
            <ng-template
              ng-optgroup-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <input
                id="item-{{ index }}"
                type="checkbox"
                [ngModel]="item$.selected"
              />
              {{ 'statistics.filters.all' | translate }}
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <input type="checkbox" [ngModel]="item$.selected" />
              {{ item.value }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>
