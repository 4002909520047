import { AppIcon } from 'src/app/shared/components/icon/icons';

export const SocialLinks = [
  { iconName: 'white_tiktok' as AppIcon, controlName: 'tiktok' },
  { iconName: 'white_youtube' as AppIcon, controlName: 'youtube' },
  { iconName: 'white_twitter' as AppIcon, controlName: 'twitter' },
  { iconName: 'white_twitch' as AppIcon, controlName: 'twitch' },
  { iconName: 'white_discord' as AppIcon, controlName: 'discord' },
  { iconName: 'white_facebook' as AppIcon, controlName: 'facebook' },
  { iconName: 'white_instagram' as AppIcon, controlName: 'instagram' },
];
