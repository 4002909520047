import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LoginService} from "./common/login/store/login-state/login.service";
import { APP_CONFIG } from 'src/app';


@Injectable({
  providedIn: 'root'
})
export class KillSwitchService {
  private apiUrl: string;
  private LayoutManager: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loginService: LoginService,
    @Inject(APP_CONFIG) private appConfig: any
    ) {this.apiUrl = this.appConfig.apiUrl;}

  checkKillSwitch(): Promise<void> {
    const url = this.apiUrl + this.appConfig.actions.kill_switch_status;
    return this.http.get<any>(url).toPromise().then(response => {
      let isKillSwitchOn = Boolean(parseInt(response.message.results[0].value));
      if (isKillSwitchOn) {
        this.setSystemStatus(false);
        this.logoutAndRedirect();
      } else {
        this.setSystemStatus(true);
      }
    }).catch(err => {
      this.setSystemStatus(true);
    });
  }

  private setSystemStatus(status: boolean): void {
    localStorage.setItem('switchStatus', String(status));
  }

  private logoutAndRedirect(): void {
    this.loginService.logoutGuard();
    this.router.navigate(['/login']);
  }
}
