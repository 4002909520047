<button
  type="button"
  class="app-button-base app-button-{{ color }} app-button-{{
    size
  }} app-button-full-width-{{ fullWidth }} {{ customStyle }}"
  [class.disabled]="disabled"
  [disabled]="disabled"
  (click)="onButtonClick($event)"
>
  <span class="inner-button typography-headline2">
    <ng-container [ngTemplateOutlet]="iconStart"></ng-container>
    <ng-content></ng-content>
    <ng-container [ngTemplateOutlet]="iconEnd"></ng-container>
  </span>
</button>

<ng-template #iconStart>
  <span class="button-icon-start">
    <ng-content select="[start]"></ng-content>
  </span>
</ng-template>
<ng-template #iconEnd>
  <span class="button-icon-end">
    <ng-content select="[end]"></ng-content>
  </span>
</ng-template>
