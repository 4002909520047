import { createReducer, on, Action } from '@ngrx/store';

import * as GeneralActions from './general-state.action';
import { GeneralState, initialGeneralState } from './general-state.state';

const campaignReducer = createReducer(
  initialGeneralState,
  on(GeneralActions.initLootLabsData, (state) => ({
    campaign: null,
    lootLabsData: null,
  })),
  on(GeneralActions.loadLootLabsDataSuccess, (state, { lootLabsData }) => ({
    ...state,
    lootLabsData: lootLabsData,
  })),
  on(GeneralActions.loadLootLabsDataFailure, (state, { error }) => ({
    ...state,
    error: error,
  }))
);

export function reducer(state: GeneralState | undefined, action: Action) {
  return campaignReducer(state, action);
}
