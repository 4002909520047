import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { MyOverlayRef } from './overlay-ref';
import { OverlayComponent } from '../../components/overlay/overlay.component';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<R = any, T = any>(
    content: any,
    data: T,
    config?: { disableClose?: boolean }
  ): MyOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: !config || !config.disableClose,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background',
    });

    const overlayRef = this.overlay.create(configs);

    const myOverlayRef = new MyOverlayRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));
    if (config && config.disableClose) {
      this.disableClose();
    }
    return myOverlayRef;
  }

  private createInjector(ref: MyOverlayRef, inj: Injector) {
    const injectorTokens = new WeakMap([[MyOverlayRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }

  disableClose(): void {
    document.body.style.pointerEvents = 'none';
  }

  enableClose(): void {
    document.body.style.pointerEvents = '';
  }
}
