<input
  type="file"
  class="file-input"
  (change)="selectFile($event)"
  accept="image/*"
  #fileUpload
/>
<div
  [class.profile]="profile"
  [class.file-upload]="!profile"
  [class.error]="invalid"
  [ngClass]="{ 'not-upload': !isUpload, upload: isUpload }"
>
  <img
    *ngIf="profile"
    [class.profile-image]="profile"
    [src]="preview"
    class="preview"
  />

  <div
    [ngClass]="{ camera: profile }"
    *ngIf="profile || !isUpload; else uploadCompleted"
  >
    <app-button
      *ngIf="!profile"
      [color]="profile ? 'naked' : 'transparent'"
      [class.upload-btn]="!profile"
      [class.profile-btn]="profile"
      (buttonClick)="fileUpload.click()"
    >
      <ng-container *ngIf="!profile">
        <span class="width">{{ 'upload_file.upload_image' | translate }}</span>
        <app-icon *ngIf="!isUpload" end name="upload"> </app-icon>
      </ng-container>
    </app-button>
    <div (click)="fileUpload.click()" *ngIf="profile">
      <img src="./assets/png/cameraBlack.png" alt="camera" />
    </div>
  </div>
  <ng-template #uploadCompleted>
    <div class="options" *ngIf="!profile">
      <app-button (buttonClick)="deleteImage()" color="transparent">
        <div class="width">
          <img
            *ngIf="isUpload"
            [class.profile-image]="profile"
            [src]="preview"
            class="preview"
          />
        </div>
        <app-icon name="delete"></app-icon>
      </app-button>
    </div>
  </ng-template>
</div>
