import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { snackbarDataTableFailed } from '../../dashboard/snackbar/snackbar';
import { TableColumn } from '@swimlane/ngx-datatable';
import { RowsModel } from '../model/rows.model';
import {
  formatDateTime,
  paginationDropDown,
} from 'src/app/shared/helper/helper';
import { TranslateService } from '@ngx-translate/core';
import { columns } from '../constants/columns';
import { ListTypeEnum } from '../../creating-flows/add-full-script/enums/type-list.enum';

@Injectable({
  providedIn: 'root',
})
export class FullScriptApiService {
  private readonly apiUrl!: string;
  currentPage = 1;
  limitPerPage = 50;
  public limitOptions = [50];
  private tempRows: RowsModel[] = [];

  public rows = new BehaviorSubject<RowsModel[]>([]);
  public rows$ = this.rows.asObservable();

  private numOfPages = new BehaviorSubject<number>(1);
  public numOfPages$ = this.numOfPages.asObservable();

  private chosenLimit = new BehaviorSubject<number>(this.limitOptions[0]);
  public chosenLimit$ = this.chosenLimit.asObservable();

  private columns = new BehaviorSubject<TableColumn[]>(columns);
  public columns$ = this.columns.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private generalService: GeneralService,
    private translateService: TranslateService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }
  public updateData() {
    this.currentPage = 1;
    this.loaderService.show();
    return this.getFullScriptTable().subscribe();
  }

  getFullScriptTable(): Observable<any> {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.tag;

    return forkJoin({
      themes: this.generalService.themes.pipe(
        filter((verticals) => !!verticals),
        take(1)
      ),
    }).pipe(
      switchMap(({}) =>
        this.http.get<Array<any>>(url, { headers }).pipe(
          map(
            (res: any) => {
              if (res != null) {
                this.numOfPages.next(
                  Math.ceil(res.message?.length / this.limitPerPage)
                );
                this.limitOptions = paginationDropDown(
                  this.numOfPages.value,
                  this.limitPerPage
                );
                const data =
                  res.message?.map((item: RowsModel) => ({
                    created_date: formatDateTime(item.created_on || ''),
                    vertical: this.generalService.getVertical(item.vertical),
                    id: item._id,
                    name: item.name,
                    operation_id: item.operation_id,
                    site_id: item.site_id,
                    site_url: item.site_url || null,
                    ...this.contentLockerTypeList(item),
                  })) || [];
                this.rows.next(data);
                this.tempRows = [...this.rows.value];
                this.loaderService.hide();
              }
            },
            (error: HttpErrorResponse) => {
              this.loaderService.hide();
              return this.generalService.catchError(
                snackbarDataTableFailed,
                error
              );
            }
          )
        )
      )
    );
  }

  contentLockerTypeList(row: Partial<RowsModel>) {
    if (
      row.external_links_whitelist &&
      row.external_links_whitelist.length > 0
    ) {
      return {
        typeList: ListTypeEnum.WHITE_LIST,
        urls: [...(row.external_links_whitelist || [])],
      };
    } else {
      return {
        typeList: ListTypeEnum.BLACK_LIST,
        urls: [...(row.external_links_blacklist || [])],
      };
    }
  }

  setChosenLimit(limit: number) {
    this.chosenLimit.next(limit);
    this.numOfPages.next(Math.ceil(this.rows.value.length / limit));
  }

  updateColumnsData(
    initTemplates: TableColumn[],
    genericTemplate: TableColumn
  ) {
    const updatedArray = this.columns.value.map((obj: any) => {
      const nameKey = obj.name || '';
      const name = this.translateService.instant(nameKey);
      const updatedObj = initTemplates.find(
        (updated: any) => updated.prop === obj.prop
      );
      if (updatedObj) {
        return { ...obj, ...updatedObj, name: name };
      }
      return { ...obj, ...genericTemplate, name: name };
    });
    this.columns.next(updatedArray);
  }

  filterTable(filterTerm: string) {
    const temp = this.tempRows.filter((item) => {
      const name = item.name?.toString().toLowerCase();
      const filterTermLowercase = filterTerm.toLowerCase();

      return name?.includes(filterTermLowercase);
    });

    this.rows.next(temp);
  }
}
