<div class="toggle-container {{ size }}">
  <button
    *ngFor="let option of options"
    class="toggle-button typography-headline2"
    [class.checked]="option.id === selectedOptionId"
    [disabled]="disabled"
    (mouseup)="onToggleChange(option.id)"
  >
    {{ option.label | translate }}
  </button>
</div>
