import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { APP_CONFIG } from 'src/app';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { columns } from '../constants/columns';
import { TableColumn } from '@swimlane/ngx-datatable/lib/types/table-column.type';
import { RowsModel } from '../model/rows.model';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  extractIds,
  formatDate,
  paginationDropDown,
} from 'src/app/shared/helper/helper';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import {
  successDeleteContentLocker,
  failsDeleteContentLocker,
  failedGetCategory,
} from '../components/dashboard-table/snackbar/snackbar';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { snackbarDataTableFailed } from '../snackbar/snackbar';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly apiUrl!: string;
  limitPerPage = 50;
  public limitOptions = [50];
  private tempRows: RowsModel[] = [];

  currentPage = 1;
  public rows = new BehaviorSubject<RowsModel[]>([]);
  public rows$ = this.rows.asObservable();

  private numOfPages = new BehaviorSubject<number>(1);
  public numOfPages$ = this.numOfPages.asObservable();

  private chosenLimit = new BehaviorSubject<number>(this.limitOptions[0]);
  public chosenLimit$ = this.chosenLimit.asObservable();

  private columns = new BehaviorSubject<TableColumn[]>(columns);
  public columns$ = this.columns.asObservable();

  public selectedCampaigns = new BehaviorSubject<any[]>([]);
  public selectedCampaigns$ = this.selectedCampaigns.asObservable();
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private snackbarService: SnackbarService,
    private generalService: GeneralService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public updateData() {
    this.currentPage = 1;
    this.loaderService.show();
    return this.getContentLockerTable();
  }

  getContentLockerTable(categories: Array<string> = ['-1']): Observable<any> {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.content_locker;
    const body = { categories };

    return forkJoin({
      themes: this.generalService.themes.pipe(
        filter((themes) => !!themes),
        take(1)
      ),
      ad_tier: this.generalService.ad_tier.pipe(
        filter((ad_tier) => !!ad_tier),
        take(1)
      ),
    }).pipe(
      switchMap(({ themes }) =>
        this.http.post<Array<any>>(url, { ...body }, { headers }).pipe(
          map((res: any) => {
            if (res != null) {
              const { message } = res;
              this.numOfPages.next(
                Math.ceil(message.length / this.limitPerPage)
              );
              this.limitOptions = paginationDropDown(
                this.numOfPages.value,
                this.limitPerPage
              );
              const formattedData = message.map((item: RowsModel) => ({
                ...item,
                maximum_tasks: item.number_of_tasks,
                created_date: formatDate(item.created_date || ''),
                theme: this.generalService.getThemes(item.theme_id),
                tier_id: this.generalService.getAdTier(item.tier_id),
                category: item.sub_id,
                name: item.short_name,
                destination_url: item.url,
                ad_tier: this.generalService.getAdTier(item.tier_id),
              }));

              this.rows.next(formattedData);
              this.tempRows = [...this.rows.value];
              this.loaderService.hide();

              return message;
            } else {
              return '';
            }
          }),
          catchError((error: any) => {
            this.loaderService.hide();
            return this.generalService.catchError(
              snackbarDataTableFailed,
              error
            );
          })
        )
      )
    );
  }
  updateColumnsData(
    initTemplates: TableColumn[],
    genericTemplate: TableColumn
  ) {
    const updatedArray = this.columns.value.map((obj: any) => {
      const nameKey = obj.name || '';
      const name = this.translateService.instant(nameKey);
      const updatedObj = initTemplates.find(
        (updated: any) => updated.prop === obj.prop
      );
      if (updatedObj) {
        return { ...obj, ...updatedObj, name: name };
      }
      return { ...obj, ...genericTemplate, name: name };
    });
    this.columns.next(updatedArray);
  }

  getCategory() {
    const headers = this.loginService.TokenFromStorage;
    let url = this.apiUrl + this.appConfig.actions.categories;
    return this.http.get<string>(url, { headers }).pipe(
      tap(
        (res: any) => {
          return res.message;
        },
        (error: HttpErrorResponse) => {
          return this.generalService.catchError(failedGetCategory, error);
        }
      )
    );
  }

  paginationDropDown(numOfPages: number): number[] {
    const array: number[] = [];
    let jump = this.limitPerPage;

    for (let i = 0; i < numOfPages; i++) {
      array.push(jump);
      jump += this.limitPerPage;
    }

    return array;
  }

  setChosenLimit(limit: number) {
    this.chosenLimit.next(limit);
    this.numOfPages.next(Math.ceil(this.rows.value.length / limit));
  }

  deleteContentLocker(body: string[], id?: string) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.content_locker;

    return (
      this.http.post(url, { short_ids: body }, { headers }).subscribe(() => {
        this.snackbarService.showSnackbar(successDeleteContentLocker);
        //SINGLE
        if (id) {
          const filteredRows = this.rows
            .getValue()
            .filter((r: RowsModel) => r.id !== id);
          this.rows.next(filteredRows);
          //MULTIPLE
        } else {
          const filteredRows = this.rows.getValue().filter((row) => {
            if (row.id !== null) {
              return !body.some((selectedId) => selectedId === row.id);
            }
            return true;
          });
          this.rows.next(filteredRows);
          this.loaderService.hide();
          this.updateSelectedCampaigns([]);
        }
        this.tempRows = [...this.rows.value];
      }),
      catchError((error) => {
        this.loaderService.hide();
        this.snackbarService.showSnackbar(failsDeleteContentLocker);
        return throwError(error);
      })
    );
  }

  deleteMultipleContentLocker() {
    const shortIds = extractIds(this.selectedCampaigns.getValue());
    this.deleteContentLocker(shortIds);
  }

  updateSelectedCampaigns(campaigns: any[]) {
    this.selectedCampaigns.next(campaigns);
  }

  filterTable(filterTerm: string) {
    const temp = this.tempRows.filter((item) => {
      const short_link = item.short_link?.toString().toLowerCase();
      const name = item.short_name?.toString().toLowerCase();
      const filterTermLowercase = filterTerm.toLowerCase();

      return (
        short_link?.includes(filterTermLowercase) ||
        name?.includes(filterTermLowercase)
      );
    });

    this.rows.next(temp);
  }
}
