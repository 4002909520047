import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemeEnum } from '../../../../enunms/theme.enum';
import { SocialLinks } from '../../../../constants/preview_social_link';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnChanges, OnInit {
  @Input() title!: string;
  @Input() creator_name!: string;
  @Input() creator_picture!: string;
  @Input() social_links!: FormGroup;
  @Input() number_of_tasks!: number;
  @Input() theme!: string;
  @Input() background!: string | null;
  socialLinks = SocialLinks;
  ThemeEnum = ThemeEnum;
  number_of_tasks_arr!: number[];
  share_src!: string;
  question_mark_src!: string;
  youtube_logo_src!: string;
  atLeastOneSocialLink = false;

  ngOnInit(): void {
    this.isAtLeastOneSelected();
  }

  ngOnChanges(): void {
    this.number_of_tasks_arr = Array(this.number_of_tasks)
      .fill(0)
      .map((_, index) => index + 1);
    if (
      this.background === './assets/svg/upload.svg' ||
      this.background === 'null' ||
      this.background === 'None' ||
      this.background === 'none'
    ) {
      this.background = null;
    }
    this.setTasksIcons();
    this.setYoutubeLogo();
    this.social_links.valueChanges.subscribe(() => {
      this.isAtLeastOneSelected();
    });
  }

  setYoutubeLogo() {
    if (this.theme === ThemeEnum.SPACE) {
      this.youtube_logo_src = '/assets/svg/white_youtube_logo.svg';
    } else {
      this.youtube_logo_src = '/assets/svg/youtube_logo.svg';
    }
  }

  setTasksIcons() {
    if (
      this.theme === ThemeEnum.SIMS ||
      this.theme === ThemeEnum.SPACE ||
      this.theme === ThemeEnum.GTA ||
      this.theme === ThemeEnum.MINECRAFT
    ) {
      this.share_src = '/assets/svg/share_white.svg';
      this.question_mark_src = '/assets/svg/preview_question_mark_white.svg';
    } else if (this.theme === ThemeEnum.CLASSIC || this.theme === null) {
      this.share_src = '/assets/svg/share.svg';
      this.question_mark_src = '/assets/svg/preview_question_mark.svg';
    }
  }

  isAtLeastOneSelected(): void {
    const controls = this.social_links.controls;
    this.atLeastOneSocialLink = Object.values(controls).some(
      (control) => !!control.value
    );
  }
}
