import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SupportService } from './services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(private supportService: SupportService) {}

  ngOnInit(): void {
    this.supportService.supportSlideToggle$.subscribe(() => {
      this.sidenav.toggle();
      this.supportService.supportSlideOpen.next(this.sidenav.opened);
    });
  }
  closeSupport() {
    this.sidenav.close();
    this.supportService.supportSlideOpen.next(false);
  }
}
