import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'src/app';
import { MyOverlayRef } from 'src/app/shared/services/overlay/overlay-ref';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { AddSingleComponent } from '../add-single.component';
import { LoginService } from 'src/app/pages/common/login/store/login-state/login.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/components/loader/service/loader.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import {
  snackbarFailedCreateSingleContentLocker,
  snackbarFailedEditSingleContentLocker,
  snackbarSuccessCreateSingleContentLocker,
  snackbarSuccessEditSingleContentLocker,
} from '../snackbar/snackbar';
import { GeneralService } from 'src/app/shared/states/general-state.service';
import { ServerSingleObject } from '../models/server-single.model';
import {
  convertFromValueToKey,
  extractIds,
} from 'src/app/shared/helper/helper';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { Router } from '@angular/router';
import { NotificationType } from 'src/app/shared/components/notifications/models/notifications.model';
import { NotificationsService } from 'src/app/shared/components/notifications/services/notifications.service';
import { FiltersService } from '../../../statistics/services/filters.service';

@Injectable({
  providedIn: 'root',
})
export class AddSingleService {
  private readonly apiUrl: string;
  isCreatedEdit = false;
  short_link!: string;
  short_id!: number;

  constructor(
    private overlay: OverlayService,
    @Inject(APP_CONFIG) private appConfig: any,
    private loginService: LoginService,
    private http: HttpClient,
    private loaderService: LoaderService,
    private snackbarService: SnackbarService,
    private generalService: GeneralService,
    private dashboardService: DashboardService,
    private router: Router,
    private notificationService: NotificationsService,
    private filterService: FiltersService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  openSingleContentLockerComponent<R = any, T = any>(data: T): MyOverlayRef<R> {
    const ref = this.overlay.open(AddSingleComponent, data);
    ref.afterClosed$.subscribe(() => {
      if (this.isCreatedEdit) {
        this.dashboardService.updateData().subscribe();
        this.router.navigate(['/dashboard']);
        this.isCreatedEdit = false;
      }
    });
    return ref;
  }

  createSingleContentLocker(body: ServerSingleObject) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.content_locker;
    return this.http.post(url, body, { headers }).pipe(
      tap((res: any) => {
        this.isCreatedEdit = true;
        this.short_link = res.message[0].short_link;
        this.short_id = res.message[0].short_id;
        this.filterService.getFilters(false);
        this.snackbarService.showSnackbar(
          snackbarSuccessCreateSingleContentLocker
        );
        const notification = {
          notificationType: NotificationType.ContentLocker,
          id: this.short_id,
        };
        const newNotification =
          this.notificationService.createNotificationObject(notification);

        this.notificationService
          .createNotification(newNotification)
          .subscribe(() => {
            this.notificationService.getNotifications();
          });
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();

        return this.generalService.catchError(
          snackbarFailedCreateSingleContentLocker,
          error
        );
      })
    );
  }

  editSingleContentLocker(body: ServerSingleObject) {
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.content_locker;
    return this.http.put(url, body, { headers }).pipe(
      tap((res: any) => {
        this.isCreatedEdit = true;
        this.filterService.getFilters(false);
        this.loaderService.hide();
        this.snackbarService.showSnackbar(
          snackbarSuccessEditSingleContentLocker
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        return this.generalService.catchError(
          snackbarFailedEditSingleContentLocker,
          error
        );
      })
    );
  }

  editMultipleContentLocker(body: ServerSingleObject) {
    const shortIds = extractIds(
      this.dashboardService.selectedCampaigns.getValue()
    );
    body.short_ids = shortIds;
    const temp_theme_id = body.theme_id;
    const temp_tier_id = body.tier_id;
    body.theme_id = convertFromValueToKey(
      this.generalService.getThemeAsArray(),
      temp_theme_id.toString()
    );
    body.tier_id = convertFromValueToKey(
      this.generalService.getTierAsArray(),
      temp_tier_id.toString()
    );
    const headers = this.loginService.TokenFromStorage;
    const url = this.apiUrl + this.appConfig.actions.content_locker;
    return this.http.put(url, body, { headers }).pipe(
      tap((res: any) => {
        this.filterService.getFilters(false);
        this.isCreatedEdit = true;
        this.loaderService.hide();
        this.snackbarService.showSnackbar(
          snackbarSuccessEditSingleContentLocker
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        return this.generalService.catchError(
          snackbarFailedEditSingleContentLocker,
          error
        );
      })
    );
  }
}
