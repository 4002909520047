import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
@NgModule({
  declarations: [SnackbarComponent],
  entryComponents: [SnackbarComponent],
  imports: [CommonModule, MatSnackBarModule, TranslateModule],
  providers: [{ provide: MAT_SNACK_BAR_DATA, useValue: {} }],
})
export class SnackbarModule {}
