import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Item } from 'src/app/shared/types/item';
import { GeneralFacade } from 'src/app/shared/states/general-state.facade';
import { loadCountries } from 'src/app/shared/helper/helper';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodFormService {
  private countries = new BehaviorSubject<Item[]>([]);
  public countries$ = this.countries.asObservable();
  private cities = new BehaviorSubject<string[]>([]);
  public cities$ = this.cities.asObservable();

  constructor(private facade: GeneralFacade) {}

  getCitiesByCountry(countryCode: any, countriesMap: any, citiesMap: any) {
    const countryObject = countriesMap[countryCode];
    if (countryObject && countryObject.country_name) {
      const cities = Object.keys(citiesMap)
        .filter((city) => citiesMap[city] === countryCode)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      return cities;
    } else {
      return [];
    }
  }

  loadCountries() {
    this.facade.enums$.pipe(first((val: any) => !!val)).subscribe((state) => {
      this.countries.next(loadCountries(state));
    });
  }

  loadCities(countryCode: string | null) {
    if (countryCode === '') {
      this.cities.next([]);
      return;
    }
    this.facade.enums$
      .pipe(first((val: any) => !!val))
      .subscribe((state: any) => {
        const countryObj = state.message.countries_map;
        const cityObj = state.message.cities_map;
        this.cities.next(
          this.getCitiesByCountry(countryCode, countryObj, cityObj)
        );
      });
  }
}
