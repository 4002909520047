import { Snackbar } from 'src/app/shared/types/snackbar';

export const failsDeleteContentLocker: Snackbar = {
  duration: 2000,
  message: 'dashboard.delete_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const successDeleteContentLocker: Snackbar = {
  duration: 2000,
  message: 'dashboard.delete_success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const failedGetCategory: Snackbar = {
  duration: 2000,
  message: 'dashboard.snackbar_failed_category',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
