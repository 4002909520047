import { TableColumn } from '@swimlane/ngx-datatable';
import { ColumnsEnum } from '../enums/columns.enum';

const columnConfig: TableColumn = {
  draggable: false,
  resizeable: false,
};
export const columns: TableColumn[] = [
  {
    prop: ColumnsEnum.REQUEST_DATE,
    name: 'payments.columns.date',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.PAYMENT_PERIOD,
    name: 'payments.columns.payment_period',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.PAYMENT_TYPE,
    name: 'payments.columns.method',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.AMOUNT,
    name: 'payments.columns.amount',
    sortable: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.PAYMENT_TERM,
    name: 'payments.columns.payment_term',
    sortable: false,
    ...columnConfig,
  },

  {
    prop: ColumnsEnum.STATUS,
    name: 'payments.columns.status',
    sortable: false,
    ...columnConfig,
  },
];
