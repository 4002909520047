import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarSuccessDownloadFile: Snackbar = {
  duration: 2000,
  message: 'multiple.step_1.download_success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedDownloadFile: Snackbar = {
  duration: 2000,
  message: 'multiple.step_1.download_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarSuccessCreateMultipleContentLocker: Snackbar = {
  duration: 2000,
  message: 'multiple.snackbar_success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedCreateMultipleContentLocker: Snackbar = {
  duration: 2000,
  message: 'multiple.snackbar_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarFailedCsvFailed: Snackbar = {
  duration: 2000,
  message: 'multiple.snackbar_csv_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
