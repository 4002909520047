<div [formGroup]="form" class="container">
  <div class="stepper-n-title">
    <div class="copy typography-body3-thin typography-color-dark-blue">
      <span class="details-subtitle">{{
        'full_page.step_3.copy' | translate
      }}</span>
      <span class="head-tag typography-color-dark-blue details-subtitle">{{
        'full_page.step_3.head_tag' | translate
      }}</span>
      <span class="details-subtitle">{{
        'full_page.step_3.and' | translate
      }}</span>
      <span class="head-tag typography-color-dark-blue details-subtitle">{{
        'full_page.step_3.head_end_tag' | translate
      }}</span>
      <span class="details-subtitle">{{
        'full_page.step_3.tag' | translate
      }}</span>
    </div>
  </div>
  <app-copy-code [linkText]="getCopyCodeText()"></app-copy-code>
</div>
<div class="typography-footnote1 warning">
  {{ 'full_page.step_3.warning' | translate }}
</div>
