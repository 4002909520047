import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as LoginActions from './login.action';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { pipe } from 'rxjs';

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private loginService: LoginService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.Login),
      concatMap((action: any) => {
        return this.loginService.login(action.credentials).pipe(
          map((res: any) => {
            this.loginService.token = res.message.token;
            this.loginService.loginGuard();

            return LoginActions.LoginSuccess({ user: res.message });
          }),
          catchError((err) => {
            return of(LoginActions.LoginFailure({ error: err }));
          })
        );
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.updateUser),
      concatMap((action: any) => {
        return this.loginService.updateUser(action.user).pipe(
          map((res: any) => {
            return LoginActions.UpdateUserSuccess({ user: res.message });
          }),
          catchError((err) => {
            return of(LoginActions.UpdateUserFailure({ error: err }));
          })
        );
      })
    )
  );
}
