<input
  aria-label="checkbox"
  type="checkbox"
  class="input-checkbox"
  [disabled]="disabled"
  [class.disabled]="disabled"
  [class.checked]="checked"
  [class.custom]="custom"
  [checked]="checked"
  [class.invalid]="invalid"
/>
