import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPopupComponent } from './error-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { ButtonModule } from '../button/button.module';
import { DividerModule } from '../divider/divider.module';

@NgModule({
  declarations: [ErrorPopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    ButtonModule,
    DividerModule,
  ],
  exports: [ErrorPopupComponent],
})
export class ErrorPopupModule {}
