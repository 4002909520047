<div class="configure-container" [formGroup]="configureDesignForm">
  <div class="column">
    <div class="title-header">
      <div class="title typography-footnote2-bold">
        {{ 'multiple.step_2.upload_file_title' | translate }}
      </div>
    </div>
    <div class="content">
      <div class="subtitle typography-footnote2-thin">
        {{ 'multiple.step_2.upload_file_subtitle' | translate }}
      </div>
      <app-radio-group
        class="radio-buttons-group"
        [groupName]="'link_option'"
        formControlName="link_option"
      >
        <app-radio-button
          class="radio-button"
          [groupName]="'link_option'"
          value="{{ linkOptions[0].key }}"
        >
          <ng-template let-checked="checked" let-disabled="disabled">
            <div [class.checked]="checked" [class.disabled]="disabled">
              <div class="label">
                <app-radio-circle class="circle" [checked]="checked">
                </app-radio-circle>
                <span class="typography-footnote2-thin">
                  {{ linkOptions[0].title | translate }}</span
                >
              </div>
            </div>
          </ng-template>
        </app-radio-button>

        <div
          *ngIf="
            configureDesignForm.get('link_option')?.value === linkOptions[0].key
          "
        >
          <app-upload-image
            [preview]="configureDesignForm.get('background')?.value"
            formControlName="background"
          >
          </app-upload-image>
        </div>

        <app-radio-button
          class="radio-button"
          [groupName]="'link_option'"
          value="{{ linkOptions[1].key }}"
        >
          <ng-template let-checked="checked" let-disabled="disabled">
            <div [class.checked]="checked" [class.disabled]="disabled">
              <div class="label">
                <app-radio-circle class="circle" [checked]="checked">
                </app-radio-circle>
                <span class="typography-footnote2-thin">
                  {{ linkOptions[1].title | translate }}</span
                >
              </div>
            </div>
          </ng-template>
        </app-radio-button>
        <div
          *ngIf="
            configureDesignForm.get('link_option')?.value === linkOptions[1].key
          "
        >
          <app-input
            class="name"
            formControlName="background"
            [placeholder]="'single.step_1.youtube_placeholder' | translate"
            [invalid]="
              (configureDesignForm.get('background')?.invalid &&
                !configureDesignForm.get('background')?.pristine) ||
              (configureDesignForm.get('background')?.hasError('required') &&
                !configureDesignForm.get('background')?.pristine)
            "
          >
          </app-input>
        </div>

        <app-radio-button
          class="radio-button"
          [groupName]="'link_option'"
          value="{{ linkOptions[2].key }}"
        >
          <ng-template let-checked="checked" let-disabled="disabled">
            <div [class.checked]="checked" [class.disabled]="disabled">
              <div class="label">
                <app-radio-circle class="circle" [checked]="checked">
                </app-radio-circle>
                <span class="typography-footnote2-thin">
                  {{ linkOptions[2].title | translate }}</span
                >
              </div>
            </div>
          </ng-template>
        </app-radio-button>
      </app-radio-group>
    </div>
  </div>

  <div class="column">
    <div class="header">
      <div class="title-header">
        <div class="title typography-footnote2-bold">
          {{ 'single.set_up.title_theme' | translate }}
        </div>
      </div>
      <div class="padding subtitle typography-caption">
        {{ 'single.set_up.subtitle_theme' | translate }}
      </div>
    </div>
    <div class="padding">
      <ng-select
        class="theme typography-headline2"
        [multiple]="false"
        [searchable]="false"
        [items]="themeList"
        bindLabel="value"
        bindValue="key"
        formControlName="theme_id"
        [placeholder]="'single.set_up.placeholder_theme' | translate"
        class="dropdown"
        [clearable]="false"
        [ngClass]="{
          'has-value': configureDesignForm.get('theme_id') !== null
        }"
        [class.error]="
          configureDesignForm.get('theme_id')?.hasError('required')
        "
      ></ng-select>
    </div>
  </div>

  <div class="column">
    <div class="header">
      <div class="title-header">
        <div class="title typography-footnote2-bold">
          {{ 'single.set_up.title_tasks' | translate }}
        </div>
      </div>
      <div class="padding subtitle typography-caption">
        {{ 'single.set_up.subtitle_tasks' | translate }}
      </div>
    </div>

    <div class="line">
      <div class="tiers tiers-gap content">
        <div class="subtitle typography-caption">
          {{ 'single.set_up.label_tasks' | translate }}
        </div>
        <ng-select
          class="dropdown-tiers typography-headline2"
          [multiple]="false"
          [items]="tiersList"
          [searchable]="false"
          bindLabel="value"
          bindValue="key"
          formControlName="tier_id"
          [placeholder]="'single.set_up.placeholder_tier' | translate"
          class="dropdown"
          [clearable]="false"
          [ngClass]="{
            'has-value': configureDesignForm.get('tier_id') !== null
          }"
          [class.error]="
            configureDesignForm.get('tier_id')?.hasError('required')
          "
        ></ng-select>
      </div>
      <div class="column tiers-gap">
        <div class="subtitle typography-footnote2-thin">
          {{ 'single.set_up.tasks_number' | translate }}
        </div>
        <div class="counter">
          <app-button size="sm" (buttonClick)="decrement()">-</app-button>
          <span class="typography-footnote2-thin">{{
            configureDesignForm.get('number_of_tasks')?.value
          }}</span>
          <app-button size="sm" (buttonClick)="increment()">+</app-button>
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="header">
      <div class="title-header">
        <div class="title typography-footnote2-bold">
          {{ 'multiple.step_2.csv_title' | translate }}
        </div>
      </div>
      <div class="padding subtitle typography-caption">
        {{ 'multiple.step_2.csv_subtitle' | translate }}
      </div>
    </div>
    <div class="padding line">
      <app-upload-csv
        [preview]="configureDesignForm.get('csv')?.value"
        type="csv"
        formControlName="csv"
        (csvChanged)="saveCsv($event)"
      ></app-upload-csv>
    </div>
  </div>
</div>
