import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarSuccessCreateFullPageContentLocker: Snackbar = {
  duration: 2000,
  message: 'full_page.succeed_create_snackbar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedCreateFullPageContentLocker: Snackbar = {
  duration: 2000,
  message: 'full_page.failed_create_snackbar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarSuccessUpdateullPageContentLocker: Snackbar = {
  duration: 2000,
  message: 'full_page.succeed_update_snackbar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedUpdateFullPageContentLocker: Snackbar = {
  duration: 2000,
  message: 'full_page.failed_update_snackbar',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
