import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { Snackbar } from '../../types/snackbar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Snackbar
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }

  get type(): string {
    return this.data.type || 'pending-snackbar';
  }

  get message(): string {
    return this.data.message;
  }
}
