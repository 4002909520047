export class MyProfitModel {
  title: string;
  profit: string;
  tooltip: string;

  constructor() {
    this.title = '';
    this.profit = '';
    this.tooltip = '';
  }
}
