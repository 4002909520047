import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './shared/components/loader/service/loader.service';
import { GeneralService } from './shared/states/general-state.service';
import { KillSwitchService} from "./pages/switch.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = "Creator's Panel - LootLabs";
  isLoading!: boolean;

  constructor(
    private loaderService: LoaderService,
    private translate: TranslateService,
    private generalService: GeneralService,
    private killSwitchService: KillSwitchService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.generalService.responsive();
    this.loaderService.isLoading.subscribe((val) => {
      this.isLoading = val;
    });

    this.appendCrispChatScript();
  }

  ngOnInit() {
    this.killSwitchService.checkKillSwitch();
  }


  appendCrispChatScript() {
    const doc = document;
    const script = doc.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    doc.getElementsByTagName('head')[0].appendChild(script);
  }
}
