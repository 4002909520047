import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LoginSelectors from './login.selector';
import { LoginState } from './login.state';

@Injectable({ providedIn: 'root' })
export class LoginFacade {
  user$ = this.createLogintStore.select(LoginSelectors.getUserState);
  error$ = this.createLogintStore.select(LoginSelectors.getErrorState);

  constructor(private createLogintStore: Store<LoginState>) {}
}
