<label
  class="radio-container typography-body3-thin"
  [class.disabled]="disabled"
>
  <input
    #radio
    [name]="groupName"
    type="radio"
    class="radio-input"
    (change)="onSelect(radio)"
    [disabled]="disabled"
    [checked]="checked"
    [value]="value"
  />
  <ng-template *ngTemplateOutlet="templateRef; context: { checked, disabled }">
  </ng-template>
</label>
