export enum ColumnsEnum {
  SHORT_LINK = 'short_link',
  NAME = 'name',
  DESTINATION_URL = 'destination_url',
  THEME = 'theme',
  AD_TIER = 'ad_tier',
  MAXIMUM_TASKS = 'maximum_tasks',
  CREATED_DATE = 'created_date',
  CATEGORY = 'category',
  TITLE = 'title',
  ACTIONS = 'actions',
}
