import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarSuccessCreateSingleContentLocker: Snackbar = {
  duration: 2000,
  message: 'single.snackbar_success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedCreateSingleContentLocker: Snackbar = {
  duration: 2000,
  message: 'single.snackbar_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const snackbarSuccessEditSingleContentLocker: Snackbar = {
  duration: 2000,
  message: 'single.snackbar_edit_success',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};

export const snackbarFailedEditSingleContentLocker: Snackbar = {
  duration: 2000,
  message: 'single.snackbar_edit_failed',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};
