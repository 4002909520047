import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportToCsvComponent } from './export-to-csv.component';
import { IconModule } from '../icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [ExportToCsvComponent],
  imports: [CommonModule, IconModule, ButtonModule, TranslateModule],
  exports: [ExportToCsvComponent],
})
export class ExportToCsvModule {}
