import { Snackbar } from 'src/app/shared/types/snackbar';

export const snackbarObjectPaymentFailed: Snackbar = {
  duration: 2000,
  message: 'profile.payment_method.failed_message',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const failsSendEmail: Snackbar = {
  duration: 2000,
  message: 'profile.payment_method.failed_send_mail',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'error-snackbar',
};

export const successSendEmail: Snackbar = {
  duration: 2000,
  message: 'profile.payment_method.success_send_mail',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  type: 'approved-snackbar',
};
