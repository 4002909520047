import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScriptTableComponent } from './components/full-script-table/full-script-table.component';
import { FullScriptApiComponent } from './full-script-api.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FullScriptTableComponent, FullScriptApiComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class FullScriptApiModule {}
