import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoginService } from './pages/common/login/store/login-state/login.service';
import { Injectable } from '@angular/core';
import { AccountTypeEnum } from './shared/enums/account-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}
  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    const data = router.data as { role: AccountTypeEnum[] };
    const role = data.role;

    if (
      localStorage.getItem('authToken') &&
      localStorage.getItem('accountType') &&
      role?.includes(Number(localStorage.getItem('accountType')))
    ) {
      return true;
    } else {
      this.loginService.redirectUrl = url;
      this.router.navigate(['/login']);
      return false;
    }
  }
}
