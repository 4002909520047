import { TableColumn } from '@swimlane/ngx-datatable';
import { ColumnsEnum } from '../enums/columns';

interface CustomTableColumn extends TableColumn {
  isCurrency?: boolean;
}

const columnConfig: CustomTableColumn = {
  draggable: false,
  resizeable: false,
};

export const columns: CustomTableColumn[] = [
  {
    prop: ColumnsEnum.DATE,
    name: 'Report Date',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.OPERATION,
    name: 'Operation Name',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.COUNTRY,
    name: 'Country',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.TAG,
    name: 'Tag Name',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.IMPRESSIONS,
    name: 'Total Impressions',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.REVENUE,
    name: 'Total Revenue',
    sortable: true, // Enable sorting for currency columns
    isCurrency: true, // Mark this as a currency column
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.CPM,
    name: 'CPM',
    sortable: true, // Enable sorting for currency columns
    isCurrency: true, // Mark this as a currency column
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.SESSIONS_CPM,
    name: 'Sessions CPM',
    sortable: true, // Enable sorting for currency columns
    isCurrency: true, // Mark this as a currency column
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.LINK,
    name: 'Link',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.TOTAL_SESSIONS,
    name: 'Total Sessions',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
  {
    prop: ColumnsEnum.TOTAL_CLICKS,
    name: 'Total clicks',
    sortable: false,
    isCurrency: false,
    ...columnConfig,
  },
];
