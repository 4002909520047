import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from 'src/app/pages/common/login/store/models/user.model';
import { ProfileService } from '../../service/profile.service';
import { CreatorModel } from 'src/app/pages/common/login/store/models/creator.model';
import { GeneralService } from 'src/app/shared/states/general-state.service';

@Component({
  selector: 'app-creator-details',
  templateUrl: './creator-details.component.html',
  styleUrls: ['./creator-details.component.scss'],
})
export class CreatorDetailsComponent implements OnChanges {
  @Input() userDetails!: UserModel;
  @Input() creatorDetails!: CreatorModel;
  public creatorDetailsForm!: FormGroup;
  isButtonDisabled = true;
  initialPicture!: string;
  isDisable = true;
  placeHolderPicture = './assets/svg/placeholder_image.svg';

  constructor(
    private profileService: ProfileService,
    private generalService: GeneralService
  ) {}

  ngOnChanges(): void {
    this.updateUserImage();
    this.initForms();

    this.creatorDetailsForm.valueChanges.subscribe(() => {
      this.isButtonDisabled = false;
      if (this.creatorDetailsForm.dirty) {
        if (this.creatorDetailsForm.valid) {
          this.isDisable = false;
        } else if (this.creatorDetailsForm.invalid) {
          this.isDisable = true;
        }
      }
    });
  }

  updateUserImage() {
    if (
      this.creatorDetails?.creator_picture === '' ||
      !this.creatorDetails?.creator_picture
    ) {
      this.initialPicture = this.placeHolderPicture;
    } else if (this.creatorDetails.creator_picture) {
      this.initialPicture = this.creatorDetails.creator_picture;
    }
  }

  initForms() {
    this.creatorDetailsForm = new FormGroup({
      creator_picture: new FormControl(this.initialPicture || '', [
        Validators.required,
      ]),
      publisher_username: new FormControl(
        this.creatorDetails?.publisher_username
      ),
      creator_name: new FormControl(this.creatorDetails?.creator_name, [
        Validators.required,
      ]),
      main_game: new FormControl(this.creatorDetails?.main_game),
      social_channel: new FormControl(this.creatorDetails?.social_channel),
      main_source_of_traffic: new FormControl(this.creatorDetails?.main_source_of_traffic),
      tiktok: new FormControl(this.creatorDetails?.tiktok),
      facebook: new FormControl(this.creatorDetails?.facebook),
      instagram: new FormControl(this.creatorDetails?.instagram),
      youtube: new FormControl(this.creatorDetails?.youtube),
      discord: new FormControl(this.creatorDetails?.discord),
      twitter: new FormControl(this.creatorDetails?.twitter),
      twitch: new FormControl(this.creatorDetails?.twitch),
      personal_site: new FormControl(this.creatorDetails?.personal_site),
    });
  }

  submit(): void {
    const imageFormControl =
      this.creatorDetailsForm.get('creator_picture')?.value;
    const image =
      imageFormControl === this.placeHolderPicture ? null : imageFormControl;
    if (this.creatorDetailsForm.valid) {
      const formData = {
        ...this.creatorDetailsForm.value,
        creator_picture: image,
      };
      this.profileService.updateCreatorDetails(formData).subscribe(() => {
        this.isDisable = true;
      });
    }
  }
}
