import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCheckboxComponent } from './checkbox.component';
import { AppCheckboxInputModule } from './checkbox-input/checkbox-input.module';

@NgModule({
  declarations: [AppCheckboxComponent],
  imports: [CommonModule, AppCheckboxInputModule],
  exports: [AppCheckboxComponent],
})
export class AppCheckboxModule {}
