<div class="personal-settings">
  <div class="title typography-heading3-bold">
    {{ 'profile.personal_settings_page.title' | translate }}
  </div>
  <mat-tab-group (selectedTabChange)="changeTab($event)">
    <mat-tab label="General info">
      <app-general-info [form]="accountForm"></app-general-info>
    </mat-tab>
    <mat-tab label="Address">
      <app-address
        [form]="accountForm"
        [userDetails]="userDetails"
      ></app-address>
    </mat-tab>
  </mat-tab-group>
  <div class="btn">
    <app-button
      [disabled]="accountForm.invalid || isButtonDisabled"
      color="primary"
      (buttonClick)="submit()"
      >{{ 'profile.personal_settings_page.btn_title' | translate }}</app-button
    >
  </div>
</div>
