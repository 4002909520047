export const linkOptions: Array<any> = [
  {
    key: 'image',
    title: 'single.step_1.image',
  },
  {
    key: 'youtube_link',
    title: 'single.step_1.youtube_title',
  },
  {
    key: 'none',
    title: 'single.step_1.none',
  },
];
